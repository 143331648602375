#pricing{ 
	overflow-x: hidden;

}

#pricing h1, 
#pricing h2, 
#pricing h3, 
#pricing h4, 
#pricing h5, 
#pricing h6, 
#pricing div, 
#pricing input, 
#pricing p, 
#pricing a{
    margin:0px; 
}

#pricing h3{ 
    font-size:22px;
}
#pricing .container-fluid, #pricing .container { 
    margin:auto;
	padding:0px 15px;
	max-width:1200px;
}
#pricing label{
	font-weight:500;
}
#pricing .form-group{
	margin-bottom:5px;
}


#pricing input, #pricing textarea, #pricing select, #pricing button{	
    margin: 5px 0px ;
    font-size:13px !important; 
    border-radius:0px;
}
#pricing input[type=text], 
#pricing input[type=password], 
#pricing textarea, 
#pricing input[type=email], 
#pricing select, 
#pricing textarea{ 
    width: 100%; 
    border:1px solid #DADADA;	
    padding: 5px 10px;	
    height:45px; 	
}
#pricing input[type=submit],
#pricing input[type=button],
#pricing input[type=reset],
#pricing button{
    border:none; 
    font-size: 11px; 	
	border-radius:3px;
	height:45px;
	color:#FFF;
}

#pricing .btn:hover,
#pricing .btn:focus{  
    cursor:pointer; 
    color:#FFF;
}
#pricing input[type=radio]{
    margin:0px;
    padding:0px; 
    height:auto;
}
#pricing .form-control{ 
    box-shadow:none !important;  
	border-radius:0px;
}
#pricing .form-control:focus{
    border:1px solid #CCC;
}
#pricing .btn:focus{
	box-shadow:none !important;
}

#pricing textarea{ 
    width: 100%;
}
#pricing input[type=reset]{ 
    margin-left: 10px;
}
#pricing textarea{
	min-height:100px;
}
#pricing a{ 
    color: inherit;
}
#pricing a:hover, #pricing a:focus{ 
    text-decoration: none !important; 
    color: inherit !important;
}
#pricing ul{
    margin: 0px; 
    padding: 0px; 
    list-style: none;
}
#pricing .relative{ 
    position: relative;
}
#pricing .absolute{ 
    position: absolute;
}
#pricing .fixed{ 
    position: fixed;
}

#pricing .pricing-table-container{
	padding:50px 0px;
}
#pricing .description{
	padding:15px 0px;
}
#pricing .description a{
	padding:10px;
	font-size:13px;
	display:block;
	font-weight:bold;
	border-bottom:1px solid #DDD;
}
#pricing .description a.active{
	background-color:#FFF;
	padding-left:25px;
}
/* @charset "utf-8"; */
/* DEMO 01 */
#pricing .pricing-table-3{
	background-color:#FFF;
	margin:15px auto;
	box-shadow:0px 0px 25px rgba(0,0,0,0.1);
	max-width:300px;
	border-radius:0px 10px 0px 10px;
	overflow:hidden;
	position:relative;
	min-height:250px;
	transition:all ease-in-out 0.25s;
}
#pricing .pricing-table-3:hover{
	transform:scale(1.1,1.1);
	cursor:pointer;
}

#pricing .pricing-table-3.basic .price{
	background-color:#010156;
	color:#FFF;
}
#pricing .pricing-table-3.premium .price{
	background-color:#e65d0f;
	color:#FFF;
}
#pricing .pricing-table-3.business .price{
	background-color:#010156;
	color:#FFF;
}

#pricing .pricing-table-3 .pricing-table-header{
	background-color:transparent;
	color:#FFF;
	padding:20px 0px 0px 20px;
	position:absolute;
	z-index:5;
}
#pricing .pricing-table-3 .pricing-table-header p{
	font-size:12px;
	opacity:0.7;
}
#pricing .pricing-table-3 .pricing-table-header::before{
	content:"";
	position:absolute;
	left:-50px;
	right:-180px;
	height:125px;
	top:-50px;
	background-color:#010156;
	z-index:-1;
	transform:rotate(-20deg)
}

#pricing .pricing-table-3 .price{
	position:absolute;
	top:0px;
	text-align:right;
	padding:110px 20px 0px 0px;
	right:0px;
	left:0px;
	font-size:20px;
	z-index:4;
}
#pricing .pricing-table-3 .price::before{
	content:"";
	position:absolute;
	left:0px;
	right:0px;
	height:100px;
	bottom:-25px;
	background-color:inherit;
	transform:skewY(10deg);
	z-index:-1;
	box-shadow:0px 5px 0px 5px rgba(0,0,0,0.05);
}


#pricing .pricing-table-3 .pricing-body{
	padding:20px;
	padding-top:200px;	
}
#pricing .pricing-table-3 .pricing-table-ul li{
	color:rgba(0,0,0,0.7);
	font-size:13px;
	padding:10px;
	border-bottom:1px solid rgba(0,0,0,0.1);
}
#pricing .pricing-table-3 .pricing-table-ul .fa{
	margin-right:10px;
}
#pricing .pricing-table-3.basic .pricing-table-ul .fa{
	color:#28b6f6;
}
#pricing .pricing-table-3.premium .pricing-table-ul .fa{
	color:#e65d0f;
}
#pricing .pricing-table-3.business .pricing-table-ul .fa{
	color:#c3185c;
}
#pricing .pricing-table-3 .view-more{
	margin:10px 20px;
	display:block;
	text-align:center;
	background-color:#010156;
	padding:10px 0px;
	color:#FFF;
}




