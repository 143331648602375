.icon-items {
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .icon-items:hover {
    transform: scale(1.1);
    color: #E65D0F !important;
  }
  
  .items:hover {
    color: #E65D0F !important;
  }

  .items {
    transition: transform 0.3s ease, color 0.3s ease;
    color: #010156 !important
  }
  
  .items:hover {
    transform: scale(1.1);
    color: #ffffff !important;
  }
  
  .items:hover .icon {
    color: #ffffff !important;
  }
  


@media (max-width: 768px) {  
    /* .mobile-sbar{
        display: none !important;
    } */
    .dashboardImg img{
        width: 40px !important;
        margin-left: 10% !important;
    }
    .input-group {
        width: 50% !important;
    }

    .input-group .form-control {
        font-size: 14px;
    }
    .main-menu {
      display: block !important;
      padding-right: 0%;
       /* Ensure it is visible */
    }
    .mega-menu-wrapper {
      position: relative;
      z-index: 9999;
    }
}