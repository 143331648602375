@import url(./assets/css/main.css);
@import url(./assets/css/meanmenu.css);
@import url(./assets/css/nice-select.css);
@import url(./assets/css/slick.css);
@import url(./assets/css/magnific-popup.css);
@import url(./assets/css/swiper-bundle.min.css);






*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.8;
  overflow-x: hidden;
  /* font-family: ; */
}

/* .table-container {
  width: 100%;
  max-width: 100%; 
  overflow-x: auto !important; 
  display: block; 
  -webkit-overflow-scrolling: touch; 
}

.table-container table {
  width: 100%; 
  table-layout: auto;
}

.table-container th, .table-container td {
  word-wrap: break-word; 
  overflow: hidden;
  text-overflow: ellipsis; 
  padding: 10px;
  max-width: 200px; 
  white-space: nowrap; 
} */





/* body.admin {
  background-color: #010156;
  background-size: cover;
} */

.sidebar-full-height {
  height: 100vh;
  padding-top: 20px;
}

.sidebar-full-height ul {
  padding-left: 0;
}

.sideicon-full-height {
  height: 100vh;
  width:5px !important;
}


.blu {
  background-color: #010156 !important;
  color: #fff;
}

.grey {
  background-color: #96958fba !important;
  color: #fff;
}

.blutext {
  color: #010156 !important;
}

.orange {
  background-color: #E65D0F !important;
}

.orangetext{
  color: #E65D0F !important;
}

.service-box-items {
  transition: color 0.3s ease;
}

.service-box-items:hover .whitaker{
  color: #ffffff !important;
}

@media screen and (max-width: 768px) {
  .hero-content h1{
    font-size: large !important;
  }
  .hero-content p{
    font-size: small !important;
    margin: auto !important;
    padding-top: 10px !important;
  }
}


