/*
Theme Name: Netband
Author: Modina Theme
Author URI: https://themeforest.net/user/modinatheme/
Description: Internet Provider and Broadband TV HTML Template
Version: 1.0.0
*/
/*CSS Table Of Content Ends Here*/
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Kode+Mono:wght@400..700&display=swap"); */
/* @import url('https://fonts.cdnfonts.com/css/museo-slab');
@import url("https://fonts.cdnfonts.com/css/museo-sans-rounded"); */
@import url("https://fonts.googleapis.com/css?family=montserrat");
                
:root {
  --body: #fff;
  --black: #000;
  --white: #ffffff;
  --theme: #E65D0F;
  --theme2: #010156;
  --header: #171422;
  --base: #166FD3;
  --text: #5E5F63;
  --text2: #8A8C94;
  --border: #EEEFF4;
  --border2: #D7D7D7;
  --button: #1C2539;
  --button2: #030734;
  --ratting: #F09815;
  --bg: #F6F3EE;
  --bg2: #F6F6F6;
  --bg3: #F5F6FD;
  --bg4: #020001;
  --bg5: #F8F8F8;
  --bg6: #020626;
  --bg7: #EDEEEE;
  --bg8: #111;
  --color-gradient-1: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 19.36%, rgba(15, 116, 230, 0.55) 71.26%, #166FD3 100%);
  --color-gradient-2: linear-gradient(180deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.78) 100%);
  ---box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.04);
}

.theme-btn {
  background-color: var(--theme2);
  font-weight: 700;
  color: var(--white);
  transition: all 0.4s ease-in-out;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  font-size: 14px; /* Reduced font size */
  padding: 14px 28px; /* Reduced padding */
  border-radius: 7px;
  z-index: 9;
  display: inline-block;
  line-height: 1;
}

@media (max-width: 767px) {
  .theme-btn {
    padding: 16px 24px; /* Adjusted padding for smaller screens */
  }
}

@media (max-width: 575px) {
  .theme-btn {
    padding: 12px 20px; /* Further reduced padding for mobile screens */
    font-size: 12px; /* Reduced font size for mobile screens */
  }
  .theme-btn span {
    font-size: 12px; /* Reduced font size for text inside button */
  }
}

.theme-btn i {
  margin-left: 8px;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .theme-btn i {
    margin-left: 3px;
    font-size: 12px;
  }
}
.theme-btn span {
  position: relative;
  z-index: 9;
}
.theme-btn::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 0;
  content: "";
  background-color: #E65D0F;
  transition: all 0.4s ease-in-out;
  border-radius: 0 7px 7px 0;
  z-index: 1;
}
.theme-btn::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 0;
  content: "";
  background-color: #E65D0F;
  transition: all 0.4s ease-in-out;
  border-radius: 7px 0 0 7px;
  z-index: 1;
}
.theme-btn:hover {
  color: var(--white);
}
.theme-btn:hover::before {
  height: 100%;
}
.theme-btn:hover::after {
  height: 100%;
}
.theme-btn:hover i {
  transform: translate(5px);
}
.theme-btn.hover-white::before, .theme-btn.hover-white::after {
  background-color: var(--white);
}
.theme-btn.hover-white:hover {
  color: var(--header);
}
.theme-btn.hover-white:hover i {
  transform: translate(5px);
  color: var(--header);
}
.theme-btn.bg-white {
  color: var(--header);
}
.theme-btn.bg-white:hover {
  color: var(--white);
}
.theme-btn.bg-white:hover i {
  transform: translate(5px);
  color: var(--white);
}
.theme-btn.theme-btn-2 {
  background-color: var(--theme2);
}

.theme-btn-3 {
  background-color: #e65d0f;
}

.theme-btn-3:hover {
  transform: translate(5px);
  background-color: #010156;
}



.theme-btn-3 {
  display: inherit;
  text-align: center;
  line-height: 1.3;
  margin: 0 auto;
  padding: 0 2px;
}

.theme-btn-3 li a {
  color: #fff;
}
.theme-btn.line-height {
  line-height: 1.3;
}
@media (max-width: 767px) {
  .theme-btn {
    padding: 22px 30px;
  }
}
@media (max-width: 575px) {
  .theme-btn {
    padding: 18px 30px;
  }
  .theme-btn span {
    font-size: 14px;
  }
}

.link-btn {
  text-transform: capitalize;
  font-size: 16px;
  color: var(--header);
  font-weight: 600;
}
.link-btn span {
  background-image: linear-gradient(var(--theme), var(--theme));
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.link-btn i {
  margin-left: 10px;
  font-size: 16px;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 25px;
  background-color: var(--theme);
  text-align: center;
  color: var(--white);
}
.link-btn:hover span {
  color: var(--theme);
  background-size: 100% 1px;
}
.link-btn.link-btn-2 span {
  background-image: linear-gradient(var(--theme2), var(--theme2));
}
.link-btn.link-btn-2 i {
  background-color: var(--theme2);
}
.link-btn.link-btn-2:hover span {
  color: var(--theme2);
}

/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
body {
  font-family: 'montserrat', sans-serif;                                         
  font-size: 17px;
  font-weight: 300;
  line-height: 28px;
  color: var(--text);
  background-color: var(--white);
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  /* cursor: none; */
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}

input:focus {
  color: var(--white);
  outline: none;
}

input {
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "montserrat" !important;
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: var(--header);
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
}

h1 {
  font-size: 85px;
  font-weight: 600;
  line-height: 106%;
}

h2 {
  font-size: 48px;
  line-height: 115%;
  font-weight: 700;
}
@media (max-width: 1199px) {
  h2 {
    font-size: 42px;
  }
}
@media (max-width: 991px) {
  h2 {
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 26px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 121%;
}
@media (max-width: 575px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 22px;
  font-weight: 700;
}
@media (max-width: 575px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 20px;
  font-weight: 600;
}

h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 156%;
}

a {
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
  color: var(--header);
  transition: all 0.4s ease-in-out;
}

p {
  margin: 0px;
  transition: all 0.4s ease-in-out;
}

.preloader {
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
.preloader .animation-preloader {
  z-index: 1000;
}
.preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: var(--theme);
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}
@media (max-width: 767px) {
  .preloader .animation-preloader .spinner {
    width: 7.5em;
    height: 7.5em;
    margin: 0 auto 1.5em auto;
  }
}
.preloader .animation-preloader .txt-loading {
  font: bold 5em "Inter", sans-serif, "Inter", sans-serif;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 767px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 2.5em;
  }
}
.preloader .animation-preloader .txt-loading .letters-loading {
  color: var(--theme);
  position: relative;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.preloader .animation-preloader .txt-loading .letters-loading::before {
  animation: letters-loading 4s infinite;
  color: var(--header);
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  font-family: "montserrat";
  position: absolute;
  top: -3px;
  transform: rotateY(-90deg);
}
.preloader p {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: var(--theme);
}
.preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}
.preloader .loader .row {
  height: 100%;
}
.preloader .loader .loader-section {
  padding: 0px;
}
.preloader .loader .loader-section .bg {
  background-color: #fff;
  height: 100%;
  left: 0;
  width: 100%;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}
.preloader.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
.preloader.loaded .loader-section .bg {
  width: 0;
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.9);
}
.search-wrap .search-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.search-wrap .search-cell {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.search-wrap .search-field-holder {
  width: 50%;
  margin: auto;
  position: relative;
  animation: slideInUp 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-wrap .search-field-holder {
    width: 70%;
  }
}
@media (max-width: 575px) {
  .search-wrap .search-field-holder {
    width: 80%;
  }
}
.search-wrap .main-search-input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: capitalize;
  background: transparent;
  font-size: 25px;
  color: var(--header);
  border-bottom: 2px solid var(--header);
  text-align: center;
  letter-spacing: 2px;
}

@media (max-width: 575px) {
  .search-wrap .main-search-input {
    height: 50px;
    padding: 0 0;
    line-height: 50px;
    font-size: 18px;
  }
}
.search-wrap input.form-control,
.search-wrap input.form-control:focus {
  background-color: var(--header);
}

input.main-search-input::-moz-placeholder {
  color: var(--header);
  opacity: 1;
  font-size: 25px;
}

input.main-search-input::placeholder {
  color: var(--header);
  opacity: 1;
  font-size: 25px;
}

@media (max-width: 575px) {
  input.main-search-input::-moz-placeholder {
    font-size: 18px;
  }
  input.main-search-input::placeholder {
    font-size: 18px;
  }
}
.search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 30px;
  color: var(--theme);
  cursor: pointer;
}

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  transform: translateZ(0);
  visibility: hidden;
}
@media (max-width: 767px) {
  .mouse-cursor {
    display: none;
  }
}

.cursor-inner {
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: var(--theme);
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -35px;
  margin-top: -35px;
  width: 70px;
  height: 70px;
  background-color: var(--theme);
  opacity: 0.3;
}

.cursor-outer {
  margin-left: -12px;
  margin-top: -12px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--theme);
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  transition: all 0.08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

.section-title {
  position: relative;
  z-index: 99;
  margin-bottom: 30px;
  margin-top: -6px;
}
@media (max-width: 767px) {
  .section-title {
    margin-bottom: 0;
  }
}
.section-title span {
  color: var(--theme);
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  text-decoration-line: underline;
}
.section-title span.color-style {
  color: var(--theme);
}
.section-title h2 {
  text-transform: capitalize;
}
.section-title.style-2 span {
  color: var(--theme2);
  text-decoration: none;
  letter-spacing: 0;
}
.section-title.style-2 h2 {
  text-transform: uppercase;
}
.section-title.style-2 h2 span {
  color: var(--theme2);
  letter-spacing: 0;
  text-decoration: none;
}

.title-section-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .title-section-area {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    text-align: center;
  }
}

.center {
  text-align: center;
  margin: 0 auto;
}

.section-bg {
  background-color: var(--bg);
}

.section-bg-2 {
  background-color: var(--bg5);
}

.section-bg-3 {
  background-color: var(--header);
}

.section-bg-4 {
  background-color: var(--bg7);
}

.body-bg-4 {
  background-color: var(--bg8) !important;
}

.footer-bg {
  background-color: var(--bg4);
}

.footerbg{
  background-color: #DFE0E2;
}

.section-padding {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .section-padding {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .section-padding {
    padding: 80px 0;
  }
}
@keyframes rippleOne {
  70% {
    box-shadow: 0 0 0 40px rgba(244, 68, 56, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
  }
}
@keyframes toggle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveCursor1 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}
@keyframes moveCursor2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes cir36 {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rounded {
  50% {
    transform: rotate(15deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.animation__rotate {
  animation: rotate 40s linear infinite;
}

@keyframes rotateY {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.animation__rotateY {
  animation: rotateY 40s linear infinite;
}

@keyframes rounded {
  50% {
    transform: rotate(30deg);
  }
}
@keyframes handed {
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes right-image-bounce {
  0% {
    transform: translateX(8px);
  }
  50% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(8px);
  }
}
@keyframes top-image-bounce {
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(8px);
  }
}
@keyframes scale-up-one {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes tpswing {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes width {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.header-top-section {
  background-color: var(--bg4);
  padding: 10px 0;
  position: relative;
  z-index: 9;
}
@media (max-width: 991px) {
  .header-top-section {
    display: none;
  }
}
.header-top-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--theme);
  z-index: -1;
  left: 73%;
}
@media (max-width: 1899px) {
  .header-top-section::before {
    display: none;
  }
}

.header-top-section-2 {
  background-color: var(--bg7);
}
@media (max-width: 1199px) {
  .header-top-section-2 {
    display: none;
  }
}

.header-top-section-3 {
  background-color: #F1F5FF;
}
@media (max-width: 1199px) {
  .header-top-section-3 {
    display: none;
  }
}
.header-top-section-3 .container-fluid {
  padding: 0 100px;
}
@media (max-width: 1399px) {
  .header-top-section-3 .container-fluid {
    padding: 0;
  }
}

.header-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-top-wrapper .contact-list {
  display: flex;
  align-items: center;
  gap: 25px;
}
.header-top-wrapper .contact-list li {
  font-size: 15px;
  color: var(--text2);
}
.header-top-wrapper .contact-list li a {
  color: var(--text2);
}
.header-top-wrapper .contact-list li i {
  color: var(--theme);
  margin-right: 10px;
}
.header-top-wrapper .top-right {
  display: flex;
  align-items: center;
  gap: 65px;
}
.header-top-wrapper .top-right .social-icon {
  gap: 15px;
}
.header-top-wrapper .top-right .social-icon a {
  color: var(--text2);
}
.header-top-wrapper .top-right .social-icon a:hover {
  color: var(--theme);
}
.header-top-wrapper .top-right .header-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header-top-wrapper .top-right .header-menu li a {
  color: var(--white);
  background-image: linear-gradient(var(--white), var(--white));
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.header-top-wrapper .top-right .header-menu li a:hover {
  background-size: 100% 1px;
}
@media (max-width: 1899px) {
  .header-top-wrapper .top-right .header-menu li a {
    color: var(--text2);
    background-image: linear-gradient(var(--text2), var(--text2));
  }
}
.header-top-wrapper.style-2 .contact-list {
  display: initial;
}
.header-top-wrapper.style-2 .contact-list li {
  color: var(--header);
  font-weight: 500;
}
.header-top-wrapper.style-2 .contact-list li .line-none {
  text-decoration: none !important;
}
.header-top-wrapper.style-2 .top-left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.header-top-wrapper.style-2 .flag-wrap {
  position: relative;
  width: 160px;
}
.header-top-wrapper.style-2 .flag-wrap .nice-select {
  padding: 10px 7px 10px 55px;
  background: transparent;
  border: none;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 999;
}
.header-top-wrapper.style-2 .flag-wrap .nice-select span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--header);
}
.header-top-wrapper.style-2 .flag-wrap .flag {
  position: absolute;
  top: 9px;
  left: 15px;
  z-index: 1;
}
@media (max-width: 767px) {
  .header-top-wrapper.style-2 .flag-wrap .flag {
    display: none;
  }
}
.header-top-wrapper.style-2 .flag-wrap .flag img {
  width: 100%;
  height: 100%;
  border: 4px solid var(--white);
  border-radius: 50%;
}
.header-top-wrapper.style-2 .top-right {
  gap: 35px;
}
.header-top-wrapper.style-2 .top-right .header-menu {
  padding: 15px 25px;
  background-color: var(--theme);
}
@media (max-width: 1899px) {
  .header-top-wrapper.style-2 .top-right .header-menu li a {
    color: var(--white);
    background-image: linear-gradient(var(--white), var(--white));
  }
}
.header-top-wrapper.style-2 .top-right .social-icon {
  gap: 20px;
}
.header-top-wrapper.style-2 .top-right .social-icon a {
  color: var(--header);
}
.header-top-wrapper.style-2 .top-right .social-icon a:hover {
  color: var(--theme);
}
.header-top-wrapper.style-3 .top-left {
  display: initial;
}
.header-top-wrapper.style-3 .top-left .contact-list {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 1600px) {
  .header-top-wrapper.style-3 .top-left .contact-list {
    gap: 10px;
  }
}
.header-top-wrapper.style-3 .top-left .contact-list li {
  font-size: 15px;
  color: var(--header);
  font-weight: 500;
}
@media (max-width: 1600px) {
  .header-top-wrapper.style-3 .top-left .contact-list li {
    font-size: 14px;
  }
}
.header-top-wrapper.style-3 .top-left .contact-list li:not(:last-child) {
  border-right: 1px solid var(--border2);
  padding-right: 20px;
}
@media (max-width: 1600px) {
  .header-top-wrapper.style-3 .top-left .contact-list li:not(:last-child) {
    padding-right: 10px;
  }
}
.header-top-wrapper.style-3 .top-left .contact-list li span {
  font-size: 13px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--theme2);
  padding: 5px 10px;
  color: var(--white);
  margin-right: 10px;
}
.header-top-wrapper.style-3 .top-left .contact-list li a {
  text-decoration: underline;
  color: var(--header);
}
.header-top-wrapper.style-3 .top-left .contact-list li a:hover {
  color: var(--theme2);
}
.header-top-wrapper.style-3 .top-left .contact-list li i {
  color: var(--theme2);
  margin-right: 10px;
}
.header-top-wrapper.style-3 .top-right {
  gap: 5px;
}
.header-top-wrapper.style-3 .top-right .nice-select.style-2 {
  background: transparent;
  border: none;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  padding: 0 30px;
}
.header-top-wrapper.style-3 .top-right .social-icon {
  background-color: var(--theme2);
  padding: 18px 25px;
}
.header-top-wrapper.style-3 .top-right .social-icon a {
  color: var(--white);
}
.header-top-wrapper.color-style .top-left {
  display: initial;
}
.header-top-wrapper.color-style .top-left .contact-list li span {
  background-color: var(--theme);
}
.header-top-wrapper.color-style .top-left .contact-list li a:hover {
  color: var(--theme);
}
.header-top-wrapper.color-style .top-left .contact-list li i {
  color: var(--theme);
}
.header-top-wrapper.color-style .top-right .social-icon {
  background-color: var(--theme);
}

.middle-header-wrapper {
  padding: 30px 0;
  display: flex;
  align-items: center;
  gap: 80px;
}
@media (max-width: 1199px) {
  .middle-header-wrapper {
    display: none;
  }
}
.middle-header-wrapper .header-contact-items {
  display: flex;
  align-items: center;
  gap: 80px;
}
.middle-header-wrapper .header-contact-items .contact-info-items {
  display: flex;
  align-items: center;
  gap: 18px;
}
.middle-header-wrapper .header-contact-items .contact-info-items .icon {
  font-size: 36px;
  color: var(--theme);
}
.middle-header-wrapper .header-contact-items .contact-info-items .content h6 {
  font-size: 16px;
  margin-bottom: 5px;
}
.middle-header-wrapper .header-contact-items .contact-info-items .content p, .middle-header-wrapper .header-contact-items .contact-info-items .content a {
  font-size: 15px;
  color: var(--header);
}

.header-section-1 {
  position: relative;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  z-index: 999;
}
.header-section-1::before {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 110px;
  content: "";
  background-color: var(--theme);
  z-index: -1;
}
@media (max-width: 1899px) {
  .header-section-1::before {
    display: none !important;
  }
}

.header-section-2 {
  position: relative;
}
.header-section-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg5);
  z-index: -1;
  top: 50%;
}

@media (max-width: 1199px) {
  .menu-thumb {
    display: none !important;
  }
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
@media (max-width: 991px) {
  .header-main {
    padding: 15px 0;
  }
}
.header-main .main-menu ul {
  margin-bottom: 0;
  margin-left: -100px;
}
.header-main .main-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-inline-end: 40px;
}
.header-main .main-menu ul li:last-child {
  margin-inline-end: 0;
}
.header-main .main-menu ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
  padding: 30px 0;
  text-align: left;
  position: relative;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
}
.header-main .main-menu ul li a i {
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
.header-main .main-menu ul li a:hover {
  color: var(--theme) !important;
}
.header-main .main-menu ul li .submenu {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 240px;
  background: var(--white);
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  color: var(--header);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(10px);
  transition: all 0.4s ease-in-out;
}
.header-main .main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.header-main .main-menu ul li .submenu li a {
  position: relative;
  z-index: 11;
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
  padding: 0 25px;
  padding-bottom: 11px;
  padding-top: 11px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
}
.header-main .main-menu ul li .submenu li:last-child a {
  border: none;
}
.header-main .main-menu ul li .submenu li .submenu {
  inset-inline-start: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.header-main .main-menu ul li .submenu li:hover > a {
  background: var(--theme);
  color: var(--white) !important;
}
.header-main .main-menu ul li .submenu li:hover > a::after {
  color: var(--theme);
}
.header-main .main-menu ul li .submenu li:hover > .submenu {
  transform: translateY(1);
  visibility: visible;
  opacity: 1;
}
.header-main .main-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  inset-inline-end: 25px;
  transform: translateY(-50%);
  color: var(--theme);
}
.header-main .main-menu ul li .has-homemenu {
  width: 1000px;
  padding: 30px 30px 10px 30px;
  opacity: 0;
  left: -250px;
  visibility: hidden;
  padding: 30px 30px 10px 30px;
}
.header-main .main-menu ul li .has-homemenu .homemenu {
  position: relative;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb {
  position: relative;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb .demo-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  margin-top: 20px;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb .demo-button .theme-btn {
  padding: 14px 25px;
  color: var(--white) !important;
  width: initial;
  font-size: 14px;
  text-align: center;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb .demo-button .theme-btn:hover {
  color: var(--white) !important;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb::before {
  background: linear-gradient(to bottom, rgba(99, 92, 92, 0) 0%, #292930 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  content: "";
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb:hover::before {
  visibility: visible;
  opacity: 1;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb:hover .demo-button {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb:hover .homemenu-btn {
  opacity: 1;
  visibility: visible;
  bottom: 50%;
  transform: translateY(50%);
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-thumb img {
  width: 100%;
}
.header-main .main-menu ul li .has-homemenu .homemenu .homemenu-title {
  text-align: center;
  margin: 15px auto;
  display: inline-block;
  font-size: 16px;
}
.header-main .main-menu ul li:hover > a {
  color: var(--theme);
}
.header-main .main-menu ul li:hover > a::after {
  color: var(--theme);
}
.header-main .main-menu ul li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.header-1 .container-fluid {
  padding: 0 50px;
}
@media (max-width: 470px) {
  .header-1 .container-fluid {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  .header-1 .mega-menu-wrapper .header-main {
    gap: 20px;
  }
}
.header-1 .mega-menu-wrapper .header-main .header-left {
  display: flex;
  align-items: center;
  gap: 180px;
}
@media (max-width: 1899px) {
  .header-1 .mega-menu-wrapper .header-main .header-left {
    gap: 100px;
  }
}
@media (max-width: 1399px) {
  .header-1 .mega-menu-wrapper .header-main .header-left {
    gap: 90px;
  }
}
@media (max-width: 1199px) {
  .header-1 .mega-menu-wrapper .header-main .header-left {
    gap: 60px;
  }
}
.header-1 .mega-menu-wrapper .header-main .header-right {
  gap: 90px;
}
@media (max-width: 1899px) {
  .header-1 .mega-menu-wrapper .header-main .header-right {
    gap: 30px;
  }
}
@media (max-width: 1399px) {
  .header-1 .mega-menu-wrapper .header-main .header-right {
    gap: 50px;
  }
}
@media (max-width: 1199px) {
  .header-1 .mega-menu-wrapper .header-main .header-right {
    gap: 20px;
  }
}
.header-1 .mega-menu-wrapper .header-main .header-right .contact-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1600px) {
  .header-1 .mega-menu-wrapper .header-main .header-right .contact-info {
    display: none !important;
  }
}
.header-1 .mega-menu-wrapper .header-main .header-right .contact-info .content p {
  text-transform: uppercase;
}
.header-1 .mega-menu-wrapper .header-main .header-right .contact-info .content h6 {
  font-size: 16px;
}
@media (max-width: 1399px) {
  .header-1 .mega-menu-wrapper .header-main .header-right .header-button {
    display: none !important;
  }
}
.header-1 .mega-menu-wrapper .header-main .header-right .search-icon {
  color: var(--white);
}
@media (max-width: 1899px) {
  .header-1 .mega-menu-wrapper .header-main .header-right .search-icon {
    color: var(--theme);
  }
}
.header-1 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle {
  color: var(--theme);
}

.header-2 {
  position: relative;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  z-index: 999;
}
.header-2::before {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 125px;
  content: "";
  background-color: var(--theme);
  z-index: -1;
}
@media (max-width: 1899px) {
  .header-2::before {
    display: none !important;
  }
}
.header-2 .container-fluid {
  padding: 0 130px;
}
@media (max-width: 1399px) {
  .header-2 .container-fluid {
    padding: 0 50px;
  }
}
@media (max-width: 991px) {
  .header-2 .container-fluid {
    padding: 0 40px;
  }
}
@media (max-width: 575px) {
  .header-2 .container-fluid {
    padding: 0 15px;
  }
}
@media screen and (max-width: 1199px) {
  .header-2 .header-main .main-menu ul li {
    margin-inline-end: 25px;
  }
}
.header-2 .header-main .main-menu ul li .has-homemenu {
  left: -400px;
}
@media (max-width: 1600px) {
  .header-2 .header-main .main-menu ul li .has-homemenu {
    left: -300px;
  }
}
.header-2 .header-main .header-right {
  gap: 30px;
}
@media (max-width: 991px) {
  .header-2 .header-main .header-right {
    gap: 25px;
  }
}
.header-2 .header-main .header-right .menu-cart {
  position: relative;
}
@media (max-width: 1199px) {
  .header-2 .header-main .header-right .menu-cart {
    display: none;
  }
}
.header-2 .header-main .header-right .menu-cart .cart-box {
  background: var(--white) none repeat scroll 0 0;
  box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
  padding: 5px 20px 0px;
  position: absolute;
  left: -70px;
  top: 150%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 340px;
  z-index: 9999;
}
@media (max-width: 1399px) {
  .header-2 .header-main .header-right .menu-cart .cart-box {
    left: -260px;
  }
}
.header-2 .header-main .header-right .menu-cart .cart-box ul li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 15px;
  border-bottom: 1px solid var(--border);
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.header-2 .header-main .header-right .menu-cart .cart-box ul li a {
  padding: 0;
  position: relative;
  font-size: 16px;
  text-transform: capitalize;
}
.header-2 .header-main .header-right .menu-cart .cart-box ul li a::after {
  position: absolute;
  content: "\f00d";
  font-family: "montserrat";
  font-weight: 600;
  top: 15px;
  right: -88px;
  color: var(--white);
  font-size: 10px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 30px;
  background-color: var(--theme);
  text-align: center;
}
.header-2 .header-main .header-right .menu-cart .cart-box ul li span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: var(--header);
}
.header-2 .header-main .header-right .menu-cart .cart-box ul li img {
  width: 90px;
}
.header-2 .header-main .header-right .menu-cart .cart-box .shopping-items {
  margin-top: 10px;
}
.header-2 .header-main .header-right .menu-cart .cart-box .shopping-items span {
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
  text-transform: capitalize;
}
.header-2 .header-main .header-right .menu-cart .cart-box .cart-button {
  margin-top: 20px;
}
.header-2 .header-main .header-right .menu-cart .cart-box .cart-button .theme-btn {
  padding: 16px 28px;
  font-size: 14px;
}
.header-2 .header-main .header-right .menu-cart .cart-icon {
  position: relative;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  background-color: var(--bg);
  display: inline-block;
  border-radius: 50%;
}
.header-2 .header-main .header-right .menu-cart .cart-icon::before {
  position: absolute;
  top: -7px;
  right: -8px;
  content: "1";
  width: 18px;
  line-height: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: var(--theme);
  color: var(--white);
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}
.header-2 .header-main .header-right .menu-cart .cart-icon i {
  color: var(--header);
}
.header-2 .header-main .header-right .menu-cart:hover .cart-box {
  transform: rotateX(0deg);
  visibility: visible;
}
.header-2 .header-main .header-right .search-icon {
  color: var(--header);
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  background-color: var(--bg);
  border-radius: 50%;
}
@media (max-width: 1199px) {
  .header-2 .header-main .header-right .search-icon {
    display: none;
  }
}
@media (max-width: 991px) {
  .header-2 .header-main .header-right .search-icon {
    display: block;
  }
}
.header-2 .header-main .header-right .header-button {
  margin-right: 50px;
}
@media (max-width: 1600px) {
  .header-2 .header-main .header-right .header-button {
    display: none;
  }
}
.header-2 .header-main .header-right .header__hamburger {
  margin-right: -70px;
}
@media (max-width: 1899px) {
  .header-2 .header-main .header-right .header__hamburger {
    margin-right: 0;
  }
}
.header-2 .header-main .header-right .header__hamburger .sidebar__toggle {
  font-size: 24px;
  color: var(--white);
}
@media (max-width: 1899px) {
  .header-2 .header-main .header-right .header__hamburger .sidebar__toggle {
    color: var(--theme);
  }
}

.header-tops-section {
  background-color: var(--theme);
  padding: 10px 0;
  position: relative;
  z-index: 99;
}
@media (max-width: 991px) {
  .header-tops-section {
    display: none;
  }
}
.header-tops-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--theme);
  z-index: -1;
  left: 81%;
}
@media (max-width: 1899px) {
  .header-tops-section::before {
    display: none;
  }
}
.header-tops-section .container-fluid {
  padding: 0 130px;
}
@media (max-width: 1399px) {
  .header-tops-section .container-fluid {
    padding: 0 50px;
  }
}
@media (max-width: 991px) {
  .header-tops-section .container-fluid {
    padding: 0 40px;
  }
}
@media (max-width: 575px) {
  .header-tops-section .container-fluid {
    padding: 0 15px;
  }
}

.header-tops-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-tops-wrapper .contact-list {
  display: flex;
  align-items: center;
  gap: 25px;
}
.header-tops-wrapper .contact-list li {
  font-size: 15px;
  color: var(--white);
}
.header-tops-wrapper .contact-list li a {
  color: var(--white);
}
.header-tops-wrapper .contact-list li i {
  color: var(--white);
  margin-right: 10px;
}
.header-tops-wrapper .top-right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.header-tops-wrapper .top-right .social-icon {
  gap: 15px;
}
.header-tops-wrapper .top-right .social-icon span {
  color: var(--white);
  font-weight: 500;
}
.header-tops-wrapper .top-right .social-icon a {
  color: var(--white);
}
.header-tops-wrapper .top-right .flag-wrap {
  position: relative;
  width: 160px;
}
.header-tops-wrapper .top-right .flag-wrap .nice-select {
  padding: 0 0 0 60px;
  background: transparent;
  border: none;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  border: none !important;
  padding-bottom: 0 !important;
}
.header-tops-wrapper .top-right .flag-wrap .nice-select::after {
  right: 23px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  width: 10px;
  height: 10px;
  top: 15px;
  border-bottom: 2px solid transparent;
  border-color: var(--theme);
  border-right: 2px solid theme-color;
}
.header-tops-wrapper .top-right .flag-wrap .nice-select span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--white);
}
.header-tops-wrapper .top-right .flag-wrap .flag {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 1;
}
@media (max-width: 767px) {
  .header-tops-wrapper .top-right .flag-wrap .flag {
    display: none;
  }
}
.header-tops-wrapper .top-right .flag-wrap .flag img {
  width: 100%;
  height: 100%;
  border: 4px solid var(--white);
  border-radius: 50%;
}

.header-3 .container-fluid {
  padding: 0 50px;
}
@media (max-width: 1399px) {
  .header-3 .container-fluid {
    padding: 0 20px;
  }
}
.header-3 .mega-menu-wrapper .header-main .header-left .main-menu ul li a:hover {
  color: var(--theme2) !important;
}
.header-3 .mega-menu-wrapper .header-main .header-left .main-menu ul li .submenu li:hover > a {
  background: var(--theme2);
  color: var(--white) !important;
}
.header-3 .mega-menu-wrapper .header-main .header-left .main-menu ul li .submenu li:hover > a::after {
  color: var(--theme2);
}
.header-3 .mega-menu-wrapper .header-main .header-left .main-menu ul li .submenu li.has-dropdown > a::after {
  color: var(--theme2);
}
.header-3 .mega-menu-wrapper .header-main .header-left .main-menu ul li:hover > a {
  color: var(--theme2);
}
.header-3 .mega-menu-wrapper .header-main .header-left .main-menu ul li:hover > a::after {
  color: var(--theme2);
}
.header-3 .mega-menu-wrapper .header-main .header-right {
  gap: 30px;
}
.header-3 .mega-menu-wrapper .header-main .header-right .header-button {
  line-height: 1;
}
@media (max-width: 1199px) {
  .header-3 .mega-menu-wrapper .header-main .header-right .header-button {
    display: none;
  }
}
.header-3 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle {
  color: var(--header);
}
.header-3 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle .header-bar span {
  background: var(--header);
}
.header-3 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle .header-bar span:first-child {
  background: var(--header);
}
.header-3 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle .header-bar span:nth-child(2) {
  background: var(--header);
}
.header-3 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle .header-bar span:last-child {
  background: var(--header);
}
.header-3 .mega-menu-wrapper .header-main.style-2 .header-left .main-menu ul li a:hover {
  color: var(--theme) !important;
}
.header-3 .mega-menu-wrapper .header-main.style-2 .header-left .main-menu ul li .submenu li:hover > a {
  background: var(--theme);
  color: var(--white) !important;
}
.header-3 .mega-menu-wrapper .header-main.style-2 .header-left .main-menu ul li .submenu li:hover > a::after {
  color: var(--theme);
}
.header-3 .mega-menu-wrapper .header-main.style-2 .header-left .main-menu ul li .submenu li.has-dropdown > a::after {
  color: var(--theme);
}
.header-3 .mega-menu-wrapper .header-main.style-2 .header-left .main-menu ul li:hover > a {
  color: var(--theme);
}
.header-3 .mega-menu-wrapper .header-main.style-2 .header-left .main-menu ul li:hover > a::after {
  color: var(--theme);
}

.header-4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.header-4 .container-fluid {
  padding: 0 100px;
}
@media (max-width: 1399px) {
  .header-4 .container-fluid {
    padding: 0 20px;
  }
}
.header-4 .header-main .main-menu ul li a {
  color: var(--white);
}
.header-4 .header-main .main-menu ul li a:hover {
  color: var(--theme) !important;
}
.header-4 .header-right {
  gap: 50px;
}
@media (max-width: 1199px) {
  .header-4 .header-right {
    gap: 20px;
  }
}
.header-4 .header-right .search-icon {
  color: var(--white);
}
.header-4 .header-right .search-icon:hover {
  color: var(--theme);
}
@media (max-width: 1199px) {
  .header-4 .header-right .header-button {
    display: none;
  }
}
.header-4 .header-right .sidebar__toggle {
  color: var(--white);
}
.header-4 .header-logo {
  display: none;
}

.sidebar__toggle {
  cursor: pointer;
}
.sidebar__toggle .header-bar {
  position: relative;
  width: 31px;
  height: 18px;
}
.sidebar__toggle .header-bar span {
  position: absolute;
  width: 100%;
  height: 2px;
  display: inline-block;
  transition: all 0.3s;
  left: 0;
  background: var(--white);
  overflow: hidden;
}
.sidebar__toggle .header-bar span:first-child {
  top: 0;
  background: var(--white);
  left: 16px;
  width: 15px;
}
.sidebar__toggle .header-bar span:nth-child(2) {
  top: 44%;
  background: var(--white);
  width: 30px;
}
.sidebar__toggle .header-bar span:last-child {
  bottom: 0;
  background: var(--white);
  width: 15px;
}
.sidebar__toggle .header-bar.active span:first-child {
  transform: rotate(45deg) translate(3px, 9px);
}
.sidebar__toggle .header-bar.active span:nth-child(2) {
  opacity: 0;
}
.sidebar__toggle .header-bar.active span:last-child {
  transform: rotate(-45deg) translate(3px, -9px);
}
.sidebar__toggle .header-bar:hover {
  cursor: pointer;
}

.sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  transition: all 0.9s;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.sticky .header-main {
  padding: 0px;
}
.sticky.header-1 .mega-menu-wrapper .header-main .header-right .search-icon {
  color: var(--theme);
}
.sticky.header-1 .mega-menu-wrapper .header-main .header-right .header__hamburger .sidebar__toggle {
  color: var(--theme);
}
.sticky.header-2 {
  background-color: var(--white);
  box-shadow: var(---box-shadow);
}
.sticky.header-4 .header-main .main-menu ul li a {
  color: var(--header);
}
.sticky.header-4 .header-main .main-menu ul li a:hover {
  color: var(--theme) !important;
}
.sticky.header-4 .header-main .header-logo-2 {
  display: none;
}
.sticky.header-4 .header-main .header-logo {
  display: block;
}
.sticky.header-4 .header-right .search-icon {
  color: var(--header);
}
.sticky.header-4 .header-right .search-icon:hover {
  color: var(--theme);
}
.sticky.header-4 .header-right .sidebar__toggle {
  color: var(--header);
}

.offcanvas__info {
  background: var(--white) none repeat scroll 0 0;
  display: block !important;
  border-left: 2px solid var(--theme);
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  transform: translateX(calc(100% + 80px));
  transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  z-index: 9999;
  overflow: auto;
  /* overflow-y: scroll; */
  pointer-events: auto;
  overscroll-behavior-y: contain;
  scrollbar-width: none;
}
.offcanvas__info::-webkit-scrollbar {
  display: none;
}

.offcanvas__info.info-open {
  opacity: 1;
  transform: translateX(0);
}

.offcanvas__wrapper {
  position: relative;
  height: 100%;
  padding: 30px 30px;

  z-index: 10000;
}
.offcanvas__wrapper .offcanvas__content .text {
  color: var(--text);
}
.offcanvas__wrapper .offcanvas__content .offcanvas__close {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--theme);
  position: relative;
  /* z-index: 9; */
  z-index: 1;
  cursor: pointer;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__close i {
  color: var(--white);
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact {
  margin-top: 50px;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul {
  margin-top: 20px;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li:not(:last-child) {
  margin-bottom: 15px;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li .offcanvas__contact-icon {
  margin-right: 20px;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li .offcanvas__contact-icon i {
  color: var(--theme);
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact .header-button .theme-btn {
  width: 100%;
  padding: 20px 40px;
}

/* .header-button{
  padding: auto !important;
} */
.offcanvas__wrapper .offcanvas__content .offcanvas__contact .social-icon {
  margin-top: 30px;
  gap: 10px;
}
.offcanvas__wrapper .offcanvas__content .offcanvas__contact .social-icon a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: transparent;
  color: var(--header);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  text-align: center;
  border: 1px solid var(--border);
}
.offcanvas__wrapper 
.offcanvas__content
.offcanvas__contact 
.social-icon 
a:hover {
  background-color: var(--theme);
  color: var(--white);
}

.offcanvas__overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #151515;
  z-index: 900;
  pointer-events: none;
  top: 0;
  opacity: 0;
  visibility: hidden;
  right: 0;
}

.offcanvas__overlay
.overlay-open {
  opacity: 0.8;
  visibility: visible;
}

.mobile-menu.offcanvas__info.offcanvas__wrapper {
  z-index: 10001; /* Ensures menu items are interactive */
}


@media (max-width: 450px) {
  .offcanvas__info {
    /* display: block !important; */
    max-width: 90%; 
    padding: 10px;
}
}

.breadcrumb-wrapper {
  position: relative;
  overflow: hidden;
}
.breadcrumb-wrapper::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background: rgba(16, 10, 47, 0.58); */
}
.breadcrumb-wrapper .layer-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 991px) {
  .breadcrumb-wrapper .layer-shape {
    display: none;
  }
}
.breadcrumb-wrapper .layer-shape img {
  width: 100%;
  height: 100%;
}
.breadcrumb-wrapper .breadcrumb-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.breadcrumb-wrapper .breadcrumb-shape img {
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .breadcrumb-wrapper .breadcrumb-shape {
    display: none;
  }
}
.breadcrumb-wrapper .page-heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
}
@media (max-width: 767px) {
  .breadcrumb-wrapper .page-heading {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}
.breadcrumb-wrapper .page-heading h1 {
  color: var(--white);
  font-size: 70px;
  position: relative;
  z-index: 9;
  line-height: 1.3;
}
@media (max-width: 991px) {
  .breadcrumb-wrapper .page-heading h1 {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .breadcrumb-wrapper .page-heading h1 {
    font-size: 42px;
  }
}
.breadcrumb-wrapper .page-heading .breadcrumb-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.breadcrumb-wrapper .page-heading .breadcrumb-items li {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .breadcrumb-wrapper .page-heading .breadcrumb-items li {
    font-size: 16px;
  }
}
.breadcrumb-wrapper .page-heading .breadcrumb-items li a {
  color: var(--theme);
  transition: all 0.4s ease-in-out;
}
.breadcrumb-wrapper .page-heading .breadcrumb-items li a:hover {
  color: var(--theme);
}
.breadcrumb-wrapper .page-heading .breadcrumb-items li i {
  font-size: 18px;
  color: var(--theme);
}
@media (max-width: 575px) {
  .breadcrumb-wrapper .page-heading .breadcrumb-items li i {
    font-size: 16px;
  }
}

.error-items .error-image {
  margin-bottom: 50px;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
}
.error-items .error-image img {
  width: 100%;
  height: 100%;
}

.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}
.mean-container .mean-nav > ul .homemenu {
  position: relative;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb {
  position: relative;
  width: 280px;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb .demo-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb .demo-button .theme-btn {
  padding: 10px 30px;
  color: var(--white) !important;
  width: initial;
  font-size: 14px;
  text-align: center;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb .demo-button .theme-btn:hover {
  color: var(--white) !important;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb::before {
  background: linear-gradient(to bottom, rgba(99, 92, 92, 0) 0%, #292930 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  content: "";
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb:hover::before {
  visibility: visible;
  opacity: 1;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb:hover .demo-button {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb:hover .homemenu-btn {
  opacity: 1;
  visibility: visible;
  bottom: 50%;
  transform: translateY(50%);
}
.mean-container .mean-nav > ul .homemenu .homemenu-thumb img {
  width: 100%;
}
.mean-container .mean-nav > ul .homemenu .homemenu-content .homemenu-title {
  margin-top: 15px;
  display: inline-block;
  font-size: 16px;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: var(--header);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
}
.mean-container .mean-nav ul li a:hover {
  color: var(--theme2);
}

.mean-container .mean-nav ul li a:last-child {
  border-bottom: 0;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--theme2);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
}

.mean-container .mean-nav ul li .mega-menu li a {
  height: 200px;
  width: 100%;
  padding: 0;
  border-top: 0;
  margin-bottom: 20px;
}

.hero-1 {
  position: relative;
}
.hero-1 .array-button {
  position: initial;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.hero-1 .array-button .array-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  left: 20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.hero-1 .array-button .array-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  right: 20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
}
@media (max-width: 1199px) {
  .hero-1 .array-button {
    top: 70%;
  }
}
@media (max-width: 767px) {
  .hero-1 .array-button {
    display: none;
  }
}
.hero-1:hover .array-button {
  opacity: 1;
  visibility: visible;
}
.hero-1 .swiper-slide-active .hero-image {
  transform: scale(1.12);
}
.hero-1 .hero-image {
  overflow: hidden;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -2;
  background-size: cover;
  transform: scale(1);
  transition: all 8s ease-out 0s;
}
.hero-1 .hero-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background-color: rgb(3, 0, 17); */
  opacity: 0.749;
}
.hero-1 .hero-content {
  position: relative;
  padding: 190px 0;
}
@media (max-width: 991px) {
  .hero-1 .hero-content {
    padding: 160px 0;
  }
}
@media (max-width: 767px) {
  .hero-1 .hero-content {
    padding: 130px 0;
    text-align: center;
    margin: 0 auto;
  }
}
@media (max-width: 575px) {
  .hero-1 .hero-content {
    padding: 100px 0;
  }
}
.hero-1 .hero-content h6 {
  font-size: 20px;
  color: var(--theme);
  margin-bottom: 15px;
}
.hero-1 .hero-content h1 {
  font-size: 75px;
}
@media (max-width: 1399px) {
  .hero-1 .hero-content h1 {
    font-size: 80px;
  }
}
@media (max-width: 1199px) {
  .hero-1 .hero-content h1 {
    font-size: 72px;
  }
}
@media (max-width: 991px) {
  .hero-1 .hero-content h1 {
    font-size: 62px;
  }
}
@media (max-width: 767px) {
  .hero-1 .hero-content h1 {
    font-size: 48px;
  }
}
@media (max-width: 575px) {
  .hero-1 .hero-content h1 {
    font-size: 36px;
    line-height: 1.3;
  }
}
.hero-1 .hero-content h2 {
  color: var(--white);
  margin-top: 15px;
}
@media (max-width: 1199px) {
  .hero-1 .hero-content h2 {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .hero-1 .hero-content h2 {
    margin-bottom: 20px;
    font-size: 40px;
  }
}
.hero-1 .hero-content h2 sub {
  font-size: 18px;
}
.hero-1 .hero-content h2 span {
  font-size: 18px;
  margin-left: -10px;
}
.hero-1 .hero-content p {
  font-size: 18px;
  font-weight: 600;
  color: var(--theme);
  margin-top: -10px;
}
.hero-1 .hero-content .hero-button {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .hero-1 .hero-content .hero-button {
    margin-top: 40px;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .hero-1 .hero-content .hero-button {
    margin-top: 25px;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.hero-1 .hero-content .hero-button .video-btn {
  background-color: var(--theme);
  color: var(--white);
  display: inline-block;
  font-size: 16px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  position: relative;
}
.hero-1 .hero-content .hero-button .video-btn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid var(--theme);
  content: "";
  transform: translate(-50%, -50%);
}
.hero-1 .hero-content .hero-button .button-text span {
  font-size: 16px;
  color: var(--white);
  font-weight: bold;
}
.hero-1 .frame-shape {
  position: relative;
  z-index: 1;
  animation: rounded 6s linear infinite;
}
@media (max-width: 575px) {
  .hero-1 .frame-shape {
    display: none;
  }
}
.hero-1.style-hero-3 .array-button-2 .array-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  left: 20px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: var(--white);
  color: var(--header);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.hero-1.style-hero-3 .array-button-2 .array-prev:hover {
  background-color: var(--theme2);
  color: var(--white);
}
.hero-1.style-hero-3 .array-button-2 .array-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  right: 20px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: var(--theme2);
  color: var(--white);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.hero-1.style-hero-3 .array-button-2 .array-next:hover {
  background-color: var(--white);
  color: var(--header);
}
@media (max-width: 1199px) {
  .hero-1.style-hero-3 .array-button-2 {
    top: 70%;
  }
}
@media (max-width: 767px) {
  .hero-1.style-hero-3 .array-button-2 {
    display: none;
  }
}
.hero-1.style-hero-3 .hero-image {
  position: relative;
}
.hero-1.style-hero-3 .hero-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background: #000; */
  transform: matrix(-1, 0, 0, 1, 0, 0);
  opacity: 0.65;
  filter: blur(40px);
}
.hero-1.style-hero-3 h6 {
  text-transform: uppercase;
  font-size: 18px;
  color: var(--theme2);
  text-decoration: underline;
}
.hero-1.style-hero-3 .price-item {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 767px) {
  .hero-1.style-hero-3 .price-item {
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
  }
}
.hero-1.style-hero-3 .price-item h2 {
  color: var(--theme2);
}
.hero-1.style-hero-3 .price-item .icon {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--theme2);
  font-size: 20px;
  text-decoration: underline;
}
.hero-1.style-hero-3 .price-item .icon i {
  font-size: 32px;
  color: var(--theme2);
}
.hero-1.style-hero-3 .theme-btn {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .hero-1.style-hero-3 .theme-btn {
    margin-top: 35px;
  }
}
@media (max-width: 575px) {
  .hero-1.style-hero-3 .theme-btn {
    margin-top: 25px;
  }
}

.hero-2 {
  padding: 180px 0;
}
@media (max-width: 991px) {
  .hero-2 {
    background-position: left center !important;
    padding: 160px 0;
  }
}
@media (max-width: 767px) {
  .hero-2 {
    padding: 130px 0;
  }
}
@media (max-width: 575px) {
  .hero-2 {
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .hero-2 .hero-content {
    text-align: center;
  }
}
.hero-2 .hero-content h1 {
  font-size: 80px;
  line-height: 114%;
  margin-bottom: 30px;
}
@media (max-width: 1399px) {
  .hero-2 .hero-content h1 {
    font-size: 64px;
  }
}
@media (max-width: 1199px) {
  .hero-2 .hero-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .hero-2 .hero-content h1 {
    font-size: 48px;
  }
}
@media (max-width: 767px) {
  .hero-2 .hero-content h1 {
    font-size: 42px;
  }
}
@media (max-width: 575px) {
  .hero-2 .hero-content h1 {
    font-size: 34px;
    margin-bottom: 15px;
  }
}
.hero-2 .hero-content p {
  font-size: 20px;
  font-weight: 600;
  color: var(--header);
}
@media (max-width: 767px) {
  .hero-2 .hero-content p {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .hero-2 .hero-content p {
    font-size: 16px;
  }
}
.hero-2 .hero-content h2 {
  color: var(--theme);
  margin-top: 30px;
  font-size: 60px;
  margin-bottom: 40px;
}
.hero-2 .hero-content h2 sub {
  font-size: 18px;
  color: var(--text);
  font-weight: 500;
  margin-left: -10px;
}
@media (max-width: 575px) {
  .hero-2 .hero-content h2 sub {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .hero-2 .hero-content h2 {
    font-size: 36px;
  }
}
@media (max-width: 575px) {
  .hero-2 .hero-content h2 {
    margin-bottom: 30px;
    font-size: 28px;
  }
}

.hero-section-2 {
  position: relative;
}
.hero-section-2 .swiper-dot {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.hero-4 {
  padding: 240px 0px;
  position: relative;
  z-index: 9;
  background-size: cover;
  background-position: top center;
}
.hero-4::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #010156 0%, rgba(9, 9, 121, 0) 75%);
  content: "";
  z-index: -1;
}
@media (max-width: 991px) {
  .hero-4 {
    padding: 210px 0 150px;
  }
}
@media (max-width: 767px) {
  .hero-4 {
    padding: 180px 0 150px;
  }
}
@media (max-width: 575px) {
  .hero-4 {
    padding: 150px 0 180px;
  }
}
@media (max-width: 767px) {
  .hero-4 .hero-content {
    text-align: center;
    margin: 0 auto;
  }
}
.hero-4 .hero-content h5 {
  letter-spacing: 1.3px;
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  border-left: 4px solid var(--theme);
  padding-left: 10px;
  display: inline-block;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .hero-4 .hero-content h5 {
    font-size: 16px;
  }
}
.hero-4 .hero-content h1 {
  font-size: 72px;
  color: var(--white);
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .hero-4 .hero-content h1 {
    font-size: 62px;
  }
}
@media (max-width: 991px) {
  .hero-4 .hero-content h1 {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  .hero-4 .hero-content h1 {
    font-size: 46px;
  }
}
@media (max-width: 575px) {
  .hero-4 .hero-content h1 {
    font-size: 32px;
  }
}
.hero-4 .hero-content p {
  color: var(--white);
}
.hero-4 .hero-content .hero-list {
  margin-top: 30px;
  margin-bottom: 50px;
}
.hero-4 .hero-content .hero-list li {
  color: var(--white);
  margin: 0 0 5px;
  font-size: 18px;
}
@media (max-width: 575px) {
  .hero-4 .hero-content .hero-list li {
    font-size: 16px;
  }
}
.hero-4 .hero-content .hero-list li:not(:last-child) {
  margin-bottom: 10px;
}
.hero-4 .hero-content .hero-list li a {
  color: var(--white);
}
.hero-4 .hero-content .hero-list li strong {
  color: var(--theme);
}
.hero-4 .hero-thumb {
  position: relative;
}
@media (max-width: 767px) {
  .hero-4 .hero-thumb {
    margin-top: 50px;
  }
}
.hero-4 .hero-thumb .video-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.hero-4 .hero-thumb .video-box .video-btn {
  background-color: var(--theme);
  color: var(--white);
  display: inline-block;
  font-size: 30px;
  height: 120px;
  width: 120px;
  line-height: 120px;
  border-radius: 50%;
  text-align: center;
}

.hero-section-4 {
  position: relative;
}
.hero-section-4 .swiper-dot {
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
  z-index: 9;
}
.hero-section-4 .array-button {
  position: initial;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.hero-section-4 .array-button .array-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  left: 20px;
  width: initial;
  height: initial;
  line-height: initial;
  font-size: 100px;
  background-color: initial;
}
.hero-section-4 .array-button .array-prev:hover {
  background-color: transparent;
}
.hero-section-4 .array-button .array-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  right: 20px;
  width: initial;
  height: initial;
  line-height: initial;
  font-size: 100px;
  background-color: initial;
}
.hero-section-4 .array-button .array-next:hover {
  background-color: transparent;
}
@media (max-width: 1199px) {
  .hero-section-4 .array-button {
    top: 70%;
  }
}
@media (max-width: 767px) {
  .hero-section-4 .array-button {
    display: none;
  }
}
.hero-section-4:hover .array-button {
  opacity: 1;
  visibility: visible;
}

.feature-section {
  position: relative;
}
.feature-section .shape-image {
  position: absolute;
  top: 0;
  left: 0;
}

.single-feature-items {
  padding: 40px 35px;
  background-color: var(--bg2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}
.single-feature-items::before {
  position: absolute;
  right: 0;
  width: 20%;
  height: 100%;
  bottom: 0;
  top: 0;
  content: "";
  background-color: #f4f2e9;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}
@media (max-width: 767px) {
  .single-feature-items {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  .single-feature-items {
    padding: 20px 20px;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    text-align: center;
  }
}
.single-feature-items .content span {
  letter-spacing: 0.11em;
  text-transform: uppercase;
  font-size: 15px;
  color: var(--theme);
  font-weight: 700;
  margin-bottom: 5px;
  display: inline-block;
}
.single-feature-items .icon {
  position: relative;
  width: 100px;
  height: 100px;
  line-height: 110px;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  text-align: center;
  font-size: 42px;
  color: var(--theme);
}
@media (max-width: 575px) {
  .single-feature-items .icon {
    width: 85px;
    height: 85px;
    line-height: 85px;
  }
}
.single-feature-items:hover::before {
  background-color: var(--theme);
}
.single-feature-items.active::before {
  background-color: var(--theme);
}

.trusted-client-wrapper .trusted-client-image {
  width: 917px;
  height: 826px;
  margin-left: -70%;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .trusted-client-wrapper .trusted-client-image {
    margin-left: -90%;
  }
}
@media (max-width: 1199px) {
  .trusted-client-wrapper .trusted-client-image {
    margin-left: 0;
    width: inherit;
    max-width: 900px;
  }
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-client-image {
    height: 600px;
  }
}
@media (max-width: 575px) {
  .trusted-client-wrapper .trusted-client-image {
    height: 500px;
  }
}
.trusted-client-wrapper .trusted-client-image .counter-main-area {
  padding-top: 0;
  width: 875px;
  height: 1063px;
  position: absolute;
  top: -97px;
  left: 146px;
  bottom: 17px;
  transform: rotate(-90deg);
}
@media (max-width: 1399px) {
  .trusted-client-wrapper .trusted-client-image .counter-main-area {
    left: 400px;
  }
}
@media screen and (max-width: 1270px) {
  .trusted-client-wrapper .trusted-client-image .counter-main-area {
    left: 420px;
  }
}
@media (max-width: 1199px) {
  .trusted-client-wrapper .trusted-client-image .counter-main-area {
    left: 90px;
  }
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-client-image .counter-main-area {
    transform: initial;
    width: initial;
    height: initial;
    bottom: 0;
    left: 0;
    top: initial;
    right: 0;
  }
}
.trusted-client-wrapper .trusted-client-image .counter-main-area .counter-area {
  padding: 50px 120px;
  background-color: var(--theme);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-client-image .counter-main-area .counter-area {
    padding: 40px 50px;
    gap: 50px;
  }
}
.trusted-client-wrapper .trusted-client-image .counter-main-area .counter-area .counter-items:not(:last-child) {
  border-right: 2px solid #3282DD;
  padding-right: 90px;
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-client-image .counter-main-area .counter-area .counter-items:not(:last-child) {
    border: none;
    padding-right: 0;
  }
}
.trusted-client-wrapper .trusted-client-image .counter-main-area .counter-area .counter-items h2 {
  font-size: 48px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--white);
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
}
.trusted-client-wrapper .trusted-client-image .counter-main-area .counter-area .counter-items h3 {
  color: var(--white);
  font-weight: normal;
}
.trusted-client-wrapper .trusted-client-image .icon-box {
  position: absolute;
  bottom: 4%;
  left: 28%;
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-client-image .icon-box {
    left: 50%;
    bottom: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 575px) {
  .trusted-client-wrapper .trusted-client-image .icon-box {
    display: none;
  }
}
.trusted-client-wrapper .trusted-client-image .icon-box .text-circle {
  animation: cir36 10s linear infinite;
}
.trusted-client-wrapper .trusted-client-image .icon-box .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 154px;
  height: 154px;
  line-height: 154px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-client-image .icon-box .icon {
    top: 33%;
  }
}
.trusted-client-wrapper .trusted-content {
  margin-left: 80px;
}
@media (max-width: 1199px) {
  .trusted-client-wrapper .trusted-content {
    margin-left: 0;
  }
}
.trusted-client-wrapper .trusted-content .single-tab-items {
  margin-top: 50px;
}
@media (max-width: 575px) {
  .trusted-client-wrapper .trusted-content .single-tab-items {
    margin-top: 30px;
  }
}
.trusted-client-wrapper .trusted-content .single-tab-items .nav {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 575px) {
  .trusted-client-wrapper .trusted-content .single-tab-items .nav {
    gap: 15px;
  }
}
.trusted-client-wrapper .trusted-content .single-tab-items .nav .nav-link {
  text-align: center;
  padding: 10px 40px;
  background-color: #EBEBEB;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--header);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .trusted-client-wrapper .trusted-content .single-tab-items .nav .nav-link {
    padding: 14px 45px;
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .trusted-client-wrapper .trusted-content .single-tab-items .nav .nav-link {
    padding: 12px 20px;
    font-size: 14px;
  }
}
.trusted-client-wrapper .trusted-content .single-tab-items .nav .nav-link.active {
  position: relative;
  background-color: var(--theme);
  color: var(--white);
}
.trusted-client-wrapper .trusted-content .single-tab-items .nav .nav-link.active::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--theme);
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content {
  margin-top: 50px;
}
@media (max-width: 575px) {
  .trusted-client-wrapper .trusted-content .single-tab-items .tab-content {
    margin-top: 30px;
  }
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items ul li {
  font-size: 18px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items ul li:not(:last-child) {
  margin-bottom: 10px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items ul li i {
  color: var(--theme);
  margin-right: 5px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .icon-area {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 35px;
}
@media (max-width: 767px) {
  .trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .icon-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .icon-area .icon-items .icon {
  width: 70px;
  height: 70px;
  line-height: 75px;
  border-radius: 50%;
  background-color: var(--bg3);
  text-align: center;
  color: var(--theme);
  font-size: 32px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .pro-items {
  width: 100%;
  margin-top: 40px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .pro-items .pro-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .pro-items .pro-head .title {
  font-size: 18px;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .pro-items .pro-head .point {
  font-size: 18px;
  color: var(--text);
  font-weight: 600;
  color: var(--header);
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .pro-items .progress {
  background: var(--bg2);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 10px;
  width: 100%;
}
.trusted-client-wrapper .trusted-content .single-tab-items .tab-content .tab-content-items .pro-items .progress-value {
  animation: load 3s normal forwards;
  border-radius: 10px;
  background: var(--theme);
  height: 10px;
  width: 0;
}
@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 75%;
  }
}

.feature-icon-box-wrapper {
  padding: 80px 50px;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  position: relative;
  z-index: 9;
  margin-top: -120px;
}
@media (max-width: 575px) {
  .feature-icon-box-wrapper {
    padding: 50px 30px;
  }
}
.feature-icon-box-wrapper .icon-box-items {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
.feature-icon-box-wrapper .icon-box-items::before {
  position: absolute;
  bottom: -75%;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  background: var(--theme);
  transition: 0.3s;
  opacity: 0;
}
@media (max-width: 1399px) {
  .feature-icon-box-wrapper .icon-box-items::before {
    display: none;
  }
}
@media (max-width: 575px) {
  .feature-icon-box-wrapper .icon-box-items {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}
.feature-icon-box-wrapper .icon-box-items .icon {
  font-size: 42px;
  width: 107px;
  height: 107px;
  line-height: 115px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--bg3);
  color: var(--theme);
}
@media (max-width: 575px) {
  .feature-icon-box-wrapper .icon-box-items .icon {
    width: 80px;
    height: 80px;
    line-height: 90px;
    font-size: 32px;
  }
}
.feature-icon-box-wrapper .icon-box-items .content {
  flex-basis: 62%;
}
@media (max-width: 1199px) {
  .feature-icon-box-wrapper .icon-box-items .content {
    flex-basis: 50%;
  }
}
.feature-icon-box-wrapper .icon-box-items .content h3 {
  margin-bottom: 10px;
}
.feature-icon-box-wrapper .icon-box-items:hover::before {
  opacity: 1;
}
.feature-icon-box-wrapper .icon-box-items.active::before {
  opacity: 1;
}

.feature-icon-box-area-2 {
  position: relative;
}
.feature-icon-box-area-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg5);
  top: 45%;
}

.counter-section {
  position: relative;
}
.counter-section .line-shape {
  position: absolute;
  top: -15%;
  left: 0;
}
@media (max-width: 1399px) {
  .counter-section .line-shape {
    top: 0;
  }
}
@media (max-width: 1199px) {
  .counter-section .line-shape {
    display: none;
  }
}
.counter-section .line-shape img {
  width: 100%;
  height: 100%;
}

.counter-wrapper {
  background-color: var(--theme);
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  margin-top: -90px;
}
@media (max-width: 1199px) {
  .counter-wrapper {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: initial;
  }
}
@media (max-width: 767px) {
  .counter-wrapper {
    padding: 40px;
  }
}
@media (max-width: 575px) {
  .counter-wrapper {
    padding: 30px;
  }
}
.counter-wrapper .counter-items {
  display: flex;
  align-items: center;
  gap: 20px;
}
.counter-wrapper .counter-items h2 {
  color: var(--white);
}
.counter-wrapper .counter-items p {
  color: var(--white);
  font-weight: 500;
}

.counter-section-2 {
  position: relative;
}
.counter-section-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #F9F8F8;
  z-index: -1;
  top: 60%;
}

.counter-wrapper-2 {
  padding: 65px 105px;
  background-color: var(--theme2);
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .counter-wrapper-2 {
    flex-wrap: wrap;
    padding: 50px 50px;
    gap: 30px;
  }
}
@media (max-width: 470px) {
  .counter-wrapper-2 {
    justify-content: center;
    text-align: center;
  }
}
.counter-wrapper-2 .counter-items:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1019607843);
  padding-right: 80px;
}
@media (max-width: 1199px) {
  .counter-wrapper-2 .counter-items:not(:last-child) {
    padding-right: 20px;
  }
}
@media (max-width: 991px) {
  .counter-wrapper-2 .counter-items:not(:last-child) {
    border: none;
    padding-right: 0;
  }
}
.counter-wrapper-2 .counter-items .icon {
  font-size: 52px;
  color: var(--white);
}
.counter-wrapper-2 .counter-items .content {
  margin-top: 25px;
}
@media (max-width: 575px) {
  .counter-wrapper-2 .counter-items .content {
    margin-top: 15px;
  }
}
.counter-wrapper-2 .counter-items .content h2 {
  font-size: 50px;
  color: var(--white);
  font-weight: 600;
}
@media (max-width: 575px) {
  .counter-wrapper-2 .counter-items .content h2 {
    font-size: 38px;
  }
}
.counter-wrapper-2 .counter-items .content p {
  color: var(--white);
  margin-top: 10px;
  font-size: 18px;
}
@media (max-width: 575px) {
  .counter-wrapper-2 .counter-items .content p {
    font-size: 16px;
  }
}

.grow-wrapper .title-section-area {
  border-bottom: 1px solid rgba(230, 230, 230, 0.1);
  padding-bottom: 30px;
}
@media (max-width: 1199px) {
  .grow-wrapper .title-section-area {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.grow-wrapper .circle-progress-bar-wrapper {
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .grow-wrapper .circle-progress-bar-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}
@media (max-width: 575px) {
  .grow-wrapper .circle-progress-bar-wrapper {
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar {
  display: flex;
  gap: 30px;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar span {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .content h6 {
  display: block;
  margin-bottom: 10px;
  color: var(--white);
}
@media (max-width: 575px) {
  .grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .content br {
    display: block;
  }
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar {
  position: relative;
  height: 120px;
  width: 120px;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar div {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar div span {
  position: absolute;
  font-size: 22px;
  line-height: 92px;
  height: 90px;
  width: 90px;
  left: 5px;
  top: 5px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--black);
}
@media (max-width: 575px) {
  .grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar div span {
    font-size: 24px;
  }
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar div span:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  border-radius: 50%;
  border: 8px solid var(--header);
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar .background {
  background-color: var(--white) !important;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar .rotate {
  clip: rect(0 50px 100px 0);
  background-color: var(--theme2) !important;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar .left {
  clip: rect(0 50px 100px 0);
  opacity: 1;
  background-color: var(--white) !important;
}
.grow-wrapper .circle-progress-bar-wrapper .single-circle-bar .circle-bar .right {
  clip: rect(0 50px 100px 0);
  transform: rotate(180deg);
  opacity: 0;
  background-color: var(--theme2) !important;
}

.manage-it-wrapper .single-tab-items .nav {
  display: flex;
  align-items: center;
  gap: 80px;
  --bs-nav-link-padding-x: 0;
  border-bottom: 1px solid var(--border2);
  margin-right: 100px;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .manage-it-wrapper .single-tab-items .nav {
    gap: 30px;
  }
}
@media (max-width: 575px) {
  .manage-it-wrapper .single-tab-items .nav {
    gap: 20px;
  }
}
.manage-it-wrapper .single-tab-items .nav .nav-link {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  color: var(--header);
  transition: all 0.3s ease-in-out;
}
.manage-it-wrapper .single-tab-items .nav .nav-link.active {
  position: relative;
  color: var(--theme);
}
.manage-it-wrapper .single-tab-items .nav .nav-link.active::before {
  position: absolute;
  bottom: -21px;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  background: var(--theme);
  transition: 0.3s;
}
.manage-it-wrapper .single-tab-items .tab-content-items .list-area {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 70px;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .manage-it-wrapper .single-tab-items .tab-content-items .list-area {
    margin-top: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.manage-it-wrapper .single-tab-items .tab-content-items .list-area ul li {
  color: var(--header);
  font-weight: 500;
}
.manage-it-wrapper .single-tab-items .tab-content-items .list-area ul li:not(:last-child) {
  margin-bottom: 10px;
}
.manage-it-wrapper .single-tab-items .tab-content-items .list-area ul li i {
  color: var(--theme);
  margin-right: 8px;
}
.manage-it-wrapper .single-tab-items .tab-content-items .theme-btn {
  line-height: 1.2;
}
.manage-it-wrapper .manage-image {
  max-width: 550px;
  height: 645px;
  position: relative;
}
.manage-it-wrapper .manage-image .counting-shape {
  position: absolute;
  bottom: 5%;
  left: -20%;
  animation: top-image-bounce 3s infinite ease-in-out;
}
@media (max-width: 991px) {
  .manage-it-wrapper .manage-image .counting-shape {
    left: 0;
  }
}

@media (max-width: 991px) {
  .choose-package-section {
    background-position: left center !important;
  }
}

.choose-package-wrapper .choose-package-content .nav {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}
@media (max-width: 575px) {
  .choose-package-wrapper .choose-package-content .nav {
    gap: 15px;
  }
}
.choose-package-wrapper .choose-package-content .nav .nav-link {
  text-align: center;
  padding: 10px 40px;
  background-color: var(--white);
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--header);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .choose-package-wrapper .choose-package-content .nav .nav-link {
    padding: 14px 45px;
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .choose-package-wrapper .choose-package-content .nav .nav-link {
    padding: 12px 20px;
    font-size: 14px;
  }
}
.choose-package-wrapper .choose-package-content .nav .nav-link.active {
  position: relative;
  background-color: var(--theme);
  color: var(--white);
}
.choose-package-wrapper .choose-package-content .nav .nav-link.active::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--theme);
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 575px) {
  .choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area {
    gap: 20px;
  }
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-monthly {
  background: var(--white);
  border: 1px solid var(--theme);
  border-radius: 5px;
  padding: 30px;
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-monthly h5 {
  font-size: 60px;
  color: var(--theme);
}
@media (max-width: 575px) {
  .choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-monthly h5 {
    font-size: 36px;
  }
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-monthly h5 sub {
  font-size: 18px;
  font-weight: 400;
  color: var(--text);
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-monthly h5 span {
  font-size: 18px;
  font-weight: 400;
  color: var(--text);
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-installation h5 {
  font-size: 60px;
}
@media (max-width: 575px) {
  .choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-installation h5 {
    font-size: 36px;
  }
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-installation h5 sub {
  font-size: 18px;
  font-weight: 400;
  color: var(--header);
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .choose-tab-price-area .price-installation h5 span {
  font-size: 18px;
  font-weight: 400;
  color: var(--header);
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .list {
  margin-top: 30px;
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .list li:not(:last-child) {
  margin-bottom: 10px;
}
.choose-package-wrapper .choose-package-content .choose-tab-price-items .list li i {
  color: var(--theme);
  margin-right: 10px;
}
.choose-package-wrapper .choose-package-content.style-2 .nav .nav-link.active {
  background-color: var(--theme2);
}
.choose-package-wrapper .choose-package-content.style-2 .nav .nav-link.active::after {
  border-top: 10px solid var(--theme2);
}
.choose-package-wrapper .choose-package-content.style-2 .choose-tab-price-items .choose-tab-price-area .price-monthly {
  border: 1px solid var(--theme2);
}
.choose-package-wrapper .choose-package-content.style-2 .choose-tab-price-items .choose-tab-price-area .price-monthly h5 {
  color: var(--theme2);
}
.choose-package-wrapper .choose-package-content.style-2 .choose-tab-price-items .list li i {
  color: var(--theme2);
}

.feature-video-wrapper .feature-video-content .counter-area {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 40px;
  gap: 20px;
  flex-wrap: wrap;
}
.feature-video-wrapper .feature-video-content .counter-area .counter-items h4 {
  color: var(--theme);
  font-size: 28px;
}
.feature-video-wrapper .feature-video-content .counter-area .counter-items p {
  font-size: 18px;
  font-weight: 600;
  color: var(--header);
}

.feature-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -8px;
}
@media (max-width: 1399px) {
  .feature-icon-wrapper {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
}
.feature-icon-wrapper .icon-items {
  text-align: center;
}
.feature-icon-wrapper .icon-items .icon {
  font-size: 48px;
  color: var(--theme2);
  margin-bottom: 15px;
}
.feature-icon-wrapper .icon-items h4 {
  font-size: 20px;
}

.about-section {
  position: relative;
}
.about-section .left-shape {
  position: absolute;
  top: -34%;
  left: 0;
}
.about-section .left-shape img {
  width: 100%;
}
.about-section .line-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.about-section .line-shape img {
  width: 100%;
  height: 100%;
}

.about-image {
  height: 36vh !important;
  margin-top: 14%;
  margin-bottom: 14%;
}

.about-wrapper .about-image-items {
  position: relative;
  padding-left: 25px;
}
.about-wrapper .about-image-items::before {
  position: absolute;
  top: 100px;
  left: 0;
  content: "";
  height: 210px;
  width: 4px;
  background-color: #E65D0F;
  content: "";
}
.about-wrapper .about-image-items .border-shape {
  position: absolute;
  bottom: -30px;
  right: 22%;
  z-index: 1;
}
@media (max-width: 575px) {
  .about-wrapper .about-image-items .border-shape {
    display: none;
  }
}
.about-wrapper .about-image-items .circle-shape {
  position: absolute;
  bottom: -40px;
  left: -40px;
  z-index: 2;
}
@media (max-width: 991px) {
  .about-wrapper .about-image-items .circle-shape {
    top: 0;
    left: 0;
  }
}
.about-wrapper .about-image-items .circle-shape .text-circle {
  animation: cir36 10s linear infinite;
}
@media (max-width: 767px) {
  .about-wrapper .about-image-items .circle-shape .text-circle {
    display: none;
  }
}
.about-wrapper .about-image-items .circle-shape .award-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  .about-wrapper .about-image-items .circle-shape .award-img {
    top: 0;
    left: 0;
    transform: initial;
  }
}
@media (max-width: 767px) {
  .about-wrapper .about-image-items .circle-shape .award-img {
    width: 155px;
    left: 10px;
  }
  .about-wrapper .about-image-items .circle-shape .award-img img {
    width: 100%;
    height: 100%;
  }
}
.about-wrapper .about-image-items .about-image {
  width: 437px;
  height: 530px;
  position: relative;
  z-index: 1;
}
@media (max-width: 575px) {
  .about-wrapper .about-image-items .about-image {
    max-width: 430px;
    width: initial;
    height: 400px;
  }
}
.about-wrapper .about-image-items .about-image .about-image-2 {
  position: absolute;
  bottom: -100px;
  right: -100px;
}
@media (max-width: 1399px) {
  .about-wrapper .about-image-items .about-image .about-image-2 {
    right: -50px;
  }
}
@media (max-width: 1199px) {
  .about-wrapper .about-image-items .about-image .about-image-2 {
    right: 0;
  }
}
@media (max-width: 991px) {
  .about-wrapper .about-image-items .about-image .about-image-2 {
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .about-wrapper .about-image-items .about-image .about-image-2 {
    bottom: 0;
    right: 0;
    width: 250px;
  }
  .about-wrapper .about-image-items .about-image .about-image-2 img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .about-wrapper .about-image-items .about-image .about-image-2 {
    width: 200px;
  }
}
.about-wrapper .about-content .circle-progress-bar-wrapper {
  display: flex;
  align-items: center;
  gap: 70px;
  margin-top: 50px;
  flex-wrap: wrap;
}
@media (max-width: 1399px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper {
    gap: 30px;
  }
}
@media (max-width: 991px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper {
    margin-top: 40px;
    gap: 70px;
  }
}
@media (max-width: 991px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper {
    gap: 20px;
  }
}
@media (max-width: 575px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper {
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 10px;
  }
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar span {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .content h6 {
  display: block;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .content br {
    display: block;
  }
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar {
  position: relative;
  height: 120px;
  width: 120px;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar div {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar div span {
  position: absolute;
  font-size: 30px;
  line-height: 92px;
  height: 90px;
  width: 90px;
  left: 5px;
  top: 5px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--header);
}
@media (max-width: 575px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar div span {
    font-size: 24px;
  }
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar .background {
  background-color: var(--bg) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar .rotate {
  clip: rect(0 50px 100px 0);
  background-color: var(--theme) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar .left {
  clip: rect(0 50px 100px 0);
  opacity: 1;
  background-color: var(--bg) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper .single-circle-bar .circle-bar .right {
  clip: rect(0 50px 100px 0);
  transform: rotate(180deg);
  opacity: 0;
  background-color: var(--theme) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar span {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .content h6 {
  display: block;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .content br {
    display: block;
  }
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar {
  position: relative;
  height: 120px;
  width: 120px;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar div {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar div span {
  position: absolute;
  font-size: 30px;
  line-height: 92px;
  height: 90px;
  width: 90px;
  left: 5px;
  top: 5px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--header);
}
@media (max-width: 575px) {
  .about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar div span {
    font-size: 24px;
  }
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar .background {
  background-color: var(--bg) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar .rotate {
  background-color: var(--theme2) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar .left {
  clip: rect(0 50px 100px 0);
  opacity: 1;
  background-color: var(--bg) !important;
}
.about-wrapper .about-content .circle-progress-bar-wrapper.style-2 .single-circle-bar .circle-bar .right {
  background-color: var(--theme2) !important;
}
.about-wrapper .about-content .about-list {
  margin-top: 20px;
}
@media (max-width: 991px) {
  .about-wrapper .about-content .about-list {
    margin-top: 0;
  }
}
.about-wrapper .about-content .about-list li {
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 991px) {
  .about-wrapper .about-content .about-list li {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .about-wrapper .about-content .about-list li {
    font-size: 15px;
  }
}
.about-wrapper .about-content .about-list li:not(:last-child) {
  margin-bottom: 10px;
}
.about-wrapper .about-content .about-list li i {
  color: var(--theme);
}
.about-wrapper .about-content .about-author {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 40px;
}
@media (max-width: 1399px) {
  .about-wrapper .about-content .about-author {
    gap: 40px;
  }
}
@media (max-width: 1199px) {
  .about-wrapper .about-content .about-author {
    flex-wrap: wrap;
  }
}
.about-wrapper .about-content .about-author .author-image {
  display: flex;
  align-items: center;
  gap: 10px;
}
.about-wrapper .about-content .about-author .author-image .content p {
  font-size: 14px;
  color: var(--theme);
  font-weight: 500;
  margin-top: -10px;
}
@media (max-width: 575px) {
  .about-wrapper.style-2 .about-image-items {
    padding-left: 0;
  }
}
@media (max-width: 575px) {
  .about-wrapper.style-2 .about-image-items .about-image {
    max-width: 525px;
  }
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area {
  position: absolute;
  top: 50px;
  left: -25%;
  padding: 35px 45px;
  background-color: var(--theme);
  width: 320px;
}
@media (max-width: 1399px) {
  .about-wrapper.style-2 .about-image-items .about-image .experience-text-area {
    top: 0px;
    left: 0;
  }
}
@media (max-width: 991px) {
  .about-wrapper.style-2 .about-image-items .about-image .experience-text-area {
    padding: 30px 20px;
    width: 264px;
  }
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area h2 {
  font-size: 65px;
  color: var(--white);
}
@media (max-width: 575px) {
  .about-wrapper.style-2 .about-image-items .about-image .experience-text-area h2 {
    font-size: 32px;
  }
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area h6 {
  font-size: 16px;
  color: var(--white);
  position: relative;
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area h6::before {
  position: absolute;
  bottom: -5px;
  left: 34%;
  transform: translateX(-50%);
  content: "";
  width: 147px;
  height: 5px;
  /* background-image: url(../../assets/img/about/bar.png); */
  background-repeat: no-repeat;
  background-size: cover;
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area .star {
  margin-top: 15px;
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area .star span {
  font-size: 15px;
  color: var(--white);
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area .ratting {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 15px 20px;
  text-align: center;
  background-color: var(--white);
  z-index: 9;
}
@media (max-width: 575px) {
  .about-wrapper.style-2 .about-image-items .about-image .experience-text-area .ratting {
    padding: 15px;
  }
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area .ratting span {
  font-size: 18px;
  font-weight: 600;
  color: var(--theme);
}
@media (max-width: 575px) {
  .about-wrapper.style-2 .about-image-items .about-image .experience-text-area .ratting span {
    font-size: 15px;
  }
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area .ratting::after {
  position: absolute;
  right: 4px;
  top: 0px;
  font-family: "montserrat";
  content: "\f005";
  font-size: 14px;
  color: var(--theme);
}
.about-wrapper.style-2 .about-image-items .about-image .experience-text-area .ratting::before {
  position: absolute;
  top: 8px;
  left: -8px;
  right: 0px;
  width: 94%;
  height: 100%;
  content: "";
  border: 1px solid var(--white);
  z-index: -1;
}
.about-wrapper.style-2 .about-image-items .about-image .about-image-2 {
  bottom: -140px;
}
@media (max-width: 1399px) {
  .about-wrapper.style-2 .about-image-items .about-image .about-image-2 {
    bottom: 0;
  }
}
@media (max-width: 1199px) {
  .about-wrapper.style-2 .about-content {
    margin-left: 20px;
  }
}
@media (max-width: 991px) {
  .about-wrapper.style-2 .about-content {
    margin-left: 0;
  }
}
@media (max-width: 1199px) {
  .about-wrapper.style-2 .about-content .section-title h2 {
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  .about-wrapper.style-2 .about-content .section-title h2 {
    font-size: 24px;
  }
}
.about-wrapper.style-2 .about-content .icon-area {
  margin-top: 30px;
  gap: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding-bottom: 25px;
  margin-bottom: 25px;
}
@media (max-width: 470px) {
  .about-wrapper.style-2 .about-content .icon-area {
    flex-wrap: wrap;
  }
}
.about-wrapper.style-2 .about-content .icon-area .icon-items .icon {
  font-size: 52px;
  color: var(--theme);
  margin-bottom: 20px;
}
.about-wrapper.style-2 .about-content .icon-area .icon-items .content h5 {
  margin-bottom: 10px;
}
.about-wrapper.style-2 .about-content .about-author .author-image {
  display: flex;
  align-items: center;
  gap: 20px;
}
.about-wrapper.style-2 .about-content .about-author .author-image .content p {
  font-weight: 600;
  color: var(--text);
  margin-top: 10px;
  position: relative;
}
.about-wrapper.style-2 .about-content .about-author .author-image .content p::before {
  position: absolute;
  bottom: -5px;
  left: 34%;
  transform: translateX(-50%);
  content: "";
  width: 124px;
  height: 5px;
  /* background-image: url(../../assets/img/about/bar-2.png); */
  background-repeat: no-repeat;
  background-size: cover;
}

.about-wrapper-2 .about-image-items {
  position: relative;
}
.about-wrapper-2 .about-image-items .circle-shape {
  position: absolute;
  bottom: -80px;
  left: 60px;
  z-index: 2;
  width: 200px;
}
@media (max-width: 991px) {
  .about-wrapper-2 .about-image-items .circle-shape {
    right: 20%;
    top: 0;
    bottom: initial;
    left: initial;
  }
}
@media (max-width: 767px) {
  .about-wrapper-2 .about-image-items .circle-shape {
    right: 0;
    width: 140px;
  }
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-image-items .circle-shape {
    display: none;
  }
}
.about-wrapper-2 .about-image-items .circle-shape img {
  width: 100%;
}
.about-wrapper-2 .about-image-items .circle-shape .text-circle {
  animation: cir36 10s linear infinite;
}
.about-wrapper-2 .about-image-items .circle-shape .about-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.about-wrapper-2 .about-image-items .circle-shape .about-title h2 {
  font-size: 57px;
  color: var(--white);
}
@media (max-width: 991px) {
  .about-wrapper-2 .about-image-items .circle-shape .about-title h2 {
    font-size: 36px;
  }
}
.about-wrapper-2 .about-image-items .circle-shape .about-title p {
  font-size: 14px;
  color: var(--white);
  line-height: 1.5;
}
.about-wrapper-2 .about-image-items .about-image-1 {
  max-width: 387px;
  height: 467px;
  border-radius: 10px;
  position: relative;
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-image-items .about-image-1 {
    max-width: 550px;
  }
}
.about-wrapper-2 .about-image-items .about-image-1 .about-image-2 {
  height: 489px;
  max-width: 376px;
  position: absolute;
  right: -45%;
  bottom: -32%;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .about-wrapper-2 .about-image-items .about-image-1 .about-image-2 {
    right: -30%;
    height: 400px;
  }
}
@media (max-width: 1199px) {
  .about-wrapper-2 .about-image-items .about-image-1 .about-image-2 {
    height: 390px;
    right: -10%;
  }
}
@media (max-width: 991px) {
  .about-wrapper-2 .about-image-items .about-image-1 .about-image-2 {
    bottom: -40px;
    right: -50%;
    height: 400px;
  }
}
@media (max-width: 767px) {
  .about-wrapper-2 .about-image-items .about-image-1 .about-image-2 {
    bottom: 0;
    right: -30%;
    height: 350px;
  }
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-image-items .about-image-1 .about-image-2 {
    height: 280px;
    right: 0;
  }
}
.about-wrapper-2 .about-image-items .about-image-1 .about-image-2 img {
  width: 100%;
  height: 100%;
}
.about-wrapper-2 .about-content .about-tabs-area {
  margin-top: 40px;
}
.about-wrapper-2 .about-content .about-tabs-area .about-list-items {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-content .about-tabs-area .about-list-items {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.about-wrapper-2 .about-content .about-tabs-area .about-list-items .video-image {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
}
.about-wrapper-2 .about-content .about-tabs-area .about-list-items .video-image .video-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-wrapper-2 .about-content .about-tabs-area .about-list-items .video-image .video-box .video-btn {
  background-color: var(--theme2);
  color: var(--white);
  display: inline-block;
  font-size: 16px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}
.about-wrapper-2 .about-content .about-tabs-area .about-list-items ul li:not(:last-child) {
  margin-bottom: 5px;
}
.about-wrapper-2 .about-content .about-tabs-area .about-list-items ul li i {
  color: var(--theme2);
  margin-right: 5px;
}
.about-wrapper-2 .about-content .about-tabs-area .about-author {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 55px;
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-content .about-tabs-area .about-author {
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
}
.about-wrapper-2 .about-content .about-tabs-area .about-author .author-image {
  display: flex;
  align-items: center;
  gap: 10px;
}
.about-wrapper-2 .about-content .about-tabs-area .about-author .author-contact {
  display: flex;
  align-items: center;
  gap: 20px;
}
.about-wrapper-2 .about-content .about-tabs-area .about-author .author-contact .content p {
  text-transform: uppercase;
  font-size: 14px;
}
.about-wrapper-2 .about-content .about-tabs-area .about-author .author-contact .content h6 {
  font-size: 16px;
}
.about-wrapper-2 .about-content .single-tab-items {
  margin-top: 40px;
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-content .single-tab-items {
    margin-top: 30px;
  }
}
.about-wrapper-2 .about-content .single-tab-items .nav {
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid var(--theme2);
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-content .single-tab-items .nav {
    gap: 0;
  }
}
.about-wrapper-2 .about-content .single-tab-items .nav .nav-link {
  text-align: center;
  padding: 10px 40px;
  background-color: transparent;
  border-radius: 4px 4px 0px 0px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--header);
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.05em;
  font-size: 14px;
}
@media (max-width: 991px) {
  .about-wrapper-2 .about-content .single-tab-items .nav .nav-link {
    padding: 14px 45px;
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .about-wrapper-2 .about-content .single-tab-items .nav .nav-link {
    padding: 12px 20px;
    font-size: 14px;
  }
}
.about-wrapper-2 .about-content .single-tab-items .nav .nav-link.active {
  position: relative;
  background-color: var(--theme2);
  color: var(--white);
}

.about-wrapper-3 .about-image {
  margin-right: 40px;
}
.about-wrapper-3 .about-image img {
  width: 100%;
  height: 100%;
}
.about-wrapper-3 .about-content .icon-items {
  display: flex;
  gap: 30px;
  margin-top: 40px;
}
.about-wrapper-3 .about-content .icon-items .icon {
  font-size: 42px;
  color: var(--theme);
}
.about-wrapper-3 .about-content .icon-items .content h4 {
  color: var(--white);
  margin-bottom: 10px;
}
.about-wrapper-3 .about-content .icon-items .content p {
  color: var(--white);
}


.service-section {
  position: relative;
}
.service-section .shape-image {
  position: absolute;
  left: 0;
  top: 0;
}
.service-section .left-shape {
  position: absolute;
  top: 0;
  left: 0;
}
.service-section .right-shape {
  position: absolute;
  bottom: 0;
  right: 0;
}
.service-section .line-shape-2 {
  position: absolute;
  left: 0;
  bottom: 8%;
}
.service-section .bottom-shape {
  position: absolute;
  bottom: 0;
  right: 0;
}

.service-section-2 {
  position: relative;
}
.service-section-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg5);
  z-index: -1;
  top: 68%;
}

.service-box-items {
  position: relative;
  margin-top: 30px;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  padding: 45px 35px;
  z-index: 9;
  overflow: hidden;
}
@media (max-width: 575px) {
  .service-box-items {
    padding: 30px 25px;
    text-align: center;
  }
}
.service-box-items::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--theme);
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: bottom center;
  z-index: -1;
}
.service-box-items::after {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 320px;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(../../assets/img/service/line-shape.png); */
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.service-box-items .icon {
  position: relative;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  font-size: 60px;
  color: var(--theme);
}
.service-box-items .icon::before {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: rgba(8, 106, 216, 0.08);
  content: "";
}
.service-box-items .content {
  margin-top: 30px;
}
@media (max-width: 575px) {
  .service-box-items .content {
    margin-top: 20px;
  }
}
.service-box-items .content h3 {
  margin-bottom: 15px;
  font-size: 21px;
}
@media (max-width: 575px) {
  .service-box-items .content h3 {
    margin-bottom: 10px;
  }
}
.service-box-items .arrow-icon {
  transform: rotate(-50deg);
  display: inline-block;
  color: var(--theme);
  margin-top: 30px;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .service-box-items .arrow-icon {
    margin-top: 20px;
  }
}
.service-box-items:hover::before {
  transform: scale(1, 1);
  transform-origin: top center;
}
.service-box-items:hover::after {
  bottom: 0;
  visibility: visible;
  opacity: 1;
}
.service-box-items:hover .icon {
  color: var(--white);
  transform: scaleX(-1);
}
.service-box-items:hover .content h3 a {
  color: var(--white);
}
.service-box-items:hover .content p {
  color: var(--white);
}
.service-box-items:hover .arrow-icon {
  color: var(--white);
  transform: initial;
}
.service-box-items.active::before {
  transform: scale(1, 1);
  transform-origin: top center;
}
.service-box-items.active::after {
  bottom: 0;
  visibility: visible;
  opacity: 1;
}
.service-box-items.active .icon {
  color: var(--white);
}
.service-box-items.active .content h3 a {
  color: var(--white);
}
.service-box-items.active .content p {
  color: var(--white);
}
.service-box-items.active .arrow-icon {
  color: var(--white);
  transform: initial;
}
.service-box-items.style-2 {
  text-align: center;
  margin: 0 auto;
  padding: 50px 20px;
  width: 100%;
}
.service-box-items.style-2::before {
  background-color: var(--theme2);
}
.service-box-items.style-2::after {
  position: absolute;
  bottom: -50px;
  left: 5px;
  width: 12px;
  height: 12px;
  content: "";
  background-repeat: initial;
  background-size: initial;
  background-image: initial;
  background: rgba(255, 255, 255, 0.23);
}
.service-box-items.style-2 .icon {
  font-size: 70px;
  color: var(--theme2);
}
.service-box-items.style-2 .content h6 {
  font-weight: bold;
}
.service-box-items.style-2:hover::after {
  bottom: 5px;
}
.service-box-items.style-2:hover .icon {
  color: var(--white);
}
.service-box-items.style-2:hover .content h6 a {
  color: var(--white);
}
.service-box-items.style-2.active::after {
  bottom: 5px;
}
.service-box-items.style-2.active .icon {
  color: var(--white);
}
.service-box-items.style-2.active .content h6 a {
  color: var(--white);
}

.service-wrapper-2 .service-box-items-2 {
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  border-radius: 10px;
  padding: 45px 35px;
  border: 2px solid transparent;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 9;
  margin-top: 30px;
}
@media (max-width: 575px) {
  .service-wrapper-2 .service-box-items-2 {
    text-align: center;
    padding: 30px;
  }
}
.service-wrapper-2 .service-box-items-2 .icon {
  font-size: 50px;
  color: var(--theme);
  margin-bottom: 25px;
  transition: all 0.4s ease-in-out;
  display: inline-block;
}
@media (max-width: 575px) {
  .service-wrapper-2 .service-box-items-2 .icon {
    margin-bottom: 20px;
  }
}
.service-wrapper-2 .service-box-items-2 .content h3 {
  margin-bottom: 10px;
}
.service-wrapper-2 .service-box-items-2 .content h3 a:hover {
  color: var(--theme);
}
.service-wrapper-2 .service-box-items-2:hover {
  border: 2px solid var(--theme);
}
.service-wrapper-2 .service-box-items-2:hover .icon {
  transform: scaleX(-1);
}
.service-wrapper-2 .service-box-items-2.active {
  border: 2px solid var(--theme);
}

.service-counter-section {
  position: relative;
}
.service-counter-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--header);
  z-index: -1;
  top: 55%;
}

.service-wrapper-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  margin-top: 30px;
}
@media (max-width: 1399px) {
  .service-wrapper-3 {
    flex-wrap: wrap;
  }
}

.service-text-area .link-btn-2 span {
  color: var(--theme2);
  background-size: 100% 1px;
}

.service-details-wrapper .service-details-items .details-image img {
  width: 100%;
  height: 100%;
}
.service-details-wrapper .service-details-items .details-content {
  margin-top: 30px;
}
@media (max-width: 575px) {
  .service-details-wrapper .service-details-items .details-content {
    margin-top: 20px;
  }
}
.service-details-wrapper .service-details-items .details-content h3 {
  font-size: 28px;
  margin-bottom: 15px;
}
@media (max-width: 575px) {
  .service-details-wrapper .service-details-items .details-content h3 {
    font-size: 22px;
  }
}
.service-details-wrapper .service-details-items .details-content h4 {
  margin-bottom: 15px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .service-details-wrapper .service-details-items .details-content h4 {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .service-details-wrapper .service-details-items .details-content h4 {
    margin-top: 20px;
  }
}
.service-details-wrapper .service-details-items .details-content .service-details-video {
  margin-top: 30px;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .service-details-wrapper .service-details-items .details-content .service-details-video {
    margin-top: 25px;
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .service-details-wrapper .service-details-items .details-content .service-details-video {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.service-details-wrapper .service-details-items .details-content .service-details-video .details-video-content ul {
  margin-top: 20px;
}
.service-details-wrapper .service-details-items .details-content .service-details-video .details-video-content ul li:not(:last-child) {
  margin-bottom: 10px;
}
.service-details-wrapper .service-details-items .details-content .service-details-video .details-video-content ul li i {
  color: var(--theme);
  margin-right: 5px;
}
.service-details-wrapper .service-details-items .details-content .service-details-video .video-image {
  position: relative;
}
.service-details-wrapper .service-details-items .details-content .service-details-video .video-image img {
  width: 100%;
  height: 100%;
}
.service-details-wrapper .service-details-items .details-content .service-details-video .video-image .video-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.service-details-wrapper .service-details-items .details-content .service-details-video .video-image .video-box .video-btn {
  background-color: var(--white);
  width: 80px;
  line-height: 80px;
  height: 80px;
  text-align: center;
  color: var(--theme);
  border-radius: 50%;
  display: inline-block;
}
.service-details-wrapper .service-details-items .details-content .service-details-video .video-image .video-box .ripple::before, .service-details-wrapper .service-details-items .details-content .service-details-video .video-image .video-box .ripple::after {
  box-shadow: 0 0 0 0 rgba(214, 17, 30, 0.5);
}
.service-details-wrapper .service-details-items .details-content .details-image-2 {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .service-details-wrapper .service-details-items .details-content .details-image-2 {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .service-details-wrapper .service-details-items .details-content .details-image-2 {
    margin-top: 20px;
  }
}
.service-details-wrapper .service-details-items .details-content .details-image-2 img {
  width: 100%;
  height: 100%;
}
.service-details-wrapper .service-details-items .faq-content {
  margin-top: 50px;
}
.service-details-wrapper .service-details-items .faq-content .accordion-item {
  border-radius: 0;
}
.service-details-wrapper .service-details-items .faq-content .accordion-item .accordion-header .accordion-button {
  font-weight: 600;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget {
  background-color: var(--bg5);
  padding: 30px;
  margin-bottom: 30px;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category {
  margin-top: 30px;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a {
  position: relative;
  display: inline-block;
  padding: 16px 20px;
  background-color: var(--white);
  width: 100%;
  font-weight: 600;
  z-index: 9;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a:not(:last-child) {
  margin-bottom: 10px;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a i {
  font-size: 18px;
  color: var(--header);
  position: absolute;
  top: 25px;
  right: 20px;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a::before {
  background-color: var(--theme);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a:hover {
  color: var(--white);
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a:hover i {
  color: var(--white);
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a:hover::before {
  visibility: visible;
  opacity: 1;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a.active {
  color: var(--white);
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a.active i {
  color: var(--white);
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .service-category a.active::before {
  visibility: visible;
  opacity: 1;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .message-form {
  margin-top: 30px;
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .message-form .single-form-input input, .service-details-wrapper .main-sidebar .single-sidebar-widget .message-form .single-form-input textarea {
  border: none;
  padding: 16px 20px;
  background-color: var(--white);
  width: 100%;
  text-transform: capitalize;
  color: var(--text);
}
.service-details-wrapper .main-sidebar .single-sidebar-widget .message-form .single-form-input textarea {
  padding: 16px 20px 120px;
}

.network-solution-items {
  margin-top: 30px;
}
.network-solution-items .icon {
  font-size: 68px;
  color: var(--header);
}
.network-solution-items .content {
  margin-top: 15px;
}
.network-solution-items .content h4 {
  margin-bottom: 10px;
}
.network-solution-items .content h4 a:hover {
  color: var(--theme);
}
.network-solution-items .content p {
  margin-bottom: 15px;
}

.network-solution-wrapper {
  background-color: var(--white);
  position: relative;
  z-index: 22;
  padding: 60px 60px;
  padding-top: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--theme);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme);
  border-radius: 10px;
}

.fix {
  overflow: hidden;
}

.ralt {
  position: relative;
}

.ml-100 {
  margin-left: 100px;
}

.ripple {
  position: relative;
}
.ripple::before, .ripple::after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(214, 17, 30, 0.5);
  animation: rippleOne 3s infinite;
}
.ripple::before {
  animation-delay: 0.9s;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}
.ripple::after {
  animation-delay: 0.6s;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}

.mt-10 {
  margin-top: 10px;
}

@media (max-width: 991px) {
  br {
    display: none;
  }
}

/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.nice-select {
  color: var(--text);
  background-color: transparent;
  font-size: 16px;
  width: unset;
  outline: none;
  padding: 18px 30px;
  border: none;
  border: 1px solid #E4E4E4;
  font-weight: 500;
  color: var(--text);
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .nice-select {
    padding: 12px 20px;
  }
}
.nice-select span {
  color: var(--text);
  font-size: 16px;
  margin-bottom: 0 !important;
  font-weight: 500;
}
.nice-select:hover::after {
  border-bottom: 1px solid var(--text);
  border-right: 1px solid var(--text);
}

.nice-select option {
  color: var(--text);
}

.nice-select .current {
  margin-right: 12px;
}

.nice-select:after {
  right: 23px;
  border-bottom: 1px solid var(--text);
  border-right: 1px solid var(--text);
  width: 10px;
  height: 10px;
}

.nice-select.open .list {
  background: var(--bg);
  margin-top: 16px;
  width: 100%;
  text-transform: capitalize;
  color: var(--text);
}

.nice-select .option.selected.focus {
  background: var(--bg);
  outline: none;
  color: var(--theme);
  text-transform: capitalize;
  font-size: 16px;
}

.nice-select .option {
  border: none;
}

.nice-select .option:hover {
  background: transparent;
}

.head-color {
  color: var(--header);
}

.theme-color-1 {
  color: var(--theme) !important;
}

.border-none {
  border-top: none !important;
}

.array-button {
  position: relative;
  z-index: 99;
}
.array-button .array-prev {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--theme2);
  font-size: 18px;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .array-button .array-prev {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
.array-button .array-prev:hover {
  background-color: var(--theme);
  color: var(--white);
}
.array-button .array-next {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background-color: var(--theme);
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .array-button .array-next {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
.array-button .array-next:hover {
  background-color: var(--white);
  color: var(--theme);
}
.array-button.style-2 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.array-button.style-2 .array-prev, .array-button.style-2 .array-next {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #181818;
  border-radius: 0;
  color: var(--white);
  font-size: 14px;
  color: #5E5E5E;
}
.array-button.style-2 .array-prev:hover, .array-button.style-2 .array-next:hover {
  background-color: var(--theme);
  color: var(--white);
}
.array-button.style-2 .array-next {
  background: var(--theme);
  color: var(--white);
}

.custom-container-2 {
  max-width: 1590px;
}

.movie-bg {
  margin-top: -250px;
  padding-top: 250px;
  padding-bottom: 115px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.mg-top-20 {
  margin-top: -20px;
}

.mg-top-8 {
  margin-top: -8px;
}

.swiper-dot {
  text-align: center;
}
.swiper-dot .swiper-pagination-bullet {
  width: 30px;
  height: 8px;
  transition: 0.6s;
  background-color: var(--white);
  opacity: 1;
  border-radius: 60px;
  position: relative;
}
.swiper-dot .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme);
  transition: 0.6s;
  position: relative;
  width: 51px;
}

.single-team-items {
  margin-top: 30px;
}
.single-team-items .team-image {
  position: relative;
}
.single-team-items .team-image img {
  width: 100%;
  height: 100%;
}
.single-team-items .team-image .social-profile {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  transition: all 0.4s ease-in-out;
  z-index: 2;
  display: flex;
  overflow: hidden;
  font-size: 22px;
  align-items: center;
}
.single-team-items .team-image .social-profile ul {
  transform: translateX(-100px);
  transition: all 0.6s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  margin-top: 21px;
}
.single-team-items .team-image .social-profile ul li a {
  padding: 8px 15px;
  text-align: center;
  font-size: 14px;
  display: block;
  background: var(--white);
  color: var(--header);
  transition: all 0.4s ease-in-out;
  text-align: center;
  margin: 0 auto;
  color: var(--text);
}
.single-team-items .team-image .social-profile ul li a:hover {
  color: var(--theme);
}
.single-team-items .team-image .social-profile .plus-btn {
  z-index: 2;
  cursor: pointer;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  background: var(--theme);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.single-team-items .team-image .social-profile:hover ul {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.single-team-items .team-image .social-profile.active a {
  opacity: 1;
  visibility: visible;
}
.single-team-items .team-content {
  margin-top: 25px;
}
.single-team-items .team-content p {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: var(--theme);
  margin-top: 5px;
}
.single-team-items .team-join-content {
  position: relative;
}
.single-team-items .team-join-content .circle-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.single-team-items .team-join-content .dot-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.single-team-items .team-join-content h2 {
  background-color: var(--bg3);
  padding: 20px;
  border-radius: 18px;
  margin-bottom: 20px;
}
.single-team-items .team-join-content h2 span {
  color: var(--theme);
}
.single-team-items .team-join-content h3 {
  font-weight: 600;
  color: var(--theme);
}
.single-team-items.style-2 .team-content h5 a {
  color: var(--white);
}
.single-team-items.style-2 .team-content h5 a:hover {
  color: var(--theme);
}

.team-card-items {
  margin-top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1019607843);
}
.team-card-items .team-content {
  padding: 40px;
}
.team-card-items .team-content h4 {
  margin-bottom: 5px;
}
.team-card-items .team-content h4 a {
  color: var(--white);
}
.team-card-items .team-content h4 a:hover {
  color: var(--theme2);
}
.team-card-items .team-image {
  position: relative;
  padding: 0 20px 20px 20px;
}
.team-card-items .team-image img {
  width: 100%;
  height: 100%;
}
.team-card-items .team-image .social-profile {
  position: absolute;
  left: 20px;
  bottom: 20px;
  content: "";
  transition: all 0.4s ease-in-out;
  z-index: 2;
  display: flex;
  overflow: hidden;
  font-size: 22px;
  align-items: center;
}
.team-card-items .team-image .social-profile ul {
  transform: translateX(-100px);
  transition: all 0.6s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  margin-top: 21px;
}
.team-card-items .team-image .social-profile ul li a {
  padding: 8px 15px;
  text-align: center;
  font-size: 14px;
  display: block;
  background: var(--white);
  color: var(--header);
  transition: all 0.4s ease-in-out;
  text-align: center;
  margin: 0 auto;
  color: var(--text);
}
.team-card-items .team-image .social-profile ul li a:hover {
  color: var(--theme2);
}
.team-card-items .team-image .social-profile .plus-btn {
  z-index: 2;
  cursor: pointer;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  background: var(--theme2);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.team-card-items .team-image .social-profile:hover ul {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.team-wrapper {
  margin-bottom: -10px;
}

.team-details-wrapper .team-image {
  height: 525px;
}
@media (max-width: 575px) {
  .team-details-wrapper .team-image {
    height: 400px;
  }
  .team-details-wrapper .team-image img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.team-details-wrapper .team-details-content .star a {
  font-size: 14px;
  color: var(--header);
}
.team-details-wrapper .team-details-content .star a i {
  color: var(--ratting);
}
.team-details-wrapper .team-details-content h3 {
  margin-bottom: 5px;
  font-size: 44px;
}
@media (max-width: 767px) {
  .team-details-wrapper .team-details-content h3 {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .team-details-wrapper .team-details-content h3 {
    font-size: 24px;
  }
}
.team-details-wrapper .team-details-content span {
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}
.team-details-wrapper .team-details-content .social-icon {
  margin-top: 40px;
  gap: 15px;
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .team-details-wrapper .team-details-content .social-icon {
    margin-top: 20px;
  }
}
.team-details-wrapper .team-details-content .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: var(--bg);
  color: var(--text);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  text-align: center;
}
.team-details-wrapper .team-details-content .social-icon a:hover {
  background-color: var(--theme);
  color: var(--white);
}

.team-skill {
  background-color: var(--theme);
}

.team-skill-wrapper .team-skill-content h3 {
  color: var(--white);
  font-size: 40px;
  font-weight: 600;
  line-height: 125%;
}
@media (max-width: 1199px) {
  .team-skill-wrapper .team-skill-content h3 {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .team-skill-wrapper .team-skill-content h3 {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .team-skill-wrapper .team-skill-content h3 {
    font-size: 24px;
  }
}
.team-skill-wrapper .progress-wrap .pro-items:not(:last-child) {
  margin-bottom: 20px;
}
.team-skill-wrapper .progress-wrap .pro-items .pro-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.team-skill-wrapper .progress-wrap .pro-items .pro-head .point {
  font-size: 18px;
  font-weight: 700;
  color: var(--white);
}
.team-skill-wrapper .progress-wrap .pro-items .pro-head .title {
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}
.team-skill-wrapper .progress-wrap .pro-items .progress {
  background: var(--white);
  justify-content: flex-start;
  border-radius: 0;
  align-items: center;
  position: relative;
  display: flex;
  height: 6px;
  width: 100%;
}
.team-skill-wrapper .progress-wrap .pro-items .progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px var(--white);
  border-radius: 0;
  background: var(--white);
  height: 6px;
  width: 0;
}
.team-skill-wrapper .progress-wrap .pro-items .style-two {
  animation: load2 3s normal forwards;
}
@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 65%;
  }
}
@keyframes load2 {
  0% {
    width: 0;
  }
  100% {
    width: 75%;
  }
}

.team-contact-wrapper .get-touch-items .get-touch-title h2 {
  margin-bottom: 10px;
}
.team-contact-wrapper .get-touch-items .contact-items {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 100px;
}
@media (max-width: 575px) {
  .team-contact-wrapper .get-touch-items .contact-items {
    margin-top: 20px;
    gap: 30px;
    flex-wrap: wrap;
  }
}
.team-contact-wrapper .get-touch-items .contact-items .contact-info h4 {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 22px;
}
.team-contact-wrapper .get-touch-items .contact-items .contact-info h5 {
  text-transform: capitalize;
  font-size: 18px;
}
.team-contact-wrapper .get-touch-items .contact-items .contact-info .social-icon {
  gap: 15px;
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .team-contact-wrapper .get-touch-items .contact-items .contact-info .social-icon {
    margin-top: 20px;
  }
}
.team-contact-wrapper .get-touch-items .contact-items .contact-info .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: var(--bg);
  color: var(--text);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  text-align: center;
}
.team-contact-wrapper .get-touch-items .contact-items .contact-info .social-icon a:hover {
  background-color: var(--theme);
  color: var(--white);
}

.team-button {
  text-align: center;
  margin-top: 30px;
}

.team-section {
  position: relative;
  z-index: 9;
}
.team-section .dot-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.actor-items {
  margin-top: 30px;
  text-align: center;
}
.actor-items .active-img {
  width: 190px;
  height: 190px;
}
.actor-items .active-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.actor-items .title {
  margin-top: 20px;
}
.actor-items .title a {
  color: var(--white);
}
.actor-items .title a:hover {
  color: var(--theme);
}

.product-card-items {
  margin-top: 30px;
}
.product-card-items .product-image {
  border: 1px solid #dfe1e7;
  position: relative;
}
.product-card-items .product-image img {
  width: 100%;
  height: 100%;
}
.product-card-items .product-image .product-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  line-height: 0;
}
.product-card-items .product-image .product-badge .product-badge-item {
  font-size: 12px;
  display: inline-block;
  padding: 4px 9px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  background-color: var(--theme);
}
.product-card-items .product-image .product-icon {
  gap: 16px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  right: 5%;
}
.product-card-items .product-image .product-icon li {
  transform: scaleX(0);
  transition: 0.4s ease-in-out;
  cursor: pointer;
  background-color: var(--theme);
  padding: 5px 14px;
}
.product-card-items .product-image .product-icon li:hover {
  background-color: var(--header);
}
.product-card-items .product-image .product-icon li:hover i {
  color: var(--white);
}
.product-card-items .product-image .product-icon li a i {
  color: var(--white);
  font-size: 16px;
  transition: all 0.4s ease-in-out;
}
.product-card-items .product-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.product-card-items .product-content h6 a:hover {
  color: var(--theme);
}
.product-card-items .product-content .ratting span {
  color: var(--theme);
  font-weight: 500;
}
.product-card-items .product-content .ratting .star {
  color: var(--ratting);
  font-size: 14px;
}
.product-card-items:hover .product-image .product-icon li {
  transform: scaleX(1);
}

.product-box-items {
  margin-top: 30px;
}
.product-box-items .product-image {
  width: 100%;
  height: 280px;
  background-color: #F5F5F5;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.product-box-items .product-image .product-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  line-height: 0;
}
.product-box-items .product-image .product-badge .product-badge-item {
  font-size: 12px;
  display: inline-block;
  padding: 4px 9px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  background-color: var(--theme);
}
.product-box-items .product-image .product-icon {
  gap: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
}
.product-box-items .product-image .product-icon li {
  transform: scaleX(0);
  transition: 0.4s ease-in-out;
  cursor: pointer;
  background-color: var(--theme);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  text-align: center;
}
.product-box-items .product-image .product-icon li:hover {
  background-color: var(--header);
}
.product-box-items .product-image .product-icon li:hover i {
  color: var(--white);
}
.product-box-items .product-image .product-icon li a i {
  color: var(--white);
  font-size: 16px;
  transition: all 0.4s ease-in-out;
}
.product-box-items .product-content {
  margin-top: 20px;
}
.product-box-items .product-content h5 a:hover {
  color: var(--theme);
}
.product-box-items .product-content .price {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}
.product-box-items .product-content .price del {
  color: var(--header);
  font-size: 14px;
  font-weight: 500;
}
.product-box-items .product-content .price span {
  color: var(--theme);
  font-size: 14px;
  font-weight: 500;
}
.product-box-items:hover .product-image .product-icon li {
  transform: scaleX(1);
}

.main-cart-wrapper {
  border-radius: 5px;
}
.main-cart-wrapper .cart-wrapper {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 40px 40px;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table {
  width: 100%;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table thead {
  border-bottom: 1px solid var(--border);
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table thead tr th {
  padding-bottom: 16px;
  color: var(--header);
  text-transform: capitalize;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item td {
  border-bottom: 1px solid var(--border);
  padding: 16px 0;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-info {
  display: flex;
  align-items: center;
  gap: 16px;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-price {
  color: var(--header);
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--bg);
  width: 100px;
  border-radius: 5px;
  padding: 2px 20px;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-quantity span {
  display: block;
  color: var(--header);
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-quantity .cart-item-quantity-controller a {
  display: block;
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-quantity .cart-item-quantity-controller a i {
  color: var(--header);
}
.main-cart-wrapper .cart-wrapper .cart-items-wrapper table tbody .cart-item .cart-item-remove a i {
  color: var(--header);
}
.main-cart-wrapper .cart-wrapper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.main-cart-wrapper .cart-wrapper-footer form {
  border: 1px solid var(--border);
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-cart-wrapper .cart-wrapper-footer form input {
  padding: 5px 5px;
  border: none;
  text-transform: capitalize;
  font-size: 16px;
  outline: none;
  background: transparent;
  color: var(--text);
}
.main-cart-wrapper .cart-wrapper-footer form button {
  outline: none;
  border: none;
}
.main-cart-wrapper .cart-pragh-box {
  margin-top: 24px;
  padding-right: 30px;
}
.main-cart-wrapper .cart-graph {
  border: 1px solid var(--border);
  padding: 30px 30px;
  border-radius: 5px;
}
.main-cart-wrapper .cart-graph h4 {
  text-align: center;
  color: var(--header);
  margin-bottom: 30px;
}
.main-cart-wrapper .cart-graph ul {
  margin-bottom: 30px;
}
.main-cart-wrapper .cart-graph ul li {
  display: flex;
}
.main-cart-wrapper .cart-graph ul li:not(:last-child) {
  border-bottom: 1px solid var(--border);
}
.main-cart-wrapper .cart-graph ul li span {
  width: 50%;
  color: var(--header);
  font-size: 18px;
  text-transform: capitalize;
  padding: 16px 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  .cart-wrapper {
    overflow-x: scroll;
  }
  .cart-wrapper .cart-items-wrapper {
    width: 700px;
  }
  .cart-wrapper .cart-wrapper-footer {
    width: 700px;
  }
}
.checkout-radio {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 24px;
}
.checkout-radio .primary-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 16px;
  color: var(--header);
  text-transform: capitalize;
}
.checkout-radio h4 {
  color: var(--header);
  margin-bottom: 16px;
  font-weight: 600;
}
.checkout-radio .checkout-radio-wrapper .checkout-radio-single .form-check-input {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  box-shadow: none;
  outline: none;
  border: 1px solid var(--border);
  font-weight: 500;
}
.checkout-radio .checkout-radio-wrapper .checkout-radio-single label {
  color: var(--header);
  text-transform: capitalize;
}
.checkout-radio .checkout-radio-wrapper .checkout-radio-single:not(:last-child) {
  margin-bottom: 12px;
}
@media (max-width: 500px) {
  .checkout-radio {
    padding: 10px;
  }
}

.checkout-single-wrapper .checkout-single h4 {
  color: var(--header);
  margin-bottom: 2rem;
  font-weight: 600;
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single textarea,
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single .country-select,
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single input {
  width: 100%;
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 12px 24px;
  color: var(--header);
  text-transform: capitalize;
  font-weight: 500;
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single label {
  color: var(--header);
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 500;
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single ::-moz-placeholder {
  color: var(--header);
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single ::placeholder {
  color: var(--header);
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single .nice-select {
  background-color: var(--white);
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single .nice-select span {
  font-size: 18px;
  color: var(--header);
  font-weight: 500;
}
.checkout-single-wrapper .checkout-single .checkout-single-form .input-single .nice-select::after {
  border-right: 1px solid var(--header);
  border-bottom: 1px solid var(--header);
}
.checkout-single-wrapper .checkout-single .checkout-single-form .payment {
  color: var(--header);
  margin-bottom: 12px;
  text-transform: capitalize;
}
.checkout-single-wrapper .boxshado-single {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 24px;
}
@media (max-width: 575px) {
  .checkout-single-wrapper .boxshado-single {
    padding: 14px;
  }
}
.checkout-single-wrapper .checkout-single-bg {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 32px;
}
.checkout-single-wrapper .checkout-single-bg .checkout-single-form .input-single textarea,
.checkout-single-wrapper .checkout-single-bg .checkout-single-form .input-single .country-select,
.checkout-single-wrapper .checkout-single-bg .checkout-single-form .input-single input {
  border: 1px solid var(--border);
  background: transparent;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .checkout-single-wrapper .checkout-single-bg {
    padding: 14px;
  }
}
.checkout-single-wrapper .checkout-single-bg .payment-save {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.checkout-single-wrapper .checkout-single-bg .payment-save input {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--border);
  background-color: var(--theme);
  outline: none;
  color: var(--header);
}
.checkout-single-wrapper .checkout-single-bg .payment-save label {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.shop-details-wrapper .shop-details-image {
  position: relative;
}
.shop-details-wrapper .shop-details-image .nav {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
.shop-details-wrapper .shop-details-image .nav img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.shop-details-wrapper .shop-details-image .nav .nav-link {
  padding: 0;
  max-width: 115px;
}
.shop-details-wrapper .shop-details-image .shop-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.shop-details-wrapper .product-details-content {
  margin-left: 60px;
}
@media (max-width: 1399px) {
  .shop-details-wrapper .product-details-content {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .shop-details-wrapper .product-details-content {
    margin-left: 0;
  }
}
.shop-details-wrapper .product-details-content .star a {
  color: var(--theme);
  font-size: 16px;
  font-weight: 600;
}
.shop-details-wrapper .product-details-content .star span {
  margin-left: 10px;
}
.shop-details-wrapper .product-details-content .price-list {
  gap: 20px;
  border-bottom: 1px solid var(--border);
  padding-bottom: 30px;
}
.shop-details-wrapper .product-details-content .price-list h3 {
  font-size: 40px;
}
.shop-details-wrapper .product-details-content .cart-wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.shop-details-wrapper .product-details-content .cart-wrp .cart-quantity {
  padding-right: 15px;
}
.shop-details-wrapper .product-details-content .cart-wrp .cart-quantity input {
  width: 50px;
  height: 40px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--header);
}
.shop-details-wrapper .product-details-content .cart-wrp .cart-quantity .minus {
  color: var(--header);
}
.shop-details-wrapper .product-details-content .cart-wrp .cart-quantity .plus {
  color: var(--header);
}
.shop-details-wrapper .product-details-content .cart-wrp .cart-quantity .qty {
  color: var(--header);
}
.shop-details-wrapper .product-details-content .cart-wrp .icon {
  width: 50px;
  height: 40px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--header);
  line-height: 40px;
}
.shop-details-wrapper .product-details-content .cart-wrp .theme-btn {
  padding: 20px 35px;
}
.shop-details-wrapper .product-details-content .details-info {
  position: relative;
}
.shop-details-wrapper .product-details-content .details-info:not(:last-child) {
  margin-bottom: 10px;
}
.shop-details-wrapper .product-details-content .details-info span {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  position: absolute;
  left: 0;
  top: 2px;
}
.shop-details-wrapper .product-details-content .details-info a {
  font-size: 16px;
  font-weight: 400;
  color: var(--text);
  margin-left: 150px;
  text-transform: capitalize;
}
.shop-details-wrapper .single-tab {
  padding-top: 80px;
}
@media (max-width: 575px) {
  .shop-details-wrapper .single-tab {
    padding-top: 50px;
  }
}
.shop-details-wrapper .single-tab .nav {
  justify-content: center;
  border-bottom: 1px solid var(--border);
  padding-bottom: 20px;
}
.shop-details-wrapper .single-tab .nav .nav-link.active {
  position: relative;
}
.shop-details-wrapper .single-tab .nav .nav-link.active h6 {
  color: var(--theme);
}
.shop-details-wrapper .single-tab .nav .nav-link.active::before {
  position: absolute;
  bottom: -21px;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  background: var(--theme);
  transition: 0.3s;
}
@media (max-width: 470px) {
  .shop-details-wrapper .single-tab .nav .nav-link.active::before {
    display: none;
  }
}
.shop-details-wrapper .single-tab .nav .nav-link h6 {
  font-size: 18px;
}
.shop-details-wrapper .single-tab .description-items .description-content {
  margin-right: 50px;
}
@media (max-width: 1399px) {
  .shop-details-wrapper .single-tab .description-items .description-content {
    margin-right: 30px;
  }
}
@media (max-width: 991px) {
  .shop-details-wrapper .single-tab .description-items .description-content {
    margin-right: 0;
  }
}
.shop-details-wrapper .single-tab .description-items .description-content h3 {
  margin-bottom: 15px;
}
.shop-details-wrapper .single-tab .description-items .description-content .description-list-items {
  margin-top: 30px;
}
@media (max-width: 575px) {
  .shop-details-wrapper .single-tab .description-items .description-content .description-list-items {
    flex-wrap: wrap;
    gap: 30px;
  }
}
.shop-details-wrapper .single-tab .description-items .description-content .description-list-items .description-list li {
  font-size: 16px;
  text-transform: capitalize;
  color: var(--header);
  font-weight: 600;
}
.shop-details-wrapper .single-tab .description-items .description-content .description-list-items .description-list li span {
  color: var(--text);
  font-weight: 400;
}
@media (max-width: 767px) {
  .shop-details-wrapper .single-tab .review-items .admin-items {
    justify-content: center;
    gap: 30px;
  }
}
.shop-details-wrapper .single-tab .review-items .admin-items .admin-img {
  width: 100px;
  height: 100px;
}
.shop-details-wrapper .single-tab .review-items .admin-items .admin-img img {
  border-radius: 50%;
}
.shop-details-wrapper .single-tab .review-items .admin-items .content {
  position: relative;
  border: 1px solid var(--border);
}
.shop-details-wrapper .single-tab .review-items .admin-items .content::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  background-color: var(--white);
  border-left: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  transform: rotate(45deg);
  top: 40%;
  left: -16px;
}
@media (max-width: 575px) {
  .shop-details-wrapper .single-tab .review-items .admin-items .content::before {
    display: none;
  }
}
.shop-details-wrapper .single-tab .review-items .admin-items .content .head-content h5 span {
  font-size: 14px;
  margin-left: 30px;
}
.shop-details-wrapper .single-tab .review-items .admin-items .content .star i {
  font-size: 16px;
  color: var(--theme);
}
.shop-details-wrapper .single-tab .review-title .rate-now {
  margin-top: 15px;
  gap: 15px;
}
.shop-details-wrapper .single-tab .review-title .rate-now p {
  font-size: 16px;
  text-transform: capitalize;
}
.shop-details-wrapper .single-tab .review-title .rate-now i {
  font-size: 16px;
  color: var(--theme);
}
.shop-details-wrapper .single-tab .review-form {
  margin-top: 40px;
}
.shop-details-wrapper .single-tab .review-form .form-clt input {
  width: 100%;
  outline: none;
  border: none;
  padding: 18px 35px;
  color: var(--text);
  background-color: rgb(245, 245, 245);
  font-size: 16px;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .shop-details-wrapper .single-tab .review-form .form-clt input {
    padding: 15px 20px;
  }
}
.shop-details-wrapper .single-tab .review-form .form-clt-big textarea {
  padding: 18px 35px 170px;
  width: 100%;
  outline: none;
  color: var(--text);
  background-color: rgb(245, 245, 245);
  font-size: 14px;
  border: none;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .shop-details-wrapper .single-tab .review-form .form-clt-big textarea {
    padding: 15px 20px;
  }
}

.faq-content {
  position: relative;
  z-index: 9;
}
.faq-content .accordion-item {
  border: 0;
  margin-top: 20px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid var(--border2);
  border-radius: 9px;
  padding: 16px 30px;
}
@media (max-width: 575px) {
  .faq-content .accordion-item {
    margin-top: 10px;
  }
}
.faq-content .accordion-item .accordion-header .accordion-button {
  font-weight: bold;
  color: var(--header);
  letter-spacing: -0.2px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 10px 0;
  font-size: 20px;
}
.faq-content .accordion-item .accordion-header .accordion-button::after {
  content: "\f067";
  background: transparent;
  font-family: "montserrat";
  font-weight: 300;
  transition: all 0.3s ease-in-out !important;
  color: var(--header);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  background-color: var(--theme);
  text-align: center;
  color: var(--white);
}
.faq-content .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-family: "montserrat";
  font-weight: 300;
}
.faq-content .accordion-item .accordion-collapse .accordion-body {
  padding-right: 30px;
  padding-left: 0;
  padding-top: 5px;
  color: var(--text);
}
@media (max-width: 1199px) {
  .faq-content .accordion-item .accordion-collapse .accordion-body {
    padding-right: 0;
  }
}
.faq-content.style-2 .accordion-item {
  background-color: var(--white);
  box-shadow: 0px 14px 50px #E4E9F1;
  border: 1px solid var(--border);
  border-radius: 4px;
}
.faq-content.style-2 .accordion-item .accordion-header .accordion-button::after {
  content: "\f078";
  width: 37px;
  height: 37px;
  background-color: var(--theme2);
  text-align: center;
  line-height: 37px;
  color: var(--white);
  border-radius: 4px;
}
.faq-content.style-2 .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\f077";
  background: #00205B;
}
.faq-content.style-2 .accordion-item .accordion-collapse .accordion-body {
  color: #5A627D;
}
.faq-content.style-color .accordion-item .accordion-header .accordion-button::after {
  background-color: var(--theme);
}
.faq-content.style-color .accordion-item .accordion-collapse .accordion-body {
  color: #5A627D;
}

.faq-section-2 {
  background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%);
}

.faq-wrapper .single-tab-items .nav {
  display: flex;
  align-items: center;
  gap: 15px;
}
.faq-wrapper .single-tab-items .nav .nav-link {
  text-align: center;
  padding: 8px 25px;
  background-color: #EDF2F6;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--header);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .faq-wrapper .single-tab-items .nav .nav-link {
    padding: 14px 45px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .faq-wrapper .single-tab-items .nav .nav-link {
    padding: 14px 30px;
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .faq-wrapper .single-tab-items .nav .nav-link {
    padding: 12px 20px;
    font-size: 14px;
  }
}
.faq-wrapper .single-tab-items .nav .nav-link.active {
  position: relative;
  background-color: var(--theme);
  color: var(--white);
}
.faq-wrapper .faq-content .accordion-item {
  border-radius: 0;
  border-bottom: 1px solid var(--border2) !important;
  border: 0;
  padding: 20px 0;
  margin-top: 0;
}
.faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button {
  font-weight: 600;
}

.faq-section {
  position: relative;
  background-color: #f5f5f8;
}
.faq-section .faq-image {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}
.faq-section .faq-image img {
  max-width: 100%;
}
@media (max-width: 1600px) {
  .faq-section .faq-image {
    right: -7%;
  }
}
@media (max-width: 1199px) {
  .faq-section .faq-image {
    right: -14%;
  }
}
@media (max-width: 1199px) {
  .faq-section .faq-image {
    right: -24%;
  }
}
@media (max-width: 991px) {
  .faq-section .faq-image {
    position: static;
    margin-bottom: 50px;
  }
}
.faq-section .faq-shape {
  position: absolute;
  top: 0;
  right: 100px;
  animation: moving 7s linear infinite;
}
@media (max-width: 1399px) {
  .faq-section .faq-shape {
    right: -7%;
  }
}
@media (max-width: 1199px) {
  .faq-section .faq-shape {
    display: none;
  }
}

.movie-section {
  position: relative;
  z-index: 9;
}
.movie-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgb(3, 0, 17);
  z-index: -1;
}

.movie-card-items {
  margin-top: 30px;
}
.movie-card-items .movie-image {
  position: relative;
  z-index: 9;
}
.movie-card-items .movie-image img {
  width: 100%;
  height: 100%;
}
.movie-card-items .movie-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: 0.5s all ease;
  background-color: rgba(16, 16, 17, 0.4);
}
.movie-card-items .movie-image .movie-play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: scale(0) translate(-50%, -50%);
  transition: all 300ms ease;
  transition-delay: 0s;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}
.movie-card-items .movie-image .movie-play .video-btn {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  color: var(--white);
  display: inline-block;
  border-radius: 50%;
  background-color: var(--theme);
}
.movie-card-items .movie-content {
  margin-top: 20px;
}
.movie-card-items .movie-content h3 {
  margin-bottom: 10px;
}
.movie-card-items .movie-content h3 a {
  color: var(--white);
}
.movie-card-items .movie-content h3 a:hover {
  color: var(--theme);
}
.movie-card-items .movie-content .movie-review {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie-card-items .movie-content .movie-review span {
  font-size: 18px;
  color: var(--white);
}
.movie-card-items .movie-content .movie-review ul {
  display: flex;
  align-items: center;
  gap: 15px;
}
.movie-card-items .movie-content .movie-review ul li {
  color: var(--white);
}
.movie-card-items .movie-content .movie-review ul li i {
  font-size: 14px;
  margin-right: 5px;
  color: var(--theme);
}
.movie-card-items:hover .movie-image::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.movie-card-items:hover .movie-image .movie-play {
  transform: scale(1) translate(-50%, -50%);
  transition-delay: 300ms;
  opacity: 1;
  visibility: visible;
}

.streaming-card-items {
  margin-top: 30px;
}
.streaming-card-items .streaming-image {
  height: 450px;
  position: relative;
}
.streaming-card-items .streaming-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: 0.5s all ease;
  background-color: rgba(16, 16, 17, 0.4);
}
.streaming-card-items .streaming-image .movie-play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: scale(0) translate(-50%, -50%);
  transition: all 300ms ease;
  transition-delay: 0s;
  transition-delay: 0s;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}
.streaming-card-items .streaming-image .movie-play .video-btn {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  color: var(--white);
  display: inline-block;
  border-radius: 50%;
  background-color: var(--theme);
}
.streaming-card-items .streaming-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.streaming-card-items .streaming-content {
  background-color: var(--white);
  position: relative;
  z-index: 9;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: -60px 25px 0;
}
.streaming-card-items .streaming-content h3 {
  margin-bottom: 10px;
}
.streaming-card-items .streaming-content h3 a:hover {
  color: var(--theme);
}
.streaming-card-items .streaming-content .video-years {
  display: inline-block;
  font-weight: 500;
  color: var(--theme);
}
.streaming-card-items .streaming-content .video-time {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
  color: var(--theme);
}
.streaming-card-items .streaming-content ul {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}
.streaming-card-items .streaming-content ul li {
  color: var(--text);
}
.streaming-card-items .streaming-content ul li i {
  font-size: 14px;
  margin-right: 5px;
  color: var(--theme);
}
.streaming-card-items:hover .streaming-image::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.streaming-card-items:hover .streaming-image .movie-play {
  transform: scale(1) translate(-50%, -50%);
  transition-delay: 300ms;
  opacity: 1;
  visibility: visible;
}

.shows-card-items {
  margin-top: 30px;
}
.shows-card-items .shows-image {
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .shows-card-items .shows-image {
    height: 350px;
  }
}
.shows-card-items .shows-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.shows-card-items .shows-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: 0.5s all ease;
  background-color: rgba(16, 16, 17, 0.4);
}
.shows-card-items .shows-image .movie-play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: scale(0) translate(-50%, -50%);
  transition: all 300ms ease;
  transition-delay: 0s;
  transition-delay: 0s;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}
.shows-card-items .shows-image .movie-play .video-btn {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  color: var(--white);
  display: inline-block;
  border-radius: 50%;
  background-color: var(--theme);
}
.shows-card-items .shows-content {
  margin-top: 20px;
}
.shows-card-items .shows-content .show-title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.shows-card-items .shows-content .show-title h4 a {
  color: var(--white);
}
.shows-card-items .shows-content .show-title h4 a:hover {
  color: var(--theme);
}
.shows-card-items .shows-content .show-title span {
  background-color: var(--theme);
  color: var(--white);
  display: inline-block;
  padding: 6px 10px;
  line-height: 1;
}
.shows-card-items .shows-content ul {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}
.shows-card-items .shows-content ul li {
  color: var(--white);
}
.shows-card-items .shows-content p {
  color: var(--white);
  margin-top: 5px;
}
.shows-card-items:hover .shows-image::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.shows-card-items:hover .shows-image .movie-play {
  transform: scale(1) translate(-50%, -50%);
  transition-delay: 300ms;
  opacity: 1;
  visibility: visible;
}

.shows-box-items {
  margin-top: 30px;
}
.shows-box-items .shows-image {
  position: relative;
  z-index: 9;
}
.shows-box-items .shows-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--black);
  opacity: 0.5;
}
.shows-box-items .shows-image img {
  width: 100%;
  height: 100%;
}
.shows-box-items .shows-image .video-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--white);
  z-index: 9;
}
.shows-box-items .shows-content {
  margin-top: 20px;
}
.shows-box-items .shows-content h5 {
  font-size: 18px;
}
.shows-box-items .shows-content h5 a {
  color: var(--white);
}
.shows-box-items .shows-content h5 a:hover {
  color: var(--theme);
}
.shows-box-items .shows-content h6 {
  font-size: 14px;
  margin-top: 10px;
}
.shows-box-items .shows-content h6 a {
  color: var(--white);
  text-decoration: underline;
}
.shows-box-items .shows-content h6 a:hover {
  color: var(--theme);
}
.shows-box-items .shows-content ul {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 8px;
}
.shows-box-items .shows-content ul li {
  color: var(--white);
}

.shows-details-wrapper .shows-details-top-items .details-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.shows-details-wrapper .shows-details-top-items .content .meta-data {
  margin-top: 15px;
  margin-bottom: 15px;
}
.shows-details-wrapper .shows-details-top-items .content .meta-data .time-year {
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
  color: var(--header);
  padding-right: 18px;
}
.shows-details-wrapper .shows-details-top-items .content .meta-data .shows-cat {
  background: var(--theme);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 0 15px;
  display: inline-block;
  border-radius: 5px;
}
.shows-details-wrapper .shows-details-top-items .content .meta-data .shows-cat a {
  color: var(--white);
}
.shows-details-wrapper .shows-details-top-items .content .show-meta {
  margin-top: 20px;
}
.shows-details-wrapper .shows-details-top-items .content .show-meta ul li {
  color: var(--header);
  font-weight: 400;
}
.shows-details-wrapper .shows-details-top-items .content .show-meta ul li:not(:last-child) {
  margin-bottom: 5px;
}
.shows-details-wrapper .shows-details-top-items .content .show-meta ul li span {
  color: var(--theme);
  margin-left: 5px;
  font-weight: 500;
}
.shows-details-wrapper .shows-details-top-items .content .show-meta ul li i {
  color: var(--ratting);
}
.shows-details-wrapper .shows-details-top-items .button-items {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .shows-details-wrapper .shows-details-top-items .button-items {
    margin-top: 25px;
    margin-bottom: 25px;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.shows-details-wrapper .shows-details-top-items .button-items .social-share span {
  font-weight: 500;
  color: var(--header);
}
.shows-details-wrapper .shows-details-top-items .button-items .social-share a:hover {
  color: var(--theme);
}
.shows-details-wrapper .shows-details-top-items .details-video-thumb {
  position: relative;
  margin-top: 20px;
}
.shows-details-wrapper .shows-details-top-items .details-video-thumb img {
  width: 100%;
  height: 100%;
}
.shows-details-wrapper .shows-details-top-items .details-video-thumb .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shows-details-wrapper .shows-details-top-items .details-video-thumb .video-play .video-btn {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  color: var(--white);
  display: inline-block;
  border-radius: 50%;
  background-color: var(--theme);
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery {
  margin-bottom: 30px;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item:not(:last-child) {
  margin-bottom: 10px;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb {
  position: relative;
}
@media (max-width: 575px) {
  .shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb {
    width: 100px;
  }
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  transition: 0.3s;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(214, 17, 30, 0.85);
  transition: 0.4s;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb .icon i {
  color: var(--white);
  font-size: 22px;
  z-index: 99;
  position: relative;
  margin-top: 40px;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb:hover .icon {
  opacity: 1;
}
.shows-details-wrapper .shows-sidebar-items .shows-gallery .gallery-item .thumb:hover .icon::after {
  opacity: 1;
}
.shows-details-wrapper .shows-sidebar-items .shows-post-area .shows-post-items {
  display: flex;
  align-items: center;
  gap: 15px;
}
.shows-details-wrapper .shows-sidebar-items .shows-post-area .shows-post-items .post-img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.shows-details-wrapper .shows-sidebar-items .shows-post-area .shows-post-items .post-cont h6 a:hover {
  color: var(--theme);
}
.shows-details-wrapper .shows-sidebar-items .shows-post-area .shows-post-items .post-cont ul {
  display: flex;
  align-items: center;
  gap: 20px;
}
.shows-details-wrapper .shows-sidebar-items .shows-post-area .shows-post-items .post-cont ul li i {
  color: var(--theme);
  margin-right: 7px;
}

.cta-banner-section {
  position: relative;
}
.cta-banner-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: var(--theme);
  opacity: 0.8;
}

.cta-banner-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .cta-banner-wrapper {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    text-align: center;
  }
}

.cta-banner-2 {
  background-color: var(--theme);
  max-width: 800px;
  margin: 60px auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  position: relative;
  z-index: 9;
}
@media (max-width: 767px) {
  .cta-banner-2 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}
.cta-banner-2 .cta-items {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 767px) {
  .cta-banner-2 .cta-items {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.cta-banner-2 .cta-items .content p {
  font-weight: 600;
  color: #C5C3C3;
}
@media (max-width: 470px) {
  .cta-banner-2 .cta-items .content p {
    text-align: center;
  }
}
.cta-banner-2 .cta-items .content h6 {
  font-size: 16px;
  color: var(--white);
}
.cta-banner-2 .theme-btn {
  line-height: 1;
}

.cta-subscribe-wrapper .cta-subscribe-image {
  margin-right: 60px;
}
.cta-subscribe-wrapper .cta-subscribe-image img {
  width: 100%;
  height: 100%;
}
.cta-subscribe-wrapper .cta-subscribe-content .cta-sub-title {
  color: var(--theme);
  font-weight: 600;
  margin-top: 30px;
}
.cta-subscribe-wrapper .cta-subscribe-content .cta-sub-title span {
  font-weight: 400;
  color: var(--header);
  margin-bottom: 5px;
  display: inline-block;
}
.cta-subscribe-wrapper .cta-subscribe-content .subscribe-plan {
  display: flex;
  align-items: center;
  margin-top: 46px;
}
.cta-subscribe-wrapper .cta-subscribe-content .subscribe-plan .price {
  line-height: 1;
  margin-bottom: 0;
  color: var(--header);
  font-size: 52px;
  position: relative;
  border: 2.5px solid var(--theme);
  border-radius: 10px;
  padding: 12px 24px;
  margin-right: 40px;
}
.cta-subscribe-wrapper .cta-subscribe-content .subscribe-plan .price span {
  font-size: 16px;
  font-weight: 400;
  color: var(--theme);
  position: absolute;
  left: 23px;
  top: -12px;
  background: var(--white);
  line-height: 1;
  padding: 2px 7px;
  z-index: 1;
}
.cta-subscribe-wrapper .cta-subscribe-content .subscribe-plan .price sub {
  bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--text);
}
.cta-subscribe-wrapper .cta-subscribe-content .subscribe-plan .btn {
  text-decoration: underline;
}
.cta-subscribe-wrapper .cta-subscribe-content .subscribe-plan .btn:hover {
  color: var(--bd-theme-secondary);
}
.cta-subscribe-wrapper .cta-subscribe-content.style-2 .subscribe-price {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cta-subscribe-wrapper .cta-subscribe-content.style-2 .subscribe-price h3 {
  font-size: 52px;
}
.cta-subscribe-wrapper .cta-subscribe-content.style-2 .subscribe-price h3 sub {
  font-size: 18px;
  font-weight: 400;
}
.cta-subscribe-wrapper .cta-subscribe-content.style-2 .subscribe-price h3 span {
  font-size: 18px;
  margin-left: -10px;
  font-weight: 400;
}
.cta-subscribe-wrapper .cta-subscribe-content.style-2 .subscribe-price h4 {
  color: var(--theme);
  font-weight: 500;
  font-size: 18px;
}

.cta-parallax-section {
  position: relative;
  background-attachment: fixed;
  z-index: 9;
}
.cta-parallax-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #0C1020;
  opacity: 0.6;
  z-index: -1;
}

.cta-parallax-wrapper {
  padding: 120px 0 220px;
}
.cta-parallax-wrapper .button-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}
.cta-parallax-wrapper .button-items .or {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
}

.cta-video-section {
  position: relative;
  z-index: 9;
}
.cta-video-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--header);
  top: 50%;
  z-index: -1;
}

.cta-video-wrapper {
  padding: 200px 30px;
  text-align: center;
  position: relative;
  z-index: 9;
}
@media (max-width: 991px) {
  .cta-video-wrapper {
    padding: 150px 30px;
  }
}
@media (max-width: 767px) {
  .cta-video-wrapper {
    padding: 120px 30px;
  }
}
@media (max-width: 575px) {
  .cta-video-wrapper {
    padding: 100px 20px;
  }
}
.cta-video-wrapper::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #0C1020;
  opacity: 0.4;
  z-index: -1;
}
.cta-video-wrapper h2 {
  font-size: 70px;
  color: var(--white);
}
@media (max-width: 1199px) {
  .cta-video-wrapper h2 {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .cta-video-wrapper h2 {
    font-size: 52px;
  }
}
@media (max-width: 767px) {
  .cta-video-wrapper h2 {
    font-size: 42px;
  }
}
@media (max-width: 575px) {
  .cta-video-wrapper h2 {
    font-size: 32px;
  }
}
.cta-video-wrapper .video-box {
  text-align: center;
  margin-top: 50px;
}
.cta-video-wrapper .video-box .video-btn {
  background-color: var(--theme);
  color: var(--white);
  display: inline-block;
  font-size: 16px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
}

.cta-shows-wrapper {
  padding: 150px 130px;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .cta-shows-wrapper {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
}
@media (max-width: 1199px) {
  .cta-shows-wrapper {
    padding: 100px 70px;
  }
}
@media (max-width: 991px) {
  .cta-shows-wrapper {
    padding: 80px 60px;
  }
}
@media (max-width: 767px) {
  .cta-shows-wrapper {
    padding: 60px 50px;
  }
}
@media (max-width: 575px) {
  .cta-shows-wrapper {
    padding: 50px 40px;
  }
}
.cta-shows-wrapper::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgb(34, 31, 31);
  background: linear-gradient(to right, rgb(34, 31, 31) 0%, rgba(34, 31, 31, 0.4) 100%);
  z-index: -1;
}
@media (max-width: 1199px) {
  .cta-shows-wrapper .cta-shows-content {
    text-align: center;
    margin: 0 auto;
  }
}
.cta-shows-wrapper .cta-shows-content h2 {
  font-size: 62px;
  color: var(--white);
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .cta-shows-wrapper .cta-shows-content h2 {
    font-size: 52px;
  }
}
@media (max-width: 767px) {
  .cta-shows-wrapper .cta-shows-content h2 {
    font-size: 42px;
  }
}
@media (max-width: 575px) {
  .cta-shows-wrapper .cta-shows-content h2 {
    font-size: 32px;
  }
}
.cta-shows-wrapper .cta-shows-content ul {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .cta-shows-wrapper .cta-shows-content ul {
    justify-content: center;
  }
}
@media (max-width: 470px) {
  .cta-shows-wrapper .cta-shows-content ul {
    flex-wrap: wrap;
  }
}
.cta-shows-wrapper .cta-shows-content ul li {
  color: var(--white);
}
.cta-shows-wrapper .cta-shows-content ul li i {
  color: var(--ratting);
}
.cta-shows-wrapper .cta-shows-content ul li a {
  background-color: var(--theme);
  color: var(--white);
  display: inline-block;
  padding: 6px 10px;
  line-height: 1;
  margin-left: 10px;
}
.cta-shows-wrapper .cta-shows-content p {
  color: var(--white);
  font-weight: 500;
}
.cta-shows-wrapper .cta-shows-content .button-items {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1199px) {
  .cta-shows-wrapper .cta-shows-content .button-items {
    justify-content: center;
  }
}
.cta-shows-wrapper .cta-shows-content .button-items .bookmark-btn {
  color: var(--white);
  font-weight: 500;
}
.cta-shows-wrapper .cta-shows-content .button-items .bookmark-btn i {
  margin-left: 5px;
}
.cta-shows-wrapper .video-box .video-btn {
  background-color: var(--theme);
  color: var(--white);
  display: inline-block;
  font-size: 16px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
}

.cta-download-wrapper {
  background-color: rgb(22, 22, 22);
  padding: 60px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .cta-download-wrapper {
    padding: 50px 50px;
  }
}
@media (max-width: 767px) {
  .cta-download-wrapper {
    padding: 50px 40px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 30px;
  }
  .cta-download-wrapper .apps-items {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .cta-download-wrapper {
    padding: 40px 30px;
  }
}
.cta-download-wrapper .thumb {
  max-width: 540px;
}
.cta-download-wrapper .thumb img {
  width: 100%;
  height: 100%;
}

.marque-section {
  position: relative;
}

.marquee-wrapper {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 575px) {
  .marquee-wrapper {
    margin-top: -25px;
  }
}

.text-slider {
  font-size: 114px;
  height: 110px;
  line-height: 90px;
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 20px;
  color: var(--theme);
  opacity: 0.5;
}
.text-slider.style-height {
  height: 65px;
  line-height: 0;
  opacity: initial;
}
.text-slider.style-height-2 {
  height: 71px;
  line-height: 0;
}
@media (max-width: 575px) {
  .text-slider.style-height-2 {
    line-height: 70px;
  }
}
@media (max-width: 575px) {
  .text-slider {
    font-size: 50px;
    height: 70px;
    line-height: 65px;
    margin-bottom: -10px;
  }
}
.text-slider.style-border {
  position: relative;
  padding-left: 65px;
}
.text-slider.style-border::before {
  position: absolute;
  bottom: 30px;
  left: 0;
  content: "";
  width: 60px;
  height: 8px;
  background-color: var(--theme);
  opacity: 0.5;
}

.marquee-inner {
  position: absolute;
  display: inline-flex;
  width: 200%;
}

.marquee-list {
  float: left;
  width: 50%;
}

.marquee-item {
  float: left;
  transition: animation 0.2s ease-out;
}
.marquee-item.style-2 .text-slider {
  text-transform: capitalize;
  font-size: 65px;
  color: var(--header);
  margin-right: 40px;
  line-height: 0;
  height: 65px;
  opacity: initial;
}
@media (max-width: 767px) {
  .marquee-item.style-2 .text-slider {
    font-size: 42px;
  }
}
.marquee-item.style-2 .text-style {
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(var(--theme), var(--theme));
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
}
.marquee-item.style-2 .text-style:hover {
  color: var(--theme);
  background-size: 100% 3px;
}
.marquee-item.style-2 .text-style-2 {
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(var(--theme2), var(--theme2));
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
}
.marquee-item.style-2 .text-style-2:hover {
  color: var(--theme2);
  background-size: 100% 3px;
}
.marquee-item.style-3 .text-slider {
  text-transform: uppercase;
  font-size: 18px;
  color: var(--white);
  margin-right: 20px;
  line-height: 0;
  height: 65px;
}

.marque-section .container-fluid {
  padding: 0 100px;
}
@media (max-width: 1199px) {
  .marque-section .container-fluid {
    padding: 0;
  }
}

.marque-section-2 {
  background-color: var(--theme);
}

.marquee-inner.to-left {
  animation: marqueeLeft 25s linear infinite;
}

@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.marquee-inner.to-right {
  animation: marqueeRight 25s linear infinite;
}

@keyframes marqueeRight {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
.testimonial-card-items {
  margin-top: 30px !important;
  border: 1px solid var(--border);
  padding: 45px 35px;
  position: relative;
}
.testimonial-card-items::before {
  position: absolute;
  right: 40px;
  top: 40px;
  content: "";
  width: 32px;
  height: 24px;
  /* background-image: url(../../assets/img/testimonial/quote.png); */
  background-repeat: no-repeat;
  background-size: cover;
}
.testimonial-card-items .author-items {
  display: flex;
  align-items: center;
  gap: 23px;
}
.testimonial-card-items .author-items .author-image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.testimonial-card-items .author-items .author-content h5 {
  margin-bottom: 5px;
}
.testimonial-card-items p {
  margin-top: 30px;
  font-size: 18px;
}
.testimonial-card-items ul {
  margin-top: 40px;
}
.testimonial-card-items ul li {
  padding: 15px;
  background-color: var(--theme);
  color: var(--white);
  font-weight: 500;
  position: relative;
}
.testimonial-card-items ul li::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 35px solid var(--theme);
  position: absolute;
  top: -15px;
  left: 50px;
  right: 0;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}

.testimonial-section {
  position: relative;
}
.testimonial-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(180deg, rgba(246, 242, 237, 0) 0%, #F6F2ED 100%);
  z-index: -1;
  top: 10%;
}

.testimonial-carousel-active .slick-slide {
  margin: 0 15px;
}
.testimonial-carousel-active .slide-dots {
  text-align: center;
  border: 1px dotted var(--theme);
  width: 145px;
  height: 40px;
  line-height: 40px;
  border-radius: 30px;
  margin: 0 auto;
  margin-top: 30px;
}
.testimonial-carousel-active .slide-dots li {
  display: inline-block;
  margin: 13px 5px;
}
.testimonial-carousel-active .slide-dots li button {
  position: relative;
  text-indent: -9999px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
  border: 2px solid var(--theme);
}
.testimonial-carousel-active .slide-dots li button:hover {
  background-color: var(--theme);
}
.testimonial-carousel-active .slide-dots li.slick-active button {
  background-color: var(--theme);
  border-color: var(--theme);
}
.testimonial-carousel-active .slick-list {
  margin: 0 -15px;
}

.testimonial-carousel-active-2 .slick-slide {
  margin: 0 15px;
}
.testimonial-carousel-active-2 .slick-list {
  margin: 0 -15px;
}
.testimonial-carousel-active-2 .slide-dots {
  margin: 40px auto;
  text-align: center;
}
.testimonial-carousel-active-2 .slide-dots li {
  display: inline-block;
  margin: 13px 8px;
}
.testimonial-carousel-active-2 .slide-dots li button {
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  transition: 0.6s;
  background-color: #D6D1D1;
  opacity: 1;
  border-radius: 10px;
  position: relative;
}
.testimonial-carousel-active-2 .slide-dots li button:hover {
  background-color: var(--theme);
}
.testimonial-carousel-active-2 .slide-dots li.slick-active button {
  background-color: var(--theme);
  transition: 0.6s;
  position: relative;
}
.testimonial-carousel-active-2 .slide-dots li.slick-active button::before {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  top: -6px;
  left: -6px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--theme);
  content: "";
}

.testimonial-card-items-2 {
  margin-top: 30px;
  text-align: center;
  border-radius: 5px;
  padding: 35px 50px;
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .testimonial-card-items-2 {
    padding: 30px;
  }
}
.testimonial-card-items-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white);
  z-index: -1;
  top: 20%;
  border-radius: 5px;
  height: initial;
}
.testimonial-card-items-2 .testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
}
.testimonial-card-items-2 .testimonial-content {
  margin-top: 20px;
}
.testimonial-card-items-2 .testimonial-content .star {
  margin-bottom: 10px;
}
.testimonial-card-items-2 .testimonial-content .star span {
  font-size: 14px;
  color: var(--ratting);
}
.testimonial-card-items-2 .testimonial-content .star span.color-2 {
  color: #DED9D1;
}
.testimonial-card-items-2 .testimonial-content .author-name {
  margin-top: 30px;
}
@media (max-width: 575px) {
  .testimonial-card-items-2 .testimonial-content .author-name {
    margin-top: 20px;
  }
}

.testimonial-section-3 {
  position: relative;
}
.testimonial-section-3::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--header);
  top: 70%;
}
.testimonial-section-3 .container-fluid {
  padding: 0 110px;
}
@media (max-width: 1399px) {
  .testimonial-section-3 .container-fluid {
    padding: 0 15px;
  }
}

.testimonial-wrapper-3 {
  padding: 120px 220px;
  position: relative;
  background-color: var(--bg2);
}
@media (max-width: 1399px) {
  .testimonial-wrapper-3 {
    padding: 120px 60px;
  }
}
@media (max-width: 991px) {
  .testimonial-wrapper-3 {
    padding: 80px 50px;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper-3 {
    padding: 50px 40px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-3 {
    padding: 40px 30px;
  }
}
.testimonial-wrapper-3 .testimonial-items {
  display: flex;
  align-items: center;
  gap: 60px;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-3 .testimonial-items {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 0;
  }
}
.testimonial-wrapper-3 .testimonial-items .testimonial-image-area {
  flex-basis: 100%;
}
.testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image {
  max-width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image {
    max-width: 250px;
    height: 250px;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image {
    max-width: 230px;
    height: 230px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image {
    max-width: 140px;
    height: 140px;
  }
}
.testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  color: var(--theme2);
  font-size: 42px;
  position: absolute;
  top: 0;
  right: -15px;
}
@media (max-width: 991px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image .icon {
    font-size: 38px;
    width: 85px;
    height: 85px;
    line-height: 95px;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image .icon {
    font-size: 32px;
    width: 70px;
    height: 70px;
    line-height: 80px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-image-area .testimonial-image .icon {
    font-size: 22px;
    width: 50px;
    height: 50px;
    line-height: 60px;
  }
}
.testimonial-wrapper-3 .testimonial-items .testimonial-image-area .star {
  width: 200px;
  text-align: center;
  margin: 30px auto;
  border: 1px solid var(--border2);
  border-radius: 45px;
  height: 45px;
  line-height: 45px;
  position: relative;
}
.testimonial-wrapper-3 .testimonial-items .testimonial-image-area .star::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--border2);
  background-color: transparent;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
  z-index: 1;
}
.testimonial-wrapper-3 .testimonial-items .testimonial-image-area .star span {
  color: var(--ratting);
}
.testimonial-wrapper-3 .testimonial-items .testimonial-content h3 {
  font-size: 30px;
  font-weight: 500;
  line-height: 170%;
  border-bottom: 1px solid var(--border2);
  padding-bottom: 50px;
}
@media (max-width: 1399px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-content h3 {
    font-size: 26px;
  }
}
@media (max-width: 1199px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-content h3 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-content h3 {
    font-size: 20px;
    padding-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-content h3 {
    font-size: 16px;
    padding-bottom: 20px;
  }
}
.testimonial-wrapper-3 .testimonial-items .testimonial-content .athor-name {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-content .athor-name {
    justify-content: center;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-3 .testimonial-items .testimonial-content .athor-name {
    margin-top: 20px;
  }
}
.testimonial-wrapper-3 .testimonial-items .testimonial-content .athor-name p {
  font-size: 14px;
}
.testimonial-wrapper-3 .slider-button {
  position: absolute;
  bottom: 20%;
  right: 20%;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-3 .slider-button {
    bottom: 4%;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 991px) {
  .testimonial-wrapper-3 .slider-button {
    display: none;
  }
}
.testimonial-wrapper-3 .testimonial-nav-prev, .testimonial-wrapper-3 .testimonial-nav-next {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  color: var(--black);
  background-color: #F1F1F1;
  line-height: 60px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .testimonial-wrapper-3 .testimonial-nav-prev, .testimonial-wrapper-3 .testimonial-nav-next {
    width: 50px;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
  }
}
.testimonial-wrapper-3 .testimonial-nav-prev.slick-disabled, .testimonial-wrapper-3 .testimonial-nav-next.slick-disabled {
  background-color: #b8b8b8 !important;
}
.testimonial-wrapper-3 .testimonial-nav-prev:hover, .testimonial-wrapper-3 .testimonial-nav-next:hover {
  background-color: var(--theme2);
  color: var(--white);
}
.testimonial-wrapper-3.style-2 {
  background-color: var(--white);
}
@media (max-width: 1199px) {
  .testimonial-wrapper-3.style-2 {
    margin-bottom: -5px;
  }
}
.testimonial-wrapper-3.style-2 .slider-button {
  position: absolute;
  bottom: 0;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-3.style-2 .slider-button {
    display: none;
  }
}
.testimonial-wrapper-3.style-2 .testimonial-nav-prev:hover, .testimonial-wrapper-3.style-2 .testimonial-nav-next:hover {
  background-color: var(--theme);
}
.testimonial-wrapper-3.style-2 .testimonial-items .testimonial-image-area .testimonial-image .icon {
  color: var(--theme);
}

.testimonial-section-4 {
  position: relative;
}
@media (max-width: 1199px) {
  .testimonial-section-4 {
    margin-top: -40px;
  }
}
.testimonial-section-4::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg5);
  z-index: -1;
  top: 30%;
}
.testimonial-section-4::after {
  position: absolute;
  bottom: 11%;
  right: 20%;
  width: 90px;
  height: 90px;
  border-radius: 90px;
  background-color: #FFB302;
  content: "";
  z-index: -1;
}
.testimonial-section-4 .client-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  width: 90px;
  height: 90px;
  animation: scale-up-one 3s infinite linear;
}
@media (max-width: 1399px) {
  .testimonial-section-4 .client-1 {
    display: none;
  }
}
.testimonial-section-4 .client-1 img {
  width: 100%;
  height: 100%;
}
.testimonial-section-4 .client-2 {
  position: absolute;
  bottom: 10%;
  right: 10%;
  animation: scale-up-one 4s infinite linear;
}
@media (max-width: 1399px) {
  .testimonial-section-4 .client-2 {
    display: none;
  }
}
.testimonial-section-4 .slider-button {
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 991px) {
  .testimonial-section-4 .slider-button {
    display: none;
  }
}
.testimonial-section-4 .slider-button .testimonial-nav-prev, .testimonial-section-4 .slider-button .testimonial-nav-next {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--theme);
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.testimonial-section-4 .slider-button .testimonial-nav-prev:hover, .testimonial-section-4 .slider-button .testimonial-nav-next:hover {
  background-color: var(--theme);
  color: var(--white);
}

.testimonial-wrapper-4 {
  position: relative;
}
.testimonial-wrapper-4 .testimonial-items {
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-4 .testimonial-items {
    padding: 40px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-4 .testimonial-items {
    padding: 30px;
  }
}
.testimonial-wrapper-4 .testimonial-items::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  top: 40px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-4 .testimonial-items::before {
    top: 70px;
  }
}
.testimonial-wrapper-4 .testimonial-items .testimonial-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.testimonial-wrapper-4 .testimonial-items .client-info {
  margin-top: 20px;
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .testimonial-wrapper-4 .testimonial-items .client-info {
    margin-top: 10px;
  }
}
.testimonial-wrapper-4 .testimonial-items .client-info h5 {
  font-size: 18px;
  text-transform: uppercase;
}
.testimonial-wrapper-4 .testimonial-items .client-info h6 {
  font-size: 16px;
  margin-top: 5px;
}
.testimonial-wrapper-4 .testimonial-items .client-info h6 span {
  color: var(--theme);
}
.testimonial-wrapper-4 .testimonial-items .testimonial-content {
  max-width: 950px;
  text-align: center;
  margin: 50px auto;
  position: relative;
  z-index: 9;
}
@media (max-width: 991px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content {
    margin: 30px auto;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content {
    margin: 20px auto;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content {
    margin: 10px auto;
  }
}
.testimonial-wrapper-4 .testimonial-items .testimonial-content h3 {
  font-size: 30px;
  line-height: 180%;
  font-weight: 500;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content h3 {
    font-size: 25px;
  }
}
@media (max-width: 991px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content h3 {
    font-size: 24px;
    line-height: 170%;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content h3 {
    font-size: 20px;
    line-height: 165%;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content h3 {
    font-size: 18px;
    line-height: 160%;
  }
}
.testimonial-wrapper-4 .testimonial-items .testimonial-content .star {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content .star {
    margin-top: 20px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper-4 .testimonial-items .testimonial-content .star {
    margin-top: 10px;
  }
}
.testimonial-wrapper-4 .testimonial-items .testimonial-content .star span {
  font-size: 14px;
  color: var(--ratting);
}

.testimonial-wrapper {
  margin-bottom: -50px;
}

.brand-wrapper h6 {
  font-size: 14px;
  position: relative;
  margin-bottom: 50px;
}
.brand-wrapper h6::before {
  position: absolute;
  top: 10px;
  left: 35px;
  content: "";
  height: 1px;
  width: 480px;
  background-color: var(--border);
}
@media (max-width: 1399px) {
  .brand-wrapper h6::before {
    display: none;
  }
}
.brand-wrapper h6::after {
  position: absolute;
  top: 10px;
  right: 35px;
  content: "";
  height: 1px;
  width: 480px;
  background-color: var(--border);
}
@media (max-width: 1399px) {
  .brand-wrapper h6::after {
    display: none;
  }
}
.brand-wrapper .brand-image {
  text-align: center;
  filter: grayscale(100%);
  transition: all 0.4s ease-in-out;
}
.brand-wrapper .brand-image:hover {
  filter: initial;
}
.brand-wrapper.style-2 .brand-carousel-active-2 .slick-slide {
  margin: 0 15px;
}
.brand-wrapper.style-2 .brand-carousel-active-2 .slick-list {
  margin: 0 -15px;
}
.brand-wrapper.style-2 .brand-image {
  padding: 30px 20px;
}
@media (max-width: 767px) {
  .brand-wrapper.style-2 .brand-image {
    padding: 30px 0;
  }
}
.brand-wrapper.style-2 .brand-image img {
  text-align: center;
  margin: 0 auto;
}
.brand-wrapper.style-2 .brand-image:hover {
  background-color: var(--white);
}

.brand-wrapper-3 {
  padding: 70px 0;
  border-bottom: 1px solid var(--border);
}
.brand-wrapper-3 .brand-image {
  text-align: center;
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
}
.brand-wrapper-3 .brand-image img {
  text-align: center;
  margin: 0 auto;
}
.brand-wrapper-3 .brand-image:hover {
  filter: initial;
  opacity: 1;
}

.brand-section-2 {
  padding: 80px 0;
  margin-top: -68px;
}
@media (max-width: 767px) {
  .brand-section-2 {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .brand-section-2 {
    padding: 40px 0;
  }
}

.brand-section-3 {
  position: relative;
}
.brand-section-3::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg5);
}

.brand-section-4 {
  position: relative;
}
.brand-section-4::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--header);
  top: 70%;
}

.brand-wrapper-4 {
  background-color: var(--white);
  position: relative;
  z-index: 9;
  padding: 80px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: -50px;
}
.brand-wrapper-4 .brand-img {
  text-align: center;
  margin: 0 auto;
}

.pricing-section {
  background-color: var(--header);
}

.pricing-card-items {
  margin-top: 30px;
  background-color: var(--white);
  border-radius: 8px;
  padding: 50px 30px;
  position: relative;
  z-index: 9;
  text-align: center;
  overflow: hidden;
}
.pricing-card-items::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #f4f2e9;
  z-index: -1;
  border-radius: 8px 8px 0 0;
  height: 31%;
}
.pricing-card-items .tag {
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 9;
  transform: rotate(45deg);
  font-weight: 900;
  font-size: 14px;
  color: var(--white);
}
.pricing-card-items .tag::before {
  position: absolute;
  top: -11px;
  right: -15px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 90px 0;
  border-color: transparent var(--theme) transparent;
  z-index: 0;
  transform: rotate(-42deg);
  z-index: -1;
}
.pricing-card-items .pricing-header {
  text-align: center;
}
.pricing-card-items .pricing-header h6 {
  color: var(--theme);
}
.pricing-card-items .icon-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 20px;
}
.pricing-card-items .icon-items li {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.08);
}
.pricing-card-items .icon-items li i {
  font-size: 35px;
  line-height: 85px;
  color: var(--header);
}
.pricing-card-items .price-list {
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: inline-block;
  text-align: left;
}
.pricing-card-items .price-list li:not(:last-child) {
  margin-bottom: 10px;
}
.pricing-card-items .price-list li i {
  color: var(--theme);
  margin-right: 5px;
}
.pricing-card-items .price {
  font-size: 18px;
  line-height: 1em;
  margin: 25px 0 30px;
  font-weight: 600;
  color: var(--theme);
  vertical-align: middle;
}
.pricing-card-items .price span {
  color: var(--header);
}

.pricing-section-2 {
  background-color: #f5f5f8;
}

.pricing-wrapper {
  background-color: var(--white);
  padding: 60px;
  padding-right: 35px;
}
@media (max-width: 767px) {
  .pricing-wrapper .pricing-content-box {
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 30px;
  }
}
.pricing-wrapper .pricing-content-box .price-content p {
  color: var(--text);
  margin-bottom: 5px;
}
.pricing-wrapper .pricing-content-box .price-content h3 {
  font-size: 28px;
  margin-bottom: 10px;
}
.pricing-wrapper .pricing-content-box .price-content span em {
  color: var(--theme);
}
.pricing-wrapper .pricing-content-box .price-icon {
  display: flex;
  align-items: center;
  gap: 25px;
  border: 1px solid #E9EDF3;
  border-radius: 50px;
  padding: 20px;
}
.pricing-wrapper .pricing-content-box .price-icon .icon {
  height: 60px;
  width: 60px;
  line-height: 70px;
  text-align: center;
  background-color: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  font-size: 36px;
  color: var(--theme);
}
@media (max-width: 1199px) {
  .pricing-wrapper .pricing-list-items {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .pricing-wrapper .pricing-list-items {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.pricing-wrapper .pricing-list-items .price-list {
  flex: 0 0 auto;
  margin-right: 45px;
}
@media (max-width: 1399px) {
  .pricing-wrapper .pricing-list-items .price-list {
    margin-right: 0;
  }
}
.pricing-wrapper .pricing-list-items .price-list li:not(:last-child) {
  margin-bottom: 5px;
}
.pricing-wrapper .pricing-list-items .price-list li i {
  color: var(--theme);
  margin-right: 5px;
}
.pricing-wrapper .pricing-list-items .price-button {
  text-align: center;
}
.pricing-wrapper .pricing-list-items .price-button h3 {
  margin-bottom: 10px;
  font-size: 48px;
}
.pricing-wrapper .pricing-list-items .price-button h3 i {
  margin-left: -10px;
  font-size: 16px;
}
.pricing-wrapper .pricing-list-items .price-button h3 span {
  font-size: 14px;
  color: var(--text);
  font-weight: 400;
}
.pricing-wrapper .pricing-list-items .price-button .theme-btn {
  background-color: transparent;
  border: 1px solid rgba(50, 57, 74, 0.15);
  border-radius: 0;
}
.pricing-wrapper .pricing-list-items .price-button .theme-btn span {
  color: var(--header);
  transition: all 0.4s ease-in-out;
}
.pricing-wrapper .pricing-list-items .price-button .theme-btn::before, .pricing-wrapper .pricing-list-items .price-button .theme-btn::after {
  background-color: var(--theme);
  border-radius: 0;
}
.pricing-wrapper .pricing-list-items .price-button .theme-btn:hover span {
  color: var(--white);
}
.pricing-wrapper.active {
  background-color: var(--theme);
}
.pricing-wrapper.active .pricing-content-box .price-content p {
  color: var(--white);
}
.pricing-wrapper.active .pricing-content-box .price-content h3 {
  color: var(--white);
}
.pricing-wrapper.active .pricing-content-box .price-content span {
  color: var(--white);
}
.pricing-wrapper.active .pricing-content-box .price-content span em {
  color: var(--white);
}
.pricing-wrapper.active .pricing-content-box .price-icon {
  border: 1px solid var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-list li {
  color: var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-list li i {
  color: var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-button {
  text-align: center;
}
.pricing-wrapper.active .pricing-list-items .price-button h3 {
  color: var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-button h3 i {
  color: var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-button h3 span {
  color: var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-button .theme-btn {
  border: 1px solid var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-button .theme-btn span {
  color: var(--white);
}
.pricing-wrapper.active .pricing-list-items .price-button .theme-btn::before, .pricing-wrapper.active .pricing-list-items .price-button .theme-btn::after {
  background-color: var(--header);
}
.pricing-wrapper.active .pricing-list-items .price-button .theme-btn:hover {
  border: 1px solid transparent;
}
.pricing-wrapper.active .pricing-list-items .price-button .theme-btn:hover span {
  color: var(--white);
}

.pricing-card-items-2 {
  padding: 40px 50px;
  text-align: center;
  position: relative;
  z-index: 9;
}
.pricing-card-items-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: initial;
  z-index: -1;
  top: 68px;
}
.pricing-card-items-2 .pricing-icon {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-bottom: 30px;
}
.pricing-card-items-2 .pricing-icon .icon {
  height: 60px;
  width: 60px;
  line-height: 70px;
  text-align: center;
  background-color: var(--theme2);
  border-radius: 50%;
  font-size: 36px;
  color: var(--white);
  box-shadow: 0px 2px 15px #c6c6c6;
}
.pricing-card-items-2 .pricing-header {
  text-align: center;
}
.pricing-card-items-2 .pricing-header h2 {
  font-size: 52px;
  margin-top: 20px;
}
.pricing-card-items-2 .pricing-header h2 sub {
  font-size: 24px;
  color: var(--header);
  font-weight: 600;
  margin-top: -15px;
  display: inline-block;
}
.pricing-card-items-2 .pricing-header h2 span {
  font-size: 16px;
  color: var(--text);
  font-weight: 400;
  margin-left: -15px;
}
.pricing-card-items-2 .pricing-list {
  margin-top: 30px;
  text-align: left;
  margin-bottom: 40px;
}
.pricing-card-items-2 .pricing-list li {
  color: var(--header);
}
.pricing-card-items-2 .pricing-list li i {
  color: var(--theme);
  margin-right: 5px;
}
.pricing-card-items-2 .pricing-list li:not(:last-child) {
  margin-bottom: 15px;
}
.pricing-card-items-2 .theme-btn {
  padding: 20px 40px;
}
.pricing-card-items-2.active::before {
  background-color: var(--theme2);
}
.pricing-card-items-2.active .pricing-icon .icon {
  background-color: var(--white);
  color: var(--header);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pricing-card-items-2.active .pricing-header h2 {
  color: var(--white);
}
.pricing-card-items-2.active .pricing-header h2 sub {
  color: var(--white);
}
.pricing-card-items-2.active .pricing-header h2 span {
  color: var(--white);
}
.pricing-card-items-2.active .pricing-header p, .pricing-card-items-2.active .pricing-header h5 {
  color: var(--white);
}
.pricing-card-items-2.active .pricing-list li {
  color: var(--white);
}
.pricing-card-items-2.active .pricing-list li i {
  color: var(--white);
}
.pricing-card-items-2.active .theme-btn {
  background-color: var(--white);
  color: var(--header);
}
.pricing-card-items-2.active .theme-btn:hover {
  color: var(--white);
}

.pricing-box-items {
  margin-top: 30px;
  background-color: rgb(22, 22, 22);
}
.pricing-box-items .pricing-header {
  background-color: var(--theme);
  padding: 30px 40px;
  position: relative;
}
.pricing-box-items .pricing-header h3 {
  color: var(--white);
  font-size: 28px;
}
.pricing-box-items .pricing-header h4 {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}
.pricing-box-items .pricing-header .icon {
  font-size: 50px;
  color: var(--white);
  position: absolute;
  top: 30px;
  right: 40px;
}
.pricing-box-items .pricing-list {
  padding: 30px 40px;
}
.pricing-box-items .pricing-list li {
  display: flex;
  gap: 20px;
}
.pricing-box-items .pricing-list li:not(:last-child) {
  margin-bottom: 15px;
}
.pricing-box-items .pricing-list li .price {
  color: var(--white);
  font-size: 14px;
  line-height: 1.7;
}
.pricing-box-items .pricing-list li i {
  color: var(--theme);
  font-size: 18px;
  margin-top: 5px;
}
.pricing-box-items .price-button {
  padding: 0 40px 30px;
}
.pricing-box-items .price-button .theme-btn::before, .pricing-box-items .price-button .theme-btn::after {
  background-color: var(--white);
}
.pricing-box-items .price-button .theme-btn span {
  transition: all 0.4s ease-in-out;
}
.pricing-box-items .price-button .theme-btn:hover span {
  color: var(--header);
}

.news-box-items {
  margin-top: 30px;
}
.news-box-items .news-image {
  height: 260px;
  border-radius: 8px;
}
.news-box-items .news-content {
  margin: -60px 20px 0;
  background-color: var(--white);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 30px;
  position: relative;
  z-index: 9;
}
.news-box-items .news-content h4 {
  font-size: 20px;
}
.news-box-items .news-content h4 a:hover {
  color: var(--theme);
}
.news-box-items .news-content ul {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 0;
}
.news-box-items .news-content ul li {
  font-size: 14px;
}
.news-box-items .news-content ul li i {
  color: var(--theme);
  margin-right: 5px;
}
.news-box-items .news-content p {
  margin-bottom: 15px;
}

.news-card-items {
  margin-top: 30px !important;
  padding-bottom: 60px;
}
.news-card-items .news-image {
  height: 388px;
  max-width: 420px;
}
@media (max-width: 1199px) {
  .news-card-items .news-image {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .news-card-items .news-image {
    text-align: center;
    margin: 0 auto;
  }
}
.news-card-items .news-content {
  margin-top: -100px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 25px;
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  position: relative;
  z-index: 9;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1399px) {
  .news-card-items .news-content {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1199px) {
  .news-card-items .news-content {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 991px) {
  .news-card-items .news-content {
    margin-left: 0;
    margin-right: 0;
  }
}
.news-card-items .news-content .post-area {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
.news-card-items .news-content .post-area li {
  transition: all 0.4s ease-in-out;
}
.news-card-items .news-content .post-area li i {
  color: var(--theme);
  margin-right: 5px;
  transition: all 0.4s ease-in-out;
}
.news-card-items .news-content h5 {
  margin-bottom: 20px;
}
.news-card-items .news-content .link-btn {
  transition: all 0.4s ease-in-out;
}
.news-card-items .news-content .link-btn:hover {
  color: var(--white);
}
.news-card-items:hover .news-content, .news-card-items .active .news-content {
  background-color: var(--theme);
  box-shadow: 0px 11px 39px rgba(22, 111, 211, 0.54);
}
.news-card-items:hover .news-content .post-area li, .news-card-items .active .news-content .post-area li {
  color: var(--white);
}
.news-card-items:hover .news-content .post-area li i, .news-card-items .active .news-content .post-area li i {
  color: var(--white);
}
.news-card-items:hover .news-content h5 a, .news-card-items .active .news-content h5 a {
  color: var(--white);
}
.news-card-items:hover .news-content .link-btn span, .news-card-items .active .news-content .link-btn span {
  color: var(--white);
  background-image: linear-gradient(var(--white), var(--white));
}
.news-card-items:hover .news-content .link-btn i, .news-card-items .active .news-content .link-btn i {
  background-color: var(--white);
  color: var(--theme);
}

.news-carousel-active .slick-slide {
  margin: 0 15px;
}
.news-carousel-active .slide-dots {
  margin: 0 auto;
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
}
.news-carousel-active .slide-dots li {
  display: inline-block;
  margin: 13px 8px;
}
.news-carousel-active .slide-dots li button {
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  transition: 0.6s;
  background-color: #D6D1D1;
  opacity: 1;
  border-radius: 10px;
  position: relative;
}
.news-carousel-active .slide-dots li button:hover {
  background-color: var(--theme);
}
.news-carousel-active .slide-dots li.slick-active button {
  background-color: var(--theme);
  transition: 0.6s;
  position: relative;
}
.news-carousel-active .slide-dots li.slick-active button::before {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  top: -6px;
  left: -6px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--theme);
  content: "";
}
.news-carousel-active .slick-list {
  margin: 0 -15px;
}
.news-carousel-active .news-card-items.slick-slide.slick-current.slick-active .news-content {
  background-color: var(--theme);
  box-shadow: 0px 11px 39px rgba(22, 111, 211, 0.54);
}
.news-carousel-active .news-card-items.slick-slide.slick-current.slick-active .news-content .post-area li {
  color: var(--white);
}
.news-carousel-active .news-card-items.slick-slide.slick-current.slick-active .news-content .post-area li i {
  color: var(--white);
}
.news-carousel-active .news-card-items.slick-slide.slick-current.slick-active .news-content h5 a {
  color: var(--white);
}
.news-carousel-active .news-card-items.slick-slide.slick-current.slick-active .news-content .link-btn span {
  color: var(--white);
  background-image: linear-gradient(var(--white), var(--white));
}
.news-carousel-active .news-card-items.slick-slide.slick-current.slick-active .news-content .link-btn i {
  background-color: var(--white);
  color: var(--theme);
}

.single-news-items-2 {
  margin-top: 30px;
}
.single-news-items-2 .news-image {
  max-width: 408px;
  height: 270px;
  position: relative;
}
@media (max-width: 1199px) {
  .single-news-items-2 .news-image {
    max-width: 550px;
  }
}
.single-news-items-2 .news-image .post-date {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 30px;
  background-color: var(--theme2);
  text-align: center;
}
.single-news-items-2 .news-image .post-date h4 {
  color: var(--white);
}
.single-news-items-2 .news-image .post-date h4 span {
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
}
.single-news-items-2 .news-content {
  padding-top: 25px;
}
.single-news-items-2 .news-content p {
  color: var(--theme2);
  margin-bottom: 10px;
  font-weight: 500;
}
.single-news-items-2 .news-content h4 {
  margin-bottom: 20px;
}
.single-news-items-2 .news-content h4 a {
  background-image: linear-gradient(var(--theme2), var(--theme2));
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: all 0.4s ease-in-out;
}
.single-news-items-2 .news-content h4 a:hover {
  color: var(--theme2);
  background-size: 100% 2px;
}

.news-section-2 {
  padding-bottom: 50px;
}
@media (max-width: 1199px) {
  .news-section-2 {
    padding-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .news-section-2 {
    padding-bottom: 10px;
  }
}

.blog-wrapper h1, .blog-wrapper h2, .blog-wrapper h3, .blog-wrapper h4, .blog-wrapper h5, .blog-wrapper h6 {
  font-weight: 600;
}
.blog-wrapper .single-blog-post {
  margin-bottom: 40px;
  position: relative;
}
.blog-wrapper .single-blog-post .video__button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.blog-wrapper .single-blog-post .video__button .video__btn__wrp {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post.post-details.format-quote .post-content {
  position: relative;
  background: transparent;
  border: none;
}
.blog-wrapper .single-blog-post.post-details .post-content {
  border: none;
}
.blog-wrapper .single-blog-post.post-details .post-content img {
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog-wrapper .single-blog-post.post-details .post-content .single-post-image {
  width: 100%;
  height: 100%;
}
.blog-wrapper .single-blog-post.post-details .post-content h1 {
  font-size: 36px;
  line-height: 1.4;
}
.blog-wrapper .single-blog-post.post-details .post-content h1, .blog-wrapper .single-blog-post.post-details .post-content h2, .blog-wrapper .single-blog-post.post-details .post-content h3, .blog-wrapper .single-blog-post.post-details .post-content h4, .blog-wrapper .single-blog-post.post-details .post-content h5, .blog-wrapper .single-blog-post.post-details .post-content h6 {
  margin-top: 10px;
}
.blog-wrapper .single-blog-post.post-details .post-content table {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post.post-details .post-content h1 {
    font-size: 28px;
  }
  .blog-wrapper .single-blog-post.post-details .post-content h3 {
    font-size: 20px;
  }
}
.blog-wrapper .single-blog-post.post-details blockquote, .blog-wrapper .single-blog-post.post-details .wp-block-quote {
  background-color: var(--theme);
  color: var(--white);
  font-size: 26px;
  line-height: 1.4;
  font-family: "montserrat";
  font-weight: 400;
  padding: 60px;
  text-align: center;
  margin: 40px 0px;
  position: relative;
  z-index: 1;
}
.blog-wrapper .single-blog-post.post-details blockquote a, .blog-wrapper .single-blog-post.post-details .wp-block-quote a {
  color: var(--white);
}
.blog-wrapper .single-blog-post.post-details blockquote a:hover, .blog-wrapper .single-blog-post.post-details .wp-block-quote a:hover {
  color: var(--text);
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post.post-details blockquote, .blog-wrapper .single-blog-post.post-details .wp-block-quote {
    padding: 30px 15px;
    font-size: 18px;
    line-height: 1.5;
  }
}
.blog-wrapper .single-blog-post.post-details blockquote::before, .blog-wrapper .single-blog-post.post-details .wp-block-quote::before {
  right: 30px;
  font-size: 110px;
  line-height: 1;
  font-family: "montserrat";
  position: absolute;
  content: "\f10d";
  bottom: -20px;
  color: var(--white);
  z-index: -1;
  opacity: 0.1;
  font-weight: 900;
}
.blog-wrapper .single-blog-post.format-video .post-featured-thumb, .blog-wrapper .single-blog-post.category-video-post .post-featured-thumb {
  z-index: 1;
  position: relative;
}
.blog-wrapper .single-blog-post.format-video .post-featured-thumb::before, .blog-wrapper .single-blog-post.category-video-post .post-featured-thumb::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #282835;
  opacity: 0.3;
  z-index: -1;
}
.blog-wrapper .single-blog-post.quote-post .post-content, .blog-wrapper .single-blog-post.format-quote .post-content {
  position: relative;
  background-color: var(--theme);
  border: none;
}
.blog-wrapper .single-blog-post.quote-post .post-content::before, .blog-wrapper .single-blog-post.format-quote .post-content::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background-image: url(../../assets/img/quotepost.png); */
  background-repeat: no-repeat;
  background-size: cover;
}
.blog-wrapper .single-blog-post.quote-post .quote-content, .blog-wrapper .single-blog-post.format-quote .quote-content {
  overflow: hidden;
  padding: 50px;
}
@media (max-width: 585px) {
  .blog-wrapper .single-blog-post.quote-post .quote-content, .blog-wrapper .single-blog-post.format-quote .quote-content {
    text-align: center;
    font-size: 24px;
    padding: 30px;
  }
}
.blog-wrapper .single-blog-post.quote-post .quote-content .icon, .blog-wrapper .single-blog-post.format-quote .quote-content .icon {
  font-size: 70px;
  line-height: 80px;
  float: left;
  overflow: hidden;
  margin-right: 30px;
}
@media (max-width: 585px) {
  .blog-wrapper .single-blog-post.quote-post .quote-content .icon, .blog-wrapper .single-blog-post.format-quote .quote-content .icon {
    float: none;
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 50px;
    line-height: 60px;
  }
}
.blog-wrapper .single-blog-post.quote-post .quote-content .quote-text, .blog-wrapper .single-blog-post.format-quote .quote-content .quote-text {
  overflow: hidden;
}
.blog-wrapper .single-blog-post.quote-post .quote-content h2, .blog-wrapper .single-blog-post.format-quote .quote-content h2 {
  margin-top: -2px;
  font-size: 30px;
  color: var(--white);
}
.blog-wrapper .single-blog-post.quote-post .quote-content .post-meta, .blog-wrapper .single-blog-post.format-quote .quote-content .post-meta {
  margin-top: 10px;
}
.blog-wrapper .single-blog-post.quote-post .quote-content .post-meta span, .blog-wrapper .single-blog-post.quote-post .quote-content .post-meta i, .blog-wrapper .single-blog-post.format-quote .quote-content .post-meta span, .blog-wrapper .single-blog-post.format-quote .quote-content .post-meta i {
  color: var(--white);
}
.blog-wrapper .single-blog-post:last-child {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post .post-featured-thumb {
  height: 450px;
  width: 100%;
  position: relative;
  margin-bottom: -2px;
}
.blog-wrapper .single-blog-post .post-featured-thumb .post-date {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 15px 30px;
  background-color: var(--theme);
  text-align: center;
}
.blog-wrapper .single-blog-post .post-featured-thumb .post-date h4 {
  color: var(--white);
}
.blog-wrapper .single-blog-post .post-featured-thumb .post-date h4 span {
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
}
@media (max-width: 991px) {
  .blog-wrapper .single-blog-post .post-featured-thumb {
    height: 280px;
  }
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-featured-thumb {
    height: 230px;
  }
}
.blog-wrapper .single-blog-post .post-featured-thumb .video-play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.blog-wrapper .single-blog-post .post-featured-thumb .video-play-btn .play-video {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
}
.blog-wrapper .single-blog-post .post-content {
  padding-top: 30px;
  overflow: hidden;
  box-sizing: border-box;
}
@media (max-width: 575px) {
  .blog-wrapper .single-blog-post .post-content {
    padding-top: 20px;
  }
}
.blog-wrapper .single-blog-post .post-content h2 {
  line-height: 1.4;
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 575px) {
  .blog-wrapper .single-blog-post .post-content h2 {
    font-size: 28px;
  }
}
.blog-wrapper .single-blog-post .post-content h2 a:hover {
  color: var(--theme);
}
.blog-wrapper .single-blog-post .post-content p {
  font-size: 16px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content {
    padding: 30px 10px;
  }
}
@media (max-width: 414px) {
  .blog-wrapper .single-blog-post .post-content h2 {
    font-size: 22px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-cat a {
  color: var(--white);
  display: inline-block;
  background-color: var(--theme);
  padding: 14px 18px 12px;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-family: "montserrat";
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-cat a {
    font-size: 12px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-cat a:hover {
  background-color: var(--header);
}
.blog-wrapper .single-blog-post .post-content ul {
  margin-bottom: 0;
  list-style-type: disc;
  margin-top: 30px;
}
.blog-wrapper .single-blog-post .post-content ul ol {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post .post-content ul ul {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post .post-content ul li {
  line-height: 1.5;
  margin-top: 10px;
  list-style-type: disc;
  color: var(--text);
  position: relative;
  font-size: 18px;
  padding-left: 30px;
}
.blog-wrapper .single-blog-post .post-content ul li::before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "montserrat";
  content: "\f00c";
  color: var(--theme);
}
.blog-wrapper .single-blog-post .post-content ol {
  margin-bottom: 0;
  list-style-type: decimal;
}
.blog-wrapper .single-blog-post .post-content ol ul {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post .post-content ol ol {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post .post-content ol li {
  line-height: 1.5;
  margin-top: 10px;
  list-style-type: decimal;
}
.blog-wrapper .single-blog-post .post-content p {
  margin-top: 15px;
}
.blog-wrapper .single-blog-post .post-content .post-meta {
  margin-bottom: 10px;
}
.blog-wrapper .single-blog-post .post-content .post-meta span {
  color: var(--text);
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  display: inline-block;
  margin-right: 25px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-meta span {
    font-size: 15px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-meta span i {
  margin-right: 5px;
  color: var(--theme);
  font-weight: 700;
}
.blog-wrapper .single-blog-post .post-content .author-info {
  overflow: hidden;
}
.blog-wrapper .single-blog-post .post-content .author-info .author-img {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .author-info .author-img {
    margin-right: 5px;
  }
}
.blog-wrapper .single-blog-post .post-content .author-info h5 {
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  margin-top: 9px;
}
.blog-wrapper .single-blog-post .post-content .author-info h5 a:hover {
  color: var(--theme);
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .author-info h5 {
    font-size: 14px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-link a {
  font-weight: 700;
}
.blog-wrapper .single-blog-post .post-content .post-link a:hover {
  color: var(--theme);
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-link a {
    font-size: 14px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-link a i {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-link a i {
    margin-right: 3px;
  }
}

@media (min-width: 991px) {
  .news-area .main-sidebar {
    margin-left: 20px;
  }
}
@media (max-width: 991px) {
  .news-area .main-sidebar {
    margin-top: 40px;
  }
}
.news-area .main-sidebar .single-sidebar-widget {
  border: 2px solid #ededed;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 40px;
}
.news-area .main-sidebar .single-sidebar-widget ul {
  padding-left: 0;
}
.news-area .main-sidebar .single-sidebar-widget ul > li {
  margin-bottom: 10px;
}
.news-area .main-sidebar .single-sidebar-widget ul > li a {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.news-area .main-sidebar .single-sidebar-widget ul > li a:hover {
  color: var(--theme);
}
.news-area .main-sidebar .single-sidebar-widget ul ul {
  padding-left: 20px;
}
.news-area .main-sidebar .single-sidebar-widget ul ul li {
  margin-top: 5px;
}
.news-area .main-sidebar .single-sidebar-widget:last-child {
  margin-bottom: 0;
}
.news-area .main-sidebar .single-sidebar-widget .wid-title {
  margin-bottom: 25px;
  padding-left: 45px;
  position: relative;
}
.news-area .main-sidebar .single-sidebar-widget .wid-title::before {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 18px;
  border-radius: 5px;
  position: absolute;
  background-color: var(--theme);
}
.news-area .main-sidebar .single-sidebar-widget .wid-title::after {
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  background-color: var(--theme);
}
.news-area .main-sidebar .single-sidebar-widget .wid-title h4 {
  font-size: 24px;
  font-weight: 600;
}
.news-area .main-sidebar .single-sidebar-widget .social-link a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--bg);
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.news-area .main-sidebar .single-sidebar-widget .social-link a:hover {
  background-color: var(--theme);
  color: var(--white);
}

.search_widget form {
  width: 100%;
  position: relative;
}
.search_widget form input {
  background-color: var(--bg);
  font-size: 15px;
  padding: 20px;
  width: 100%;
  border: none;
  text-transform: capitalize;
  color: var(--header);
}
.search_widget form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  font-size: 18px;
  height: 100%;
  background-color: var(--theme);
  color: var(--white);
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.search_widget form button:hover {
  background-color: var(--header);
}

.popular-posts .single-post-item, .popular_posts .single-post-item {
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
}
.popular-posts .single-post-item:last-child, .popular_posts .single-post-item:last-child {
  margin-bottom: 0;
  border: none;
  padding-bottom: 0;
}
.popular-posts .single-post-item .thumb, .popular_posts .single-post-item .thumb {
  height: 95px;
  width: 95px;
  background-color: #f2f2f2;
  float: left;
  overflow: hidden;
  margin-right: 20px;
}
.popular-posts .single-post-item .post-content, .popular_posts .single-post-item .post-content {
  overflow: hidden;
}
.popular-posts .single-post-item .post-content h5, .popular_posts .single-post-item .post-content h5 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: capitalize;
}
.popular-posts .single-post-item .post-content h5 a:hover, .popular_posts .single-post-item .post-content h5 a:hover {
  color: var(--theme);
}
.popular-posts .single-post-item .post-content .post-date, .popular_posts .single-post-item .post-content .post-date {
  margin-top: 10px;
  color: var(--theme);
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
}
.popular-posts .single-post-item .post-content .post-date i, .popular_posts .single-post-item .post-content .post-date i {
  margin-right: 7px;
}

.widget_categories ul li {
  display: block;
}
.widget_categories ul li:last-child a {
  margin-bottom: 0;
}
.widget_categories ul li a {
  position: relative;
  background: var(--bg);
  padding: 17px 20px;
  line-height: 1;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
}
.widget_categories ul li a:hover {
  color: var(--theme);
}
.widget_categories ul li a i {
  margin-right: 10px;
}
.widget_categories ul li a span {
  position: absolute;
  width: 60px;
  line-height: 55px;
  height: 100%;
  content: "";
  background-color: var(--theme);
  right: 0;
  top: 0;
  text-align: center;
  color: var(--white);
}

.tagcloud a {
  display: inline-block;
  padding: 11px 26px;
  line-height: 1;
  font-size: 15px;
  font-weight: 600;
  background: var(--bg);
  margin-right: 10px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.tagcloud a:last-child {
  margin-right: 0;
}
.tagcloud a:hover {
  background-color: var(--theme);
  color: var(--white);
}

.widget_categories ul li {
  display: block;
  margin-bottom: 10px;
}

p.sticky-label {
  position: absolute;
  right: 0;
  background: var(--theme);
  color: var(--white);
  padding: 10px 40px;
  z-index: -1;
  top: 0;
  font-weight: 700;
}

.page-nav-wrap ul li {
  display: inline-block;
}
.page-nav-wrap ul li .page-numbers {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #f6f6f6;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  margin: 0 2px;
}
.page-nav-wrap ul li .page-numbers.current {
  background-color: var(--theme);
  color: var(--white);
}
@media (max-width: 767px) {
  .page-nav-wrap ul li .page-numbers {
    margin-top: 10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.page-nav-wrap ul li .page-numbers i {
  margin-top: 2px;
}
.page-nav-wrap ul li .page-numbers:hover {
  background-color: var(--theme);
  color: var(--white);
}

.social-share a {
  margin-left: 10px;
  transition: all 0.4s ease-in-out;
}
.social-share a:hover {
  color: var(--theme);
}

.tag-share-wrap {
  padding-bottom: 50px;
}
.tag-share-wrap h4 {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .tag-share-wrap h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .tag-share-wrap .tagcloud {
    margin-bottom: 20px;
  }
}

.comments-section-wrap {
  overflow: hidden;
}

.comments-heading h3, .related-post-wrap h3, .comment-form-wrap h3 {
  font-size: 26px;
}
@media (max-width: 767px) {
  .comments-heading h3, .related-post-wrap h3, .comment-form-wrap h3 {
    font-size: 20px;
  }
}

.comments-item-list .single-comment-item {
  margin-top: 30px;
}
.comments-item-list .single-comment-item ul {
  margin-bottom: 15px;
}
.comments-item-list .single-comment-item p, .comments-item-list .single-comment-item span {
  font-size: 16px;
}
.comments-item-list .single-comment-item .author-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  margin-right: 30px;
}
@media (max-width: 991px) {
  .comments-item-list .single-comment-item .author-img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }
}
@media (max-width: 585px) {
  .comments-item-list .single-comment-item .author-img {
    float: none;
    margin-bottom: 20px;
  }
}
.comments-item-list .single-comment-item .author-info-comment {
  overflow: auto;
  padding-bottom: 25px;
  border-bottom: 1px solid #e2e2e2;
}
.comments-item-list .single-comment-item .author-info-comment .info {
  position: relative;
}
.comments-item-list .single-comment-item .author-info-comment .info .theme-btn {
  padding: 12px 18px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #727475;
  border: 1px solid #ebebeb;
  background: transparent;
  border-radius: 0;
  transition: all 0.4s ease-in-out;
  box-shadow: inherit;
}
@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .info .theme-btn {
    font-size: 12px;
  }
}
.comments-item-list .single-comment-item .author-info-comment .info .theme-btn i {
  margin-right: 10px;
  transition: all 0.4s ease-in-out;
  margin-left: 0;
}
.comments-item-list .single-comment-item .author-info-comment .info .theme-btn.minimal-btn::before, .comments-item-list .single-comment-item .author-info-comment .info .theme-btn.minimal-btn::after {
  background-color: initial;
}
.comments-item-list .single-comment-item .author-info-comment .info .theme-btn:hover {
  background-color: var(--theme);
  color: var(--white);
}
.comments-item-list .single-comment-item .author-info-comment .info .theme-btn:hover a {
  color: var(--white) !important;
}
.comments-item-list .single-comment-item .author-info-comment .info .theme-btn:hover i {
  transform: translate(0);
}
.comments-item-list .single-comment-item .author-info-comment .info h5 {
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .info h5 {
    font-size: 16px;
  }
}
.comments-item-list .single-comment-item .author-info-comment span {
  color: var(--theme);
  font-weight: 500;
}
.comments-item-list .single-comment-item .author-info-comment .theme-btn {
  padding: 7px 18px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .theme-btn {
    font-size: 12px;
  }
}
.comments-item-list .single-comment-item .author-info-comment .theme-btn i {
  margin-right: 5px;
}
.comments-item-list .single-comment-item .author-info-comment .theme-btn:hover a {
  color: var(--white);
}
.comments-item-list .single-comment-item .author-info-comment .comment-text {
  margin-top: 15px;
}

.comment-form {
  background-color: var(--white);
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .comment-form {
    padding: 30px;
  }
}
@media (max-width: 585px) {
  .comment-form {
    padding: 30px 15px;
  }
}
.comment-form label {
  margin-bottom: 4px;
}
.comment-form input, .comment-form textarea {
  margin-bottom: 20px;
  outline: none;
  padding: 20px 30px;
  line-height: 1;
  font-size: 16px;
  width: 100%;
  border: none;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--header);
  background-color: var(--bg);
}
@media (max-width: 585px) {
  .comment-form input, .comment-form textarea {
    padding: 15px;
  }
}
.comment-form textarea {
  height: 200px;
  line-height: 1.3;
  border: none;
}
@media (max-width: 767px) {
  .comment-form textarea {
    height: 120px;
  }
}
.comment-form .theme-btn {
  display: inline-block;
  line-height: 1.2;
  padding: 24px 60px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .comment-form .theme-btn {
    padding: 20px 40px;
  }
}
@media (max-width: 585px) {
  .comment-form .theme-btn {
    padding: 15px 40px;
    font-size: 14px;
  }
}
.comment-form .theme-btn i {
  margin-right: 10px;
}
.comment-form .theme-btn:hover i {
  transform: translate(0);
}

.comment ul {
  list-style-type: disc;
}
.comment ul ol {
  margin-bottom: 0;
}
.comment ul li {
  line-height: 1.5;
  margin-top: 10px;
  list-style-type: disc;
}
.comment ol ul {
  margin-bottom: 0;
}
.comment ol ol {
  margin-bottom: 0;
}
.comment ol li {
  line-height: 1.5;
  margin-top: 10px;
}

.site_info_widget .single-contact-info {
  overflow: auto;
  margin-bottom: 20px;
}
.site_info_widget .single-contact-info span {
  display: block;
  color: var(--theme);
}
.site_info_widget .single-contact-info .icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--white);
  background-color: var(--theme);
  float: left;
  text-align: center;
  overflow: hidden;
  font-size: 16px;
  margin-right: 20px;
}
.site_info_widget .single-contact-info:nth-child(2n) .icon {
  background-color: var(--theme);
}
.site_info_widget .single-contact-info:nth-child(3n) .icon {
  background-color: var(--theme);
}
.site_info_widget .social-link {
  margin-top: 35px;
}
.site_info_widget .social-link a {
  margin-right: 8px !important;
}

.alignleft {
  float: left;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}

.contact-section {
  position: relative;
}
.contact-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg3);
  left: 20%;
}
@media (max-width: 1399px) {
  .contact-section::before {
    left: 0;
  }
}

.contact-form-items .form-clt input, .contact-form-items .form-clt textarea {
  padding: 18px 30px;
  border: none;
  outline: none;
  background-color: var(--white);
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: var(--header);
  border-radius: 5px;
}
@media (max-width: 575px) {
  .contact-form-items .form-clt input, .contact-form-items .form-clt textarea {
    padding: 14px 20px;
  }
}
.contact-form-items .form-clt input::-moz-placeholder, .contact-form-items .form-clt textarea::-moz-placeholder {
  color: var(--header);
}
.contact-form-items .form-clt input::placeholder, .contact-form-items .form-clt textarea::placeholder {
  color: var(--header);
}
.contact-form-items .form-clt textarea {
  padding: 18px 30px 110px;
}
@media (max-width: 575px) {
  .contact-form-items .form-clt textarea {
    padding: 14px 20px 90px;
  }
}

.contact-section-2 {
  position: relative;
  z-index: 9;
}
.contact-section-2 .contact-image {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  animation: right-image-bounce 3s infinite ease-in-out;
}

.contact-wrapper-2 .contact-content .contact-info-area {
  margin-top: 50px;
}
.contact-wrapper-2 .contact-content .contact-info-area .contact-info-items {
  display: flex;
  align-items: center;
  gap: 30px;
}
.contact-wrapper-2 .contact-content .contact-info-area .contact-info-items .icon {
  font-size: 32px;
  color: var(--theme2);
}
.contact-wrapper-2 .contact-content .contact-info-area .contact-info-items .content p {
  color: var(--theme2);
}
.contact-wrapper-2 .contact-content .contact-info-area .contact-info-items .content h3 {
  font-size: 22px;
  margin-top: 5px;
  text-transform: initial;
}
.contact-wrapper-2 .contact-content .contact-info-area .contact-info-items.style-2 .icon {
  color: var(--theme);
}
.contact-wrapper-2 .contact-content .contact-info-area .contact-info-items.style-2 .content p {
  color: var(--theme);
}

.contact-box {
  background-color: var(--white);
  box-shadow: var(---box-shadow);
  border-radius: 5px;
  padding: 60px 45px;
}
.contact-box .contact-title {
  text-align: center;
  margin-bottom: 30px;
}
.contact-box .contact-title h3 {
  font-size: 27px;
}
.contact-box .contact-title p {
  color: var(--black);
  margin-top: 5px;
}
.contact-box .contact-form-items .form-clt {
  position: relative;
}
.contact-box .contact-form-items .form-clt input, .contact-box .contact-form-items .form-clt textarea {
  border: 1px solid #E4E4E4;
  color: var(--text);
  font-weight: 500;
}
.contact-box .contact-form-items .form-clt input::-moz-placeholder, .contact-box .contact-form-items .form-clt textarea::-moz-placeholder {
  color: var(--text);
}
.contact-box .contact-form-items .form-clt input::placeholder, .contact-box .contact-form-items .form-clt textarea::placeholder {
  color: var(--text);
}
@media (max-width: 575px) {
  .contact-box .contact-form-items .form-clt input, .contact-box .contact-form-items .form-clt textarea {
    padding: 12px 20px;
  }
}
.contact-box .contact-form-items .form-clt .icon {
  position: absolute;
  top: 18px;
  right: 30px;
}
.contact-box .contact-form-items textarea {
  padding: 18px 30px 90px;
}
@media (max-width: 575px) {
  .contact-box .contact-form-items textarea {
    padding: 12px 20px 90px !important;
  }
}
.contact-box .contact-form-items .theme-btn-2 {
  display: inherit;
  text-align: center;
  line-height: 1.3;
  margin: 0 auto;
}

.contact-main-wrapper .contact-content {
  margin-right: 30px;
}
@media (max-width: 1399px) {
  .contact-main-wrapper .contact-content {
    margin-right: 0;
  }
}
.contact-main-wrapper .contact-content .info-items {
  display: flex;
  gap: 25px;
}
.contact-main-wrapper .contact-content .info-items .icon {
  font-size: 22px;
  color: var(--theme);
}
.contact-main-wrapper .contact-content .info-items .content h5 {
  margin-bottom: 5px;
}
.contact-main-wrapper .contact-content .info-items .content a {
  color: var(--text);
}
.contact-main-wrapper .contact-content .info-items .content .social-icon {
  gap: 15px;
  position: relative;
  z-index: 9;
  margin-top: 10px;
}
.contact-main-wrapper .contact-content .info-items .content .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: transparent;
  color: var(--text);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  text-align: center;
  border: 1px solid var(--border2);
}
.contact-main-wrapper .contact-content .info-items .content .social-icon a:hover {
  background-color: var(--theme);
  color: var(--white);
  border: 1px solid transparent;
}
.contact-main-wrapper .contact-content .contact-image {
  margin-top: 50px;
}
.contact-main-wrapper .contact-content .contact-image img {
  width: 100%;
  height: 100%;
}
.contact-main-wrapper .contact-form-items {
  background-color: var(--bg5);
  padding: 55px 45px;
}
.contact-main-wrapper .contact-form-items .contact-title {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .contact-main-wrapper .contact-form-items .contact-title {
    margin-bottom: 20px;
  }
}
.contact-main-wrapper .contact-form-items .contact-title h3 {
  font-size: 38px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .contact-main-wrapper .contact-form-items .contact-title h3 {
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  .contact-main-wrapper .contact-form-items .contact-title h3 {
    font-size: 28px;
  }
}
.contact-main-wrapper .contact-form-items .form-clt {
  position: relative;
}
.contact-main-wrapper .contact-form-items .form-clt .icon {
  position: absolute;
  top: 18px;
  left: 0;
}
@media (max-width: 575px) {
  .contact-main-wrapper .contact-form-items .form-clt .icon {
    display: none;
  }
}
.contact-main-wrapper .contact-form-items .form-clt .icon i {
  color: var(--header);
}
.contact-main-wrapper .contact-form-items .form-clt input, .contact-main-wrapper .contact-form-items .form-clt textarea {
  width: 100%;
  outline: none;
  border-radius: 0;
  border: none;
  padding: 20px 30px;
  color: var(--text);
  background-color: transparent;
  text-transform: capitalize;
  font-weight: 500;
  border-bottom: 1px solid var(--border2);
}
@media (max-width: 575px) {
  .contact-main-wrapper .contact-form-items .form-clt input, .contact-main-wrapper .contact-form-items .form-clt textarea {
    padding: 15px 20px;
  }
}
.contact-main-wrapper .contact-form-items .form-clt input::-moz-placeholder, .contact-main-wrapper .contact-form-items .form-clt textarea::-moz-placeholder {
  color: var(--text);
}
.contact-main-wrapper .contact-form-items .form-clt input::placeholder, .contact-main-wrapper .contact-form-items .form-clt textarea::placeholder {
  color: var(--text);
}
.contact-main-wrapper .contact-form-items .form-clt textarea {
  padding: 20px 30px 110px;
}
.contact-main-wrapper .contact-form-items .theme-btn i {
  margin-right: 10px;
}
.contact-main-wrapper .contact-form-items .theme-btn:hover i {
  transform: translate(0);
}

.google-map iframe {
  width: 100%;
  height: 500px;
}

.footer-section {
  position: relative;
}

.footer-top {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(230, 230, 230, 0.1);
}
@media (max-width: 991px) {
  .footer-top {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  .footer-top {
    padding: 40px 0;
  }
}
.footer-top ul {
  display: flex;
  align-items: center;
  gap: 55px;
}
@media (max-width: 767px) {
  .footer-top ul {
    gap: 20px;
    flex-wrap: wrap;
  }
}
.footer-top ul li i {
  color: var(--theme);
  margin-right: 5px;
}
.footer-top ul li a {
  color: var(--white);
  font-weight: 500;
}

.footer-widgets-wrapper {
  padding: 90px 0 120px;
  position: relative;
  z-index: 9;
}
@media (max-width: 1199px) {
  .footer-widgets-wrapper {
    padding: 60px 0 90px;
  }
}
@media (max-width: 991px) {
  .footer-widgets-wrapper {
    padding: 50px 0 70px;
  }
}
.footer-widgets-wrapper .single-footer-widget {
  margin-top: 30px;
}
.footer-widgets-wrapper .single-footer-widget .widget-head {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .widget-head {
    margin-bottom: 20px;
  }
}
.footer-widgets-wrapper .single-footer-widget .widget-head h5 {
  text-transform: capitalize;
  margin-top: 10px;
  color: var(--white);
}
.footer-widgets-wrapper .single-footer-widget .widget-head h3 {
  font-size: 32px;
  text-transform: capitalize;
  color: var(--white);
  margin-top: 10px;
}
.footer-widgets-wrapper .single-footer-widget .footer-content p {
  color: var(--text2);
}
@media (max-width: 1399px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content br {
    display: none;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content span {
  font-weight: 500;
  margin-top: 15px;
  display: inline-block;
  color: var(--text2);
}
@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content span {
    margin-top: 10px;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content .link {
  font-size: 16px;
  font-weight: 700;
  color: var(--theme);
  margin-top: 15px;
  display: inline-block;
}
@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content .link {
    margin-top: 10px;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content .social-icon {
  margin-top: 40px;
  gap: 15px;
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content .social-icon {
    margin-top: 20px;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: rgba(92, 92, 91, 0.1);
  color: var(--text2);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  text-align: center;
}
.footer-widgets-wrapper .single-footer-widget .footer-content .social-icon a:hover {
  background-color: var(--theme);
  color: var(--white);
}
.footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1399px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .form-clt {
  flex-basis: 70%;
}
@media (max-width: 991px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .form-clt {
    flex-basis: 100% !important;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .form-clt input {
  position: relative;
  padding: 18px 30px;
  border: none;
  outline: none;
  background: #4E4A59;
  border: 1.2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  width: 100%;
  font-size: 16px;
  color: var(--text);
  border-radius: 5px;
}
.footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .theme-btn {
  padding: 24px 40px;
}
@media (max-width: 991px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .theme-btn {
    width: 100%;
    text-align: center;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .theme-btn span {
  margin-top: 0;
  color: var(--white);
  transition: all 0.4s ease-in-out;
}
.footer-widgets-wrapper .single-footer-widget .footer-content .newsletter-items .theme-btn:hover span {
  color: var(--header);
}
.footer-widgets-wrapper .single-footer-widget .footer-content .label-text {
  color: var(--text2);
  font-weight: 500;
  margin-top: 15px;
  font-size: 16px;
}
.footer-widgets-wrapper .single-footer-widget .footer-content .label-text a {
  color: var(--white);
  text-decoration: underline;
}
.footer-widgets-wrapper .single-footer-widget .list-items li {
  text-transform: capitalize;
}
.footer-widgets-wrapper .single-footer-widget .list-items li:not(:last-child) {
  margin-bottom: 15px;
}
.footer-widgets-wrapper .single-footer-widget .list-items li a {
  font-size: 16px;
  color: var(--text2);
  position: relative;
}
.footer-widgets-wrapper .single-footer-widget .list-items li a::before {
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  content: "";
  border-radius: 50%;
  background-color: var(--theme);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.footer-widgets-wrapper .single-footer-widget .list-items li a:hover {
  padding-left: 20px;
  color: var(--theme);
}
.footer-widgets-wrapper .single-footer-widget .list-items li a:hover::before {
  opacity: 1;
  visibility: visible;
}
.footer-widgets-wrapper .single-footer-widget .footer-address-text p {
  color: var(--text2);
}
.footer-widgets-wrapper .single-footer-widget .footer-address-text h5 {
  padding: 20px 0;
  color: var(--white);
}
.footer-widgets-wrapper .single-footer-widget .footer-address-text h5 a {
  color: var(--white);
}
.footer-widgets-wrapper .single-footer-widget .footer-address-text .link-mail {
  color: var(--text2);
  font-size: 16px;
}
.footer-widgets-wrapper .single-footer-widget .footer-apps-items h5 {
  font-weight: 400;
  color: var(--text);
}
.footer-widgets-wrapper .single-footer-widget .footer-apps-items .apps-image {
  margin-top: 30px;
  gap: 10px;
}
@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .footer-apps-items .apps-image {
    margin-top: 20px;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-apps-items .support-text {
  margin-top: 40px;
}
@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .footer-apps-items .support-text {
    margin-top: 20px;
  }
}
.footer-widgets-wrapper .single-footer-widget .footer-apps-items .support-text p {
  color: var(--white);
  margin-bottom: 15px;
}
.footer-widgets-wrapper .single-footer-widget .footer-apps-items .support-text h3 {
  font-size: 28px;
}
.footer-widgets-wrapper .single-footer-widget .footer-apps-items .support-text h3 a {
  color: var(--theme);
}

.footer-bottom {
  padding: 20px 0;
  position: relative;
  z-index: 9;
  background-color: var(--theme);
}
@media (max-width: 991px) {
  .footer-bottom .footer-wrapper {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center !important;
    margin-top: 30px;
  }
}
.footer-bottom .footer-wrapper p {
  color: var(--white);
}
.footer-bottom .footer-wrapper p a {
  color: var(--white);
}
.footer-bottom .footer-wrapper .footer-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer-bottom .footer-wrapper .footer-menu li a {
  color: var(--white);
  background-image: linear-gradient(var(--white), var(--white));
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.footer-bottom .footer-wrapper .footer-menu li a:hover {
  background-size: 100% 1px;
}
.footer-bottom .scroll-icon {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  line-height: 60px;
  background-color: var(--theme);
  border-radius: 50%;
  text-align: center;
  color: var(--white);
  border: 5px solid var(--white);
  display: inline-block;
}
.footer-bottom.style-2 {
  padding: 0;
  background-color: transparent;
  text-align: center;
  padding-bottom: 30px;
  margin-top: -10px;
}
.footer-bottom.style-2 .footer-wrapper {
  display: initial !important;
}

.newsletter-area {
  position: relative;
  z-index: 9;
}
.newsletter-area .newsletter-title {
  text-align: center;
}
.newsletter-area .newsletter-title h2 {
  color: var(--white);
  margin-bottom: 10px;
}
.newsletter-area .newsletter-title p {
  color: var(--white);
}
.newsletter-area .shape-1 {
  position: absolute;
  left: -15%;
  bottom: 4%;
  animation: rounded 6s linear infinite;
}
@media (max-width: 1399px) {
  .newsletter-area .shape-1 {
    display: none;
  }
}
.newsletter-area .shape-2 {
  position: absolute;
  right: -15%;
  bottom: 4%;
  animation: rounded 5s linear infinite;
}
@media (max-width: 1399px) {
  .newsletter-area .shape-2 {
    display: none;
  }
}
.newsletter-area .newsletter-items {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1399px) {
  .newsletter-area .newsletter-items {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    gap: 20px;
  }
}
.newsletter-area .newsletter-items .form-clt {
  flex-basis: 70%;
}
@media (max-width: 767px) {
  .newsletter-area .newsletter-items .form-clt {
    flex-basis: 100%;
  }
}
.newsletter-area .newsletter-items .form-clt input {
  position: relative;
  padding: 18px 30px;
  border: none;
  outline: none;
  background-color: var(--white);
  width: 100%;
  font-size: 16px;
  color: var(--text);
  border-radius: 5px;
}
@media (max-width: 767px) {
  .newsletter-area .newsletter-items .form-clt input {
    padding: 14px 30px;
  }
}
@media (max-width: 575px) {
  .newsletter-area .newsletter-items .form-clt input {
    padding: 14px 25px;
  }
}
.newsletter-area .newsletter-items .theme-btn {
  padding: 24px 50px;
}
@media (max-width: 767px) {
  .newsletter-area .newsletter-items .theme-btn {
    width: 100%;
    padding: 20px 40px;
  }
}
@media (max-width: 575px) {
  .newsletter-area .newsletter-items .theme-btn {
    padding: 18px 25px;
  }
}
.newsletter-area.style-2 {
  padding-bottom: 120px;
  margin-top: -10px;
}
@media (max-width: 1199px) {
  .newsletter-area.style-2 {
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .newsletter-area.style-2 {
    padding-bottom: 80px;
  }
}
.newsletter-area.style-2 .newsletter-items .theme-btn {
  padding: 24px 30px;
}
.newsletter-area.style-2 .social-icon {
  margin-top: 40px;
  gap: 15px;
  position: relative;
  z-index: 9;
  justify-content: center;
}
@media (max-width: 575px) {
  .newsletter-area.style-2 .social-icon {
    margin-top: 20px;
  }
}
.newsletter-area.style-2 .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: rgba(92, 92, 91, 0.1);
  color: var(--text2);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  text-align: center;
}
.newsletter-area.style-2 .social-icon a:hover {
  background-color: var(--theme);
  color: var(--white);
}

.footer-bottom-4 {
  border-top: 1px solid #1B1B1B;
  padding: 15px 0;
}
.footer-bottom-4 p {
  color: var(--white);
}
.footer-bottom-4 p a {
  color: var(--theme);
}




.image-carousel {
  margin-top: 30px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.grid-item {
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
}

.box {
  border: 1px solid #ddd;
  padding: 15px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}





.mouse-cursor {
  position: absolute !important;
  pointer-events: none !important;
  z-index: 9999 !important;
}

.cursor-outer {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  border: 2px solid #010156 !important;
  transform: translate(-50%, -50%) !important;
  transition: transform 0.15s ease-out !important;
}

.cursor-inner {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  background-color: #E65D0F !important; 
  transform: translate(-50%, -50%) !important;
  transition: transform 0.1s ease-out !important;
}



.menu-thumb a:hover,
.menu-thumb a:focus,
.menu-thumb a.active {
  color: #0056b3;
  transition: color 0.3s ease;
}


.MuiMenu-paper {
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform-origin: top center;
  opacity: 0;
}
.MuiMenu-paper.Mui-open {
  opacity: 1;
  transform: scale(1);
}


.MuiMenu-paper {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.offcanvas__info li {
  padding: 10px 20px;
  font-size: 18px;
}

.mobile-menu {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}
.mobile-menu.open {
  transform: translateX(0);
}







